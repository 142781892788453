import { Collapsible } from "../../../../../../ui/Collapsible"
import { RuleChip } from "../../components/RuleChip"
import { RuleChipLayout } from "../../components/RuleChipLayout"
import { CateringLabels, RulesMessage } from "../../shared/config"
import { CateringForm, FeeQuantityEnum } from "../../shared/hookforms.intefaces"
import { CateringFeeField } from "./CateringFeeField"
import { Grid } from "@mantine/core"
import { FieldPath, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type ServiceFeeRulesType = {
  key: string
  label: {
    id: string
    defaultMessage: string
  }
  apply: boolean
  value?: number
  type: FeeQuantityEnum
}

type ServiceCateringFeeType = {
  id: string
  key: string
  apply: boolean
  type: FeeQuantityEnum
  label: {
    id: string
    defaultMessage: string
  }
  checkboxField: FieldPath<CateringForm>
  valueField: FieldPath<CateringForm>
  typeField: FieldPath<CateringForm>
}

const formatNumber = (value: number, type: FeeQuantityEnum) =>
  type === FeeQuantityEnum.AMOUNT ? `$${value}` : `${value}%`

export const CateringFeeForm = () => {
  const {
    control,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const intl = useIntl()

  const [isActive, rulesCateringFee, typeOfServices] = useWatch({
    control,
    name: ["isActive", "rules.cateringFee", "rules.typeOfService"],
  })

  const noneLabel = intl.formatMessage({
    defaultMessage: "None",
    id: "restaurants.settings.location.catering.rules.chip.none.label",
  })

  const verifySomeTypeOfService = Object.values(typeOfServices).includes(true)

  const serviceFeeRules: ServiceFeeRulesType[] = [
    {
      key: "pickup",
      label: CateringLabels.pickup,
      apply: rulesCateringFee.pickup?.apply,
      value: rulesCateringFee.pickup?.value,
      type: rulesCateringFee.pickup?.type,
    },
    {
      key: "delivery",
      label: CateringLabels.delivery,
      apply: rulesCateringFee.delivery?.apply,
      value: rulesCateringFee.delivery?.value,
      type: rulesCateringFee.delivery?.type,
    },
    {
      key: "foodTruckExperience",
      label: CateringLabels.foodTruckExperience,
      apply: rulesCateringFee.foodTruckExperience?.apply,
      value: rulesCateringFee.foodTruckExperience?.value,
      type: rulesCateringFee.foodTruckExperience?.type,
    },
    {
      key: "insideVenue",
      label: CateringLabels.insideVenue,
      apply: rulesCateringFee.insideVenue?.apply,
      value: rulesCateringFee.insideVenue?.value,
      type: rulesCateringFee.insideVenue?.type,
    },
  ]

  const serviceCateringFees: ServiceCateringFeeType[] = [
    {
      id: "pickup-section",
      key: "pickup",
      apply: rulesCateringFee.pickup?.apply,
      type: rulesCateringFee.pickup?.type,
      label: RulesMessage.cateringFee.pickup,
      checkboxField: "rules.cateringFee.pickup.apply",
      valueField: "rules.cateringFee.pickup.value",
      typeField: "rules.cateringFee.pickup.type",
    },
    {
      id: "delivery-section",
      key: "delivery",
      apply: rulesCateringFee.delivery?.apply,
      type: rulesCateringFee.delivery?.type,
      label: RulesMessage.cateringFee.delivery,
      checkboxField: "rules.cateringFee.delivery.apply",
      valueField: "rules.cateringFee.delivery.value",
      typeField: "rules.cateringFee.delivery.type",
    },
    {
      id: "food-truck-experience-section",
      key: "foodTruckExperience",
      apply: rulesCateringFee.foodTruckExperience?.apply,
      type: rulesCateringFee.foodTruckExperience?.type,
      label: RulesMessage.cateringFee.foodTruckExperience,
      checkboxField: "rules.cateringFee.foodTruckExperience.apply",
      valueField: "rules.cateringFee.foodTruckExperience.value",
      typeField: "rules.cateringFee.foodTruckExperience.type",
    },
    {
      id: "inside-venue-section",
      key: "insideVenue",
      apply: rulesCateringFee.insideVenue?.apply,
      type: rulesCateringFee.insideVenue?.type,
      label: RulesMessage.cateringFee.insideVenue,
      checkboxField: "rules.cateringFee.insideVenue.apply",
      valueField: "rules.cateringFee.insideVenue.value",
      typeField: "rules.cateringFee.insideVenue.type",
    },
  ]

  const hasErrors = errors.rules?.cateringFee

  return (
    <Collapsible
      label={intl.formatMessage(RulesMessage.cateringFee.title)}
      disabled={!isActive || !verifySomeTypeOfService}
      error={Boolean(hasErrors)}
      rightSection={
        <RuleChipLayout>
          {serviceFeeRules.map(
            (fee) =>
              typeOfServices[fee.key as keyof typeof typeOfServices] && (
                <RuleChip
                  key={fee.key}
                  data={[
                    intl.formatMessage(fee.label),
                    fee.apply && fee.value
                      ? formatNumber(fee.value, fee.type)
                      : noneLabel,
                  ]}
                  disabled={!isActive}
                />
              )
          )}
        </RuleChipLayout>
      }
    >
      <Grid>
        {serviceCateringFees.map(
          (service) =>
            typeOfServices[service.key as keyof typeof typeOfServices] && (
              <CateringFeeField
                key={service.key}
                id={service.id}
                control={control}
                nameCheckbox={service.checkboxField}
                nameType={service.typeField}
                nameValue={service.valueField}
                apply={
                  rulesCateringFee[service.key as keyof typeof typeOfServices]
                    ?.apply
                }
                type={service.type}
                setValue={setValue}
                trigger={trigger}
                label={service.label}
              />
            )
        )}
      </Grid>
    </Collapsible>
  )
}
