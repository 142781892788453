import { toFormat } from "../../../../../../shared/utils/currency/toFormat.dinero"
import { Collapsible } from "../../../../../../ui/Collapsible"
import { RuleChip } from "../../components/RuleChip"
import { RuleChipLayout } from "../../components/RuleChipLayout"
import { CateringLabels, RulesMessage } from "../../shared/config"
import { CateringForm } from "../../shared/hookforms.intefaces"
import { MinimumOrderAmountField } from "./MinimumOrderAmointField"
import { Grid } from "@mantine/core"
import { FieldPath, useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

type ServiceRulesType = {
  key: string
  label: {
    id: string
    defaultMessage: string
  }
  apply: boolean
  amount?: number
}

type ServiceMinimumOrderAmountType = {
  key: string
  apply: boolean
  id: string
  errorAmount?: string
  errorApply?: string
  field: FieldPath<CateringForm>
  checkboxField: FieldPath<CateringForm>
  amountField: FieldPath<CateringForm>
  label: {
    id: string
    defaultMessage: string
  }
}

export const MinimumOrderAmountForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const intl = useIntl()

  const [isActive, rulesMinimumAmount, typeOfServices] = useWatch({
    control,
    name: ["isActive", "rules.minimumAmount", "rules.typeOfService"],
  })

  const noneLabel = intl.formatMessage({
    defaultMessage: "None",
    id: "restaurants.settings.location.catering.rules.chip.none.label",
  })

  const verifySomeTypeOfService = Object.values(typeOfServices).includes(true)

  const serviceRules: ServiceRulesType[] = [
    {
      key: "pickup",
      label: CateringLabels.pickup,
      apply: rulesMinimumAmount.pickup?.apply,
      amount: rulesMinimumAmount.pickup?.amount,
    },
    {
      key: "delivery",
      label: CateringLabels.delivery,
      apply: rulesMinimumAmount.delivery?.apply,
      amount: rulesMinimumAmount.delivery?.amount,
    },
    {
      key: "foodTruckExperience",
      label: CateringLabels.foodTruckExperience,
      apply: rulesMinimumAmount.foodTruckExperience?.apply,
      amount: rulesMinimumAmount.foodTruckExperience?.amount,
    },
    {
      key: "insideVenue",
      label: CateringLabels.insideVenue,
      apply: rulesMinimumAmount.insideVenue?.apply,
      amount: rulesMinimumAmount.insideVenue?.amount,
    },
  ]

  const serviceMinimumOrderAmounts: ServiceMinimumOrderAmountType[] = [
    {
      key: "pickup",
      apply: typeOfServices.pickup,
      field: "rules.minimumAmount.pickup",
      id: "pickup-section",
      checkboxField: "rules.minimumAmount.pickup.apply",
      errorAmount: errors.rules?.minimumAmount?.pickup?.amount?.type,
      errorApply: errors.rules?.minimumAmount?.pickup?.apply?.type,
      amountField: "rules.minimumAmount.pickup.amount",
      label: RulesMessage.minimumAmount.pickupCheckbox,
    },
    {
      key: "delivery",
      apply: typeOfServices.delivery,
      id: "delivery-section",
      field: "rules.minimumAmount.delivery",
      checkboxField: "rules.minimumAmount.delivery.apply",
      errorAmount: errors.rules?.minimumAmount?.delivery?.amount?.type,
      errorApply: errors.rules?.minimumAmount?.delivery?.apply?.type,
      amountField: "rules.minimumAmount.delivery.amount",
      label: RulesMessage.minimumAmount.deliveryCheckbox,
    },
    {
      key: "foodTruckExperience",
      apply: typeOfServices.foodTruckExperience,
      id: "food-truck-experience-section",
      field: "rules.minimumAmount.foodTruckExperience",
      checkboxField: "rules.minimumAmount.foodTruckExperience.apply",
      errorAmount:
        errors.rules?.minimumAmount?.foodTruckExperience?.amount?.type,
      errorApply: errors.rules?.minimumAmount?.foodTruckExperience?.apply?.type,
      amountField: "rules.minimumAmount.foodTruckExperience.amount",
      label: RulesMessage.minimumAmount.foodTruckExperienceCheckbox,
    },
    {
      key: "insideVenue",
      apply: typeOfServices.insideVenue,
      id: "inside-venue-section",
      field: "rules.minimumAmount.insideVenue",
      checkboxField: "rules.minimumAmount.insideVenue.apply",
      errorAmount: errors.rules?.minimumAmount?.insideVenue?.amount?.type,
      errorApply: errors.rules?.minimumAmount?.insideVenue?.apply?.type,
      amountField: "rules.minimumAmount.insideVenue.amount",
      label: RulesMessage.minimumAmount.insideVenueCheckbox,
    },
  ]

  const enableError =
    errors.rules?.minimumAmount?.pickup ||
    errors.rules?.minimumAmount?.delivery ||
    errors.rules?.minimumAmount?.foodTruckExperience ||
    errors.rules?.minimumAmount?.insideVenue

  return (
    <Collapsible
      label={intl.formatMessage(RulesMessage.minimumAmount.title)}
      disabled={!isActive || !verifySomeTypeOfService}
      error={enableError ? true : false}
      rightSection={
        <RuleChipLayout>
          {serviceRules.map(
            (rule) =>
              typeOfServices[rule.key as keyof typeof typeOfServices] && (
                <RuleChip
                  data={[
                    intl.formatMessage(rule.label),
                    rule.apply && rule.amount
                      ? toFormat(rule.amount)
                      : noneLabel,
                  ]}
                  disabled={!isActive}
                />
              )
          )}
        </RuleChipLayout>
      }
    >
      <Grid grow>
        {serviceMinimumOrderAmounts.map((service) => {
          return (
            typeOfServices[service.key as keyof typeof typeOfServices] && (
              <MinimumOrderAmountField
                key={service.key}
                id={service.id}
                control={control}
                nameCheckbox={service.checkboxField}
                nameAmount={service.amountField}
                apply={
                  rulesMinimumAmount[service.key as keyof typeof typeOfServices]
                    ?.apply
                }
                label={service.label}
              />
            )
          )
        })}
      </Grid>
    </Collapsible>
  )
}
