import { Collapsible } from "../../../../../../ui/Collapsible"
import { RuleChip } from "../../components/RuleChip"
import { RulesMessage } from "../../shared/config"
import { CateringForm } from "../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../shared/validation-catering.title"
import styles from "./OrderSchedulingForm.module.css"
import { NumberInput, Stack, Text } from "@mantine/core"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

export const OrderSchedulingForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const intl = useIntl()

  const [isActive, daysInAdvance] = useWatch({
    control,
    name: ["isActive", "rules.orderScheduling.daysInAdvance"],
  })
  const noneLabel = intl.formatMessage({
    defaultMessage: "None",
    id: "restaurants.settings.location.catering.rules.chip.none.label",
  })
  const hasErrors = errors?.rules?.orderScheduling?.daysInAdvance

  return (
    <Collapsible
      label={intl.formatMessage(RulesMessage.orderScheduling.title)}
      disabled={!isActive}
      error={hasErrors ? true : false}
      rightSection={
        <RuleChip
          data={
            daysInAdvance
              ? [
                  daysInAdvance.toString(),
                  intl.formatMessage({
                    id: "restaurants.settings.locaiton.catering.rules.order.scheduling.chip.label",
                    defaultMessage: "Days in advance",
                  }),
                ]
              : [noneLabel]
          }
          disabled={!isActive}
        />
      }
    >
      <Text size="sm">
        <FormattedMessage {...RulesMessage.orderScheduling.description} />
      </Text>
      <Stack spacing={4}>
        <Controller
          control={control}
          name="rules.orderScheduling.daysInAdvance"
          render={({ field, fieldState: { error } }) => (
            <NumberInput
              type="number"
              error={
                error?.message
                  ? intl.formatMessage(validationCateringTitle[error?.message])
                  : undefined
              }
              classNames={{ label: styles.label, input: styles.input }}
              label={
                <Text size="sm" weight={600} display="inline">
                  <FormattedMessage {...RulesMessage.orderScheduling.label} />
                </Text>
              }
              description={
                <FormattedMessage {...RulesMessage.orderScheduling.caption} />
              }
              descriptionProps={{ position: "bottom" }}
              withAsterisk
              display="block"
              precision={0}
              w={408}
              h={82}
              hideControls
              inputWrapperOrder={["label", "input", "description", "error"]}
              mb={4}
              value={daysInAdvance}
              onChange={(value) => {
                field.onChange(value)
              }}
            />
          )}
        />
      </Stack>
    </Collapsible>
  )
}
