import { emailRegex } from "../../../../../shared/utils/constant/emailRegex"
import { regexValidPhoneNumber } from "../../../../../shared/utils/constant/regexValidPhoneNumber"
import { urlRegex } from "../../../../../shared/utils/constant/urlRegex"
import { emptyValueCleanup } from "../../../../../shared/utils/helpers/yup-utils"
import yup from "../../../../../shared/yup"
import {
  WebsiteDefaultTabIdType,
  WebsiteDefaultTabsIds,
} from "../WebsiteTabs/utils/DefaultTabs"
import { WebsiteDomainTypeEnum } from "./hookforms.interface"
import {
  EMAIL_VALID_ID,
  PHONE_VALID_ID,
  REQUIRED_ID,
  URL_VALID_ID,
} from "./website-validation-messages"
import { yupResolver } from "@hookform/resolvers/yup"

const WebsiteSettingsShape = yup
  .object()
  .optional()
  .shape({
    enabled: yup.boolean(),
    domainType: yup
      .mixed<WebsiteDomainTypeEnum>()
      .oneOf(Object.values(WebsiteDomainTypeEnum) as number[])
      .required(),
    crmbDomainURL: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
    customDomainURL: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
    contactEmail: yup
      .string()
      .matches(emailRegex, EMAIL_VALID_ID)
      .required(REQUIRED_ID),
    contactPhone: yup
      .string()
      .matches(regexValidPhoneNumber, PHONE_VALID_ID)
      .required(REQUIRED_ID),
    contactAddress: yup.string().typeError(REQUIRED_ID).required(REQUIRED_ID),
    contactAddressAlt: yup
      .string()
      .typeError(REQUIRED_ID)
      .required(REQUIRED_ID),
    facebook: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
    instagram: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
    tiktok: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
    socialX: yup
      .string()
      .transform(emptyValueCleanup)
      .matches(urlRegex, URL_VALID_ID)
      .optional(),
  })
const HomeSettingsShape = yup
  .object()
  .optional()
  .shape({
    imageURL: yup.string().optional(),
    title: yup.string().typeError(REQUIRED_ID).required(REQUIRED_ID),
    subtitle: yup.string().optional(),
  })

export const WebsiteTabsSchema = yup.object().shape({
  tabs: yup.array().of(
    yup.object().shape({
      tabId: yup.string().required(),
      name: yup.string().required(),
    })
  ),
  currentTab: yup.string().nullable(),
  homeForm: HomeSettingsShape.when(
    "currentTab",
    (...values: Array<unknown>) => {
      const [currentTab, schema] = values as [
        WebsiteDefaultTabIdType,
        typeof HomeSettingsShape
      ]
      if (currentTab === WebsiteDefaultTabsIds.HOME)
        return schema.required() as typeof HomeSettingsShape
      return schema
    }
  ),
  settingsForm: WebsiteSettingsShape.when(
    "currentTab",
    (...values: Array<unknown>) => {
      const [currentTab, schema] = values as [
        WebsiteDefaultTabIdType,
        typeof WebsiteSettingsShape
      ]
      if (currentTab === WebsiteDefaultTabsIds.SETTINGS)
        return schema.required() as typeof WebsiteSettingsShape
      return schema
    }
  ),
})

export const WebsiteTabsResolver = yupResolver(WebsiteTabsSchema)
