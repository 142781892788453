import Header from "../../../../ui/Header"
import Content from "../../../../ui/Layouts/MainLayout/Content"
import { SaveWebsiteButton } from "./SaveWebsiteButton"
import WebsiteTabs from "./WebsiteTabs"
import { WebsiteDefaultTabsIds } from "./WebsiteTabs/utils/DefaultTabs"
import { WebsiteTabsForm } from "./hookform/hookforms.interface"
import { WebsiteTabsResolver } from "./hookform/schemas"
import { FormProvider, useForm } from "react-hook-form"
import { useIntl } from "react-intl"

export const Website = () => {
  const intl = useIntl()
  const form = useForm<WebsiteTabsForm>({
    resolver: WebsiteTabsResolver,
    defaultValues: {
      tabs: [],
      currentTab: WebsiteDefaultTabsIds.SETTINGS,
      settingsForm: {
        enabled: false,
      },
    },
    mode: "all",
  })

  return (
    <FormProvider {...form}>
      <Content
        header={
          <Header
            actions={SaveWebsiteButton}
            title={intl.formatMessage({
              id: "components.layouts.settings.restaurant.website.header",
              defaultMessage: "Website",
            })}
          />
        }
      >
        <WebsiteTabs />
      </Content>
    </FormProvider>
  )
}
