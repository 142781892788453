import ImagePlaceholderIcon from "../../../../../../../../shared/assets/icons/icon-placeholder-variant-1.svg"
import styles from "./SectionTooltip.module.css"
import { Box, Card, Group, Image, Paper, Stack, Text } from "@mantine/core"
import { useIntl } from "react-intl"

export const SectionTooltip = () => {
  const intl = useIntl()
  return (
    <Card maw={160} pb={8} radius="sm" bg={"gray.6"}>
      <Card.Section className={styles["card-section"]} p=".25rem" bg={"gray.5"}>
        <Box
          mih={"6rem"}
          miw={"8.5rem"}
          className={styles["section-tooltip-image"]}
        >
          <Group position="apart" w={"100%"}>
            <Stack spacing={6}>
              <Stack spacing={2}>
                <Paper bg={"gray.4"} h={4} w={56} radius={1} />
                <Paper bg={"gray.4"} h={4} w={56} radius={1} />
              </Stack>
              <Stack spacing={1}>
                <Paper bg={"gray.4"} h={2} w={56} radius={1} />
                <Paper bg={"gray.4"} h={2} w={56} radius={1} />
              </Stack>
              <Stack mt={3}>
                <Paper bg={"gray.4"} h={6} w={20} radius={1} />
              </Stack>
            </Stack>
            <Image src={ImagePlaceholderIcon} maw={34} />
          </Group>
        </Box>
      </Card.Section>
      <Text
        fz={12}
        mt={12}
        c={"white"}
        className={styles["text"]}
        ta={"center"}
      >
        {intl.formatMessage({
          id: "restaurant.settings.website.settings.home.hero.tooltip.text",
          defaultMessage:
            "Image stretches across the width. Feel free to include a title and description!",
        })}
      </Text>
    </Card>
  )
}
