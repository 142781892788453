/**
 * Paths configuration for routing
 */

export default {
  root: "/",
  error: "/error",
  general: {
    about: "/about",
    validate: "/validate-token",
    validateMember: "/validate-token-member",
    notFound: "/not-found",
    accessDenied: "/access-denied",
    tableScanner: "/table/:uuid",
  },
  guest: {
    login: "/login",
    loginCallback: "/login/callback",
    loginConfirmation: "/login/confirm-email",
    loginMemberConfirmation: "/login/confirm-email-member",
    loginError: "/login/invalid-token",
  },
  account: {
    root: "/account",
    settings: "/account/settings",
    profile: "/account/profile",
    support: "/account/help-support",
    roadmap: "/account/roadmap-changelog",
    community: "/account/join-slack-community",
  },
  onboarding: {
    root: "/onboarding",
    done: "/onboarding/done",
  },
  restaurants: {
    root: "/restaurants",
    create: "/restaurants/new",
    items: "/restaurants/items",
    catering: "/restaurants/catering",
    createProduct: "/restaurants/items/new",
    editProduct: (itemUUID = ":itemUUID") =>
      `/restaurants/items/${itemUUID}/edit`,
    createCombo: "/restaurants/items/combos/new",
    editCombo: (comboUUID = ":comboUUID") =>
      `/restaurants/items/combos/${comboUUID}/edit`,
    modifiers: "/restaurants/modifiers",
    createModifiers: "/restaurants/modifiers/new",
    editModifier: (modifierUUID = ":modifierUUID") =>
      `/restaurants/modifiers/${modifierUUID}/edit`,
    menu: "/restaurants/menu",
    createMenu: "/restaurants/menu/new",
    editMenu: (menuUUID = ":menuUUID") => `/restaurants/menu/${menuUUID}/edit`,
    orders: "/restaurants/orders",
    createOrder: "/restaurants/orders/new",
    orderDetail: (orderUUID = ":orderUUID") =>
      `/restaurants/order/${orderUUID}`,
    cateringDetail: (orderUUID = ":orderUUID") =>
      `/restaurants/catering/${orderUUID}`,
    customers: "/restaurants/customers",
    customerDetail: (userUUID = ":userUUID") =>
      `/restaurants/customer/${userUUID}`,
    devices: "/restaurants/devices",
    reports: "/restaurants/reports",
    // TODO: remove this path when cleaning apEnableReports flag
    sales: "/restaurants/sales",
    discounts: "/restaurants/discounts",
    createDiscounts: "/restaurants/discounts/new",
    editDiscounts: (discountUUID = ":discountUUID") =>
      `/restaurants/discounts/${discountUUID}/edit`,
    locations: {
      root: "/restaurants/location",
      customersByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/customers`,
      ordersByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/orders`,
      cateringByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/catering`,
      cateringByLocationDetail: (
        orderUUID = ":orderUUID",
        locationUUID = ":locationUUID"
      ) => `/restaurants/location/${locationUUID}/catering/${orderUUID}`,
      reportsByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/reports`,
      employeesByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/employees`,
      customerDetailByLocation: (
        locationUUID = ":locationUUID",
        userUUID = ":userUUID"
      ) => `/restaurants/location/${locationUUID}/customer/${userUUID}`,
      menusByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/menus`,
      itemsByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/items`,
      modifiersByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/modifiers`,
      createModifiersByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/modifiers/new`,
      editModifiersByLocation: (
        locationUUID = ":locationUUID",
        modifierUUID = ":modifierUUID"
      ) => {
        return `/restaurants/location/${locationUUID}/modifiers/${modifierUUID}/edit`
      },
      discountsByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/discounts`,
      createMenu: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/menus/new`,
      editMenuByLocation: (
        locationUUID = ":locationUUID",
        menuUUID = ":menuUUID"
      ) => {
        return `/restaurants/location/${locationUUID}/menus/${menuUUID}/edit`
      },
      createProductByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/items/new`,
      createComboByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/combos/new`,
      editItemByLocation: (
        locationUUID = ":locationUUID",
        itemUUID = ":itemUUID"
      ) => {
        return `/restaurants/location/${locationUUID}/items/${itemUUID}/edit`
      },
      editComboByLocation: (
        locationUUID = ":locationUUID",
        comboUUID = ":comboUUID"
      ) => `/restaurants/location/${locationUUID}/combos/${comboUUID}/edit`,
      payrollByLocation: (locationUUID = ":locationUUID") =>
        `/restaurants/location/${locationUUID}/payroll`,
    },
  },
  settings: {
    root: "/settings",
    restaurant: {
      root: "/settings/restaurant",
      edit: (restaurantUUID = ":restaurantUUID") =>
        `/settings/restaurants/${restaurantUUID}/`,
      delivery: "/settings/restaurant/delivery",
      taxes: "/settings/restaurant/taxes",
      createSalesTax: "/settings/restaurant/taxes/sales/new",
      createItemTax: "/settings/restaurant/taxes/item/new",
      editSalesTax: (taxUUID = ":taxUUID") =>
        `/settings/restaurant/taxes/sales/${taxUUID}`,
      editItemTax: (taxUUID = ":taxUUID") =>
        `/settings/restaurant/taxes/item/${taxUUID}`,
      members: "/settings/restaurant/members",
      qrCode: "/settings/restaurant/qr-code",
      restaurantInfo: "/settings/restaurant/info",
      loyaltyProgram: "/settings/restaurant/loyalty-program",
      banking: "/settings/restaurant/banking",
      plans: "/settings/restaurant/plans",
      mobileApp: "/settings/restaurant/mobile-app",
      website: "/settings/restaurant/website",
      catering: "/settings/restaurant/catering",
      integrations: {
        square: "/settings/restaurant/integrations/square",
        squareOAuth: "/settings/integrations/squareoauth",
      },
    },

    locations: {
      root: "/settings/location",
      create: "/settings/add-location",
      locationInfo: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}`,
      bankingByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/banking`,
      hoursOfOperation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/hours-of-operation`,
      orderThrottling: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/order-throttling`,
      deliveryByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/delivery`,
      employeesByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/employees`,
      tablesByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/tables`,
      devicesByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/devices`,
      integrationsByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/integrations`,
      editSquareIntegrationByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/integrations/square`,
      createSquareIntegrationByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/integrations/square/new`,
      cateringByLocation: (locationUUID = ":locationUUID") =>
        `/settings/location/${locationUUID}/catering`,
    },
  },
  locations: {
    root: "/locations",
  },
}
