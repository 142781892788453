export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  JSON: any
  Timestamp: any
}

export type AcceptedCateringOrder = {
  __typename?: "AcceptedCateringOrder"
  invoice?: Maybe<Invoice>
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type AddComboItemsInput = {
  comboItemGroupUUID: Scalars["String"]
  items: Array<ItemInput>
}

export type AddCommentToOrderInput = {
  comment: Scalars["String"]
  locationUUID?: InputMaybe<Scalars["ID"]>
  orderUUID: Scalars["ID"]
}

export type AddDeliveryIntegrationInput = {
  apiKey: Scalars["String"]
  developerId?: InputMaybe<Scalars["String"]>
  integrationLabel: Scalars["String"]
  integrationName: DeliveryIntegrationEnum
  settings?: InputMaybe<Scalars["String"]>
  signingSecret?: InputMaybe<Scalars["String"]>
}

export type AddItemToOrderInput = {
  itemUUID: Scalars["ID"]
  notes?: InputMaybe<Scalars["String"]>
  orderUUID: Scalars["ID"]
  quantity: Scalars["Int"]
  variantUUID: Scalars["ID"]
}

export type AddLocationIntegrationInput = {
  externalIntegration: RuuidInput
  externalLocations: Array<ExternalLocationInput>
  externalOrderLocations: Array<ExternalOrderLocationInput>
  integrationType: ExternalIntegrationEnum
}

export type AddModifierGroupInput = {
  deletedModifiers?: InputMaybe<Array<Scalars["ID"]>>
  modifiers: Array<ModifierInput>
  name: Scalars["String"]
  restaurantUUID: Scalars["ID"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type AddModifierGroupToItemInput = {
  itemUUID: Scalars["ID"]
  modifierGroups: Array<Scalars["ID"]>
}

export type AddModifierGroupToVariantInput = {
  modifierGroupUUID: Scalars["ID"]
  variantUUID: Scalars["ID"]
}

export type AddOrUpdateVariantModifierToItemInput = {
  modifierGroups: Array<VariantModifierGroupInput>
  orderDetailUUID: Scalars["ID"]
}

export type AddQrConfigToRestaurantInput = {
  qrConfig: Scalars["String"]
  restaurantUUID: Scalars["ID"]
}

export type AddRelationBankingAccountInput = {
  bankingAccountUUID: Scalars["ID"]
  locationUUID: Scalars["ID"]
}

export type AddWhiteLabelConfigInput = {
  attachment?: InputMaybe<CreateRestaurantAttachmentInput>
  isShouldPublish: Scalars["Boolean"]
  restaurantUUID: Scalars["ID"]
  whiteLabelConfig?: InputMaybe<Scalars["String"]>
}

export type AddressModel = {
  __typename?: "AddressModel"
  apartment?: Maybe<Scalars["String"]>
  city: Scalars["String"]
  country: Scalars["String"]
  isDefault: Scalars["Boolean"]
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  number: Scalars["String"]
  postalCode: Scalars["String"]
  state: Scalars["String"]
  street: Scalars["String"]
  uuid: Scalars["ID"]
}

export type AdminBasicModel = {
  __typename?: "AdminBasicModel"
  firstName: Scalars["String"]
  lastName?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

/** status of admin invitation */
export enum AdminInviteStatusEnum {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
  REVOKED = "REVOKED",
}

export type AdminOnboardingModel = {
  __typename?: "AdminOnboardingModel"
  isOnboardingCompleted: Scalars["DateTime"]
}

/** The admin role names */
export enum AdminRoleNameEnum {
  RESTAURANT_ADMIN = "RESTAURANT_ADMIN",
  RESTAURANT_EMPLOYEE = "RESTAURANT_EMPLOYEE",
  RESTAURANT_MANAGER = "RESTAURANT_MANAGER",
  RESTAURANT_OWNER = "RESTAURANT_OWNER",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export type AmountDetail = {
  __typename?: "AmountDetail"
  amount: Scalars["Float"]
  type: RefundMethodTypeEnum
}

export type AppHistoryModel = {
  __typename?: "AppHistoryModel"
  dateTime: Scalars["DateTime"]
  platform: AppPlatformEnum
  status: AppHistoryStatusEnum
  uuid: Scalars["String"]
  version?: Maybe<Scalars["String"]>
}

/** The status of app publishment */
export enum AppHistoryStatusEnum {
  FAILITURE = "FAILITURE",
  PUBLISHED = "PUBLISHED",
  WAITING_FOR_REVIEW = "WAITING_FOR_REVIEW",
}

/** The mobile platform */
export enum AppPlatformEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
}

/** The supported attachment content types */
export enum AttachmentContentTypeEnum {
  AVIF = "AVIF",
  JPEG = "JPEG",
  JPG = "JPG",
  PDF = "PDF",
  PNG = "PNG",
  WEBP = "WEBP",
}

/** The supported directories types */
export enum AttachmentDirectoryEnum {
  COMBOS = "COMBOS",
  EMPLOYEE = "EMPLOYEE",
  INGREDIENTS = "INGREDIENTS",
  ITEM = "ITEM",
  LOCATION = "LOCATION",
  MENUS = "MENUS",
  RESTAURANT = "RESTAURANT",
  VARIANTS = "VARIANTS",
}

/** The supported attachment extensions */
export enum AttachmentExtensionEnum {
  AVIF = "AVIF",
  JPEG = "JPEG",
  JPG = "JPG",
  PDF = "PDF",
  PNG = "PNG",
  WEBP = "WEBP",
}

export type AttachmentModel = {
  __typename?: "AttachmentModel"
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  signedUrl: Scalars["String"]
  uuid: Scalars["String"]
}

export type AttachmentSnapshot = {
  __typename?: "AttachmentSnapshot"
  contentType: AttachmentContentTypeEnum
  ext: AttachmentExtensionEnum
  fileName: Scalars["String"]
  signedUrl: Scalars["String"]
  uuid: Scalars["String"]
}

export type AttachmentSnapshotInput = {
  contentType: AttachmentContentTypeEnum
  ext: AttachmentExtensionEnum
  fileName: Scalars["String"]
  type: AttachmentTypeEnum
  uuid: Scalars["ID"]
}

/** The supported attachments types */
export enum AttachmentTypeEnum {
  APP_LOGO = "APP_LOGO",
  COVER = "COVER",
  LOGO = "LOGO",
  LOGO_HORIZONTAL = "LOGO_HORIZONTAL",
  MENU = "MENU",
  SF_COVER = "SF_COVER",
}

export type BankAccountBasicModel = {
  __typename?: "BankAccountBasicModel"
  name: Scalars["String"]
  stIsConnected: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type BankingAccountDataModel = {
  __typename?: "BankingAccountDataModel"
  stData?: Maybe<Scalars["JSON"]>
  uuid: Scalars["ID"]
}

export type BankingAccountModel = {
  __typename?: "BankingAccountModel"
  isActive: Scalars["Boolean"]
  name: Scalars["String"]
  stHasExternalAccount: Scalars["Boolean"]
  stIsConnected: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type BasicDeliveryLocation = {
  __typename?: "BasicDeliveryLocation"
  isDefault: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type BasicDiscount = {
  __typename?: "BasicDiscount"
  code: Scalars["String"]
  status: DiscountStatusesEnum
  uuid: Scalars["ID"]
}

export type BasicLocationIntegration = {
  __typename?: "BasicLocationIntegration"
  acceptInternalOrders: Scalars["Boolean"]
  externalId: Scalars["String"]
  externalName: Scalars["String"]
  type: ExternalIntegrationEnum
}

export type BasicLocationModel = {
  __typename?: "BasicLocationModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type BasicLocationTable = {
  __typename?: "BasicLocationTable"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type BasicRule = {
  __typename?: "BasicRule"
  allCombos: Scalars["Boolean"]
  allItems: Scalars["Boolean"]
  combos: Array<Ruuid>
  items: Array<Ruuid>
  stackingAvailable: Scalars["Boolean"]
}

export type BasicTimeEntry = {
  __typename?: "BasicTimeEntry"
  amount: Scalars["Float"]
  day: WeekDaysEnum
  duration: Scalars["Int"]
  endDate?: Maybe<Scalars["DateTime"]>
  isRunning: Scalars["Boolean"]
  modifiedEndDate?: Maybe<Scalars["DateTime"]>
  modifiedStartDate?: Maybe<Scalars["DateTime"]>
  notes?: Maybe<Scalars["String"]>
  startDate: Scalars["DateTime"]
  tips: Scalars["Float"]
  uuid: Scalars["String"]
}

export type BasicVariantModifierGroupModel = {
  __typename?: "BasicVariantModifierGroupModel"
  fieldType?: Maybe<FieldTypeEnumItem>
  isActive: Scalars["Boolean"]
  isRequired?: Maybe<Scalars["Boolean"]>
  itemOrder: Scalars["Int"]
  maxSelectedOptions?: Maybe<Scalars["Int"]>
  minSelectedOptions?: Maybe<Scalars["Int"]>
  uuid: Scalars["ID"]
}

export type BasicZoneModel = {
  __typename?: "BasicZoneModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type BestSellerItemsReport = {
  __typename?: "BestSellerItemsReport"
  count: Scalars["Float"]
  name: Scalars["String"]
}

export type CalculatedCateringPaymentAmount = {
  __typename?: "CalculatedCateringPaymentAmount"
  cash?: Maybe<PaymentDetailByMode>
  eReader?: Maybe<PaymentDetailByMode>
  isFree: Scalars["Boolean"]
  payment?: Maybe<PaymentDetailByMode>
  paymentLink?: Maybe<PaymentDetailByMode>
  tapToPay?: Maybe<PaymentDetailByMode>
}

export type CalculatedDiscount = {
  __typename?: "CalculatedDiscount"
  amountApplied: Scalars["Float"]
  code: Scalars["String"]
  entryMethod: DiscountsEntryMethodsEnum
  rule: BasicRule
  uuid: Scalars["ID"]
}

export type CancelOrderInput = {
  comment: Scalars["String"]
  order: RuuidInput
}

export type CancelPolicyInput = {
  percentage: Scalars["Float"]
  timeBeforeEvent: TimeBeforeEventEnum
}

export type CancelledOrder = {
  __typename?: "CancelledOrder"
  isPaidLater: Scalars["Boolean"]
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  refund?: Maybe<RefundBasic>
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type Categories = {
  __typename?: "Categories"
  foodCategoryUUID: Scalars["ID"]
}

export type CateringCancelPolicy = {
  __typename?: "CateringCancelPolicy"
  percentage: Scalars["Float"]
  timeBeforeEvent: TimeBeforeEventEnum
}

export type CateringOrder = {
  __typename?: "CateringOrder"
  eventEndDate?: Maybe<Scalars["DateTime"]>
  eventName?: Maybe<Scalars["String"]>
  headCount?: Maybe<Scalars["Int"]>
  serviceType?: Maybe<CateringServiceEnum>
}

export type CateringOrderInformation = {
  __typename?: "CateringOrderInformation"
  headCount?: Maybe<Scalars["Int"]>
  placedAt?: Maybe<Scalars["DateTime"]>
  serviceType?: Maybe<CateringServiceEnum>
}

export type CateringPrePayment = {
  __typename?: "CateringPrePayment"
  amount?: Maybe<Scalars["Float"]>
  percentage?: Maybe<Scalars["Float"]>
}

/** The available services of a catering */
export enum CateringServiceEnum {
  DELIVERY = "DELIVERY",
  FOOD_TRUCK_EXPERIENCE = "FOOD_TRUCK_EXPERIENCE",
  INSIDE_VENUE = "INSIDE_VENUE",
  PICK_UP = "PICK_UP",
}

export type CateringServices = {
  __typename?: "CateringServices"
  feeFixedAmount?: Maybe<Scalars["Float"]>
  feePercentage?: Maybe<Scalars["Float"]>
  minimumAmount?: Maybe<Scalars["Float"]>
  type: CateringServiceEnum
  uuid: Scalars["ID"]
}

export type ComboBasicModel = {
  __typename?: "ComboBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ComboDetailModel = {
  __typename?: "ComboDetailModel"
  comboIdentifier: Scalars["String"]
  items: Array<ItemDetailModel>
  name: Scalars["String"]
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type ComboItemBasicModel = {
  __typename?: "ComboItemBasicModel"
  deletedAt?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type ComboItemDetailModel = {
  __typename?: "ComboItemDetailModel"
  deletedAt?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  item: ItemWithAttachmentModel
  uuid: Scalars["ID"]
}

export type ComboItemGroupBasicModel = {
  __typename?: "ComboItemGroupBasicModel"
  deletedAt?: Maybe<Scalars["DateTime"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ComboItemGroupDetailModel = {
  __typename?: "ComboItemGroupDetailModel"
  combo: ComboBasicModel
  comboItems: Array<ComboItemDetailModel>
  deletedAt?: Maybe<Scalars["DateTime"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ComboItemGroupWithComboItemModel = {
  __typename?: "ComboItemGroupWithComboItemModel"
  comboItems: Array<ComboItemBasicModel>
  deletedAt?: Maybe<Scalars["DateTime"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ComboItemWithVariantsModel = {
  __typename?: "ComboItemWithVariantsModel"
  comboItemGroup: ComboItemGroupBasicModel
  deletedAt?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  item: ItemWithVariantsModel
  uuid: Scalars["ID"]
}

export type ComboModel = {
  __typename?: "ComboModel"
  attachments?: Maybe<Array<AttachmentModel>>
  deletedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  discountPercentage: Scalars["Float"]
  isActive: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  menus?: Maybe<Array<MenuBasicModel>>
  name: Scalars["String"]
  price: Scalars["Float"]
  priceOverride: Scalars["Float"]
  restaurant: RestaurantBasicModel
  uuid: Scalars["ID"]
}

export type ComboWithAttachmentsModel = {
  __typename?: "ComboWithAttachmentsModel"
  attachments?: Maybe<Array<AttachmentModel>>
  description?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type ComboWithComboItemGroupsModel = {
  __typename?: "ComboWithComboItemGroupsModel"
  comboItemGroup: ComboItemGroupBasicModel
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

/** The supported content types */
export enum ContentTypeEnum {
  AVIF = "AVIF",
  JPEG = "JPEG",
  JPG = "JPG",
  PDF = "PDF",
  PNG = "PNG",
  WEBP = "WEBP",
}

export type CreateAddressInput = {
  apartment?: InputMaybe<Scalars["String"]>
  city: Scalars["String"]
  country: Scalars["String"]
  isDefault: Scalars["Boolean"]
  latitude: Scalars["String"]
  longitude: Scalars["String"]
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  number: Scalars["String"]
  postalCode: Scalars["String"]
  restaurantUUID: Scalars["ID"]
  state: Scalars["String"]
  street: Scalars["String"]
  userUUID: Scalars["ID"]
}

export type CreateAdminManagerInput = {
  email: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  locationUUIDs?: InputMaybe<Array<Scalars["ID"]>>
  restaurantUUID: Scalars["ID"]
  roleName: AdminRoleNameEnum
}

export type CreateAttachmentIngredientInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  ingredientUUID: Scalars["ID"]
}

export type CreateAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
}

export type CreateComboAttachmentInput = {
  comboUUID: Scalars["ID"]
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
}

export type CreateComboInput = {
  attachments?: InputMaybe<Array<CreateAttachmentInput>>
  description?: InputMaybe<Scalars["String"]>
  discountPercentage?: InputMaybe<Scalars["Float"]>
  menusUUID?: InputMaybe<Array<Scalars["String"]>>
  name: Scalars["String"]
  priceOverride?: InputMaybe<Scalars["Float"]>
  restaurantUUID: Scalars["ID"]
}

export type CreateComboItemGroupInput = {
  comboItemGroupName: Scalars["String"]
  comboName?: InputMaybe<Scalars["String"]>
  comboUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID?: InputMaybe<Scalars["ID"]>
}

export type CreateCommentInput = {
  comment: Scalars["String"]
  order: RuuidInput
}

export type CreateCustomerInput = {
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["String"]
  phone: Scalars["String"]
  restaurantUUID: Scalars["String"]
}

export type CreateDeliveryLocationManyInput = {
  deliveryIntegrations: Array<UuidIsDefaultInput>
  location: RuuidInput
}

export type CreateDeliveryPriceInput = {
  label?: InputMaybe<Scalars["String"]>
  location: RuuidInput
  maxDistance: Scalars["Int"]
  price: Scalars["Float"]
}

export type CreateDeviceInput = {
  code: Scalars["String"]
  deviceKey: Scalars["String"]
  functionType: DeviceFunctionEnum
  locationUUID: Scalars["ID"]
}

export type CreateDiscountInput = {
  code: Scalars["String"]
  entryMethod: DiscountsEntryMethodsEnum
  fixedAmount?: InputMaybe<Scalars["Float"]>
  percentage?: InputMaybe<Scalars["Float"]>
  rule: CreateDiscountRuleInput
}

export type CreateDiscountRuleInput = {
  allCombos: Scalars["Boolean"]
  allItems: Scalars["Boolean"]
  combos: Array<Scalars["String"]>
  endDate?: InputMaybe<Scalars["String"]>
  generatedQuantity?: InputMaybe<Scalars["Int"]>
  items: Array<Scalars["String"]>
  lifetimeSpend?: InputMaybe<Scalars["Float"]>
  limitPerCustomer?: InputMaybe<Scalars["Int"]>
  locations: Array<Scalars["String"]>
  orderTypes: Array<DiscountsOrderMethodEnum>
  stackingAvailable: Scalars["Boolean"]
  startDate: Scalars["String"]
  users: Array<Scalars["String"]>
}

export type CreateEmployeeInput = {
  allowTip: Scalars["Boolean"]
  attachment?: InputMaybe<CreateAttachmentInput>
  code: Scalars["String"]
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  payRate: Scalars["Float"]
  phone: Scalars["String"]
  role: EmployeeRoleNameEnum
}

export type CreateExternalAccountInput = {
  bankingAccountUUID: Scalars["ID"]
  token: Scalars["String"]
}

export type CreateItemAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  itemUUID: Scalars["ID"]
}

export type CreateItemTaxLocationInput = {
  itemUUIDs: Array<Scalars["ID"]>
  locationUUIDs: Array<Scalars["ID"]>
  taxUUID: Scalars["ID"]
}

export type CreateLocationCateringRulesInput = {
  cancelPolicies?: InputMaybe<Array<CancelPolicyInput>>
  employees: Array<RuuidInput>
  orderMethodAcceptance: OrderMethodAcceptanceEnum
  orderScheduling: Scalars["Float"]
  prePayment: PrePaymentInput
  services: Array<ServicesInput>
}

export type CreateLocationInput = {
  address: Scalars["String"]
  attachment?: InputMaybe<CreateAttachmentInput>
  countryCode?: InputMaybe<Scalars["String"]>
  estimatedPreparationTime?: InputMaybe<Scalars["Int"]>
  latitude?: InputMaybe<Scalars["String"]>
  longitude?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  orderTypes: Array<OrderMethodEnum>
  phone?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  timezone?: InputMaybe<Scalars["String"]>
}

export type CreateLocationScheduleInput = {
  description?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["String"]
  name: Scalars["String"]
  restaurantUUID: Scalars["String"]
  schedules: Array<ScheduleDetail>
}

export type CreateLocationTableInput = {
  capacity: Scalars["Int"]
  description?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["ID"]
  name: Scalars["String"]
  qrPath: Scalars["String"]
  status: StatusTableEnum
  tableIdentifier: Scalars["String"]
  tableZoneUUID: Scalars["ID"]
}

export type CreateLocationTaxInput = {
  locationUUIDs: Array<Scalars["ID"]>
  taxUUID: Scalars["ID"]
}

export type CreateMenuItemsInput = {
  itemUUID: Scalars["ID"]
  menusUUIDs: Array<Scalars["String"]>
}

export type CreateOnboardingLinkInput = {
  name: Scalars["String"]
  restaurantUUID: Scalars["ID"]
}

export type CreateOrUpdateHolidayInput = {
  date: Scalars["String"]
  endTime?: InputMaybe<Scalars["String"]>
  isOpen: Scalars["Boolean"]
  location: RuuidInput
  name: Scalars["String"]
  startTime?: InputMaybe<Scalars["String"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type CreateOrUpdateLoyaltyProgramInput = {
  conversionRate?: InputMaybe<Scalars["Int"]>
  increment?: InputMaybe<Scalars["Int"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  loyaltyProgramUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
}

export type CreateOrUpdateRestaurantSnapshotInput = {
  snapshot: RestaurantSnapshotInput
  userUUID: Scalars["ID"]
}

export type CreateOrderInput = {
  locationUUID: Scalars["ID"]
  notes?: InputMaybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  restaurantUUID: Scalars["ID"]
  tableUUID?: InputMaybe<Scalars["ID"]>
  userUUID: Scalars["ID"]
}

export type CreateOrderThrottlingInput = {
  defaultIntervalTime?: InputMaybe<Scalars["Float"]>
  defaultOrdersLimit: Scalars["Float"]
  isActive?: InputMaybe<Scalars["Boolean"]>
  location: RuuidInput
  rules: Array<CreateOrderThrottlingRuleInput>
}

export type CreateOrderThrottlingRuleInput = {
  day: WeekDaysEnum
  endDate: Scalars["String"]
  ordersLimit: Scalars["Float"]
  startDate: Scalars["String"]
}

export type CreateRefundInput = {
  amountToRefund: Scalars["Float"]
  cashToRefund?: InputMaybe<Scalars["Float"]>
  comment: Scalars["String"]
  location: RuuidInput
  order: RuuidInput
  storeCashToRefund: Scalars["Float"]
  tipToRefund: Scalars["Float"]
}

export type CreateRestaurantAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  type: AttachmentTypeEnum
}

export type CreateRestaurantInput = {
  locationsQuantity?: InputMaybe<Scalars["String"]>
  logo?: InputMaybe<CreateRestaurantAttachmentInput>
  name: Scalars["String"]
  urlIdentifier: Scalars["String"]
  userUUID: Scalars["ID"]
}

export type CreateRestaurantScheduleInput = {
  description?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  restaurantUUID: Scalars["String"]
  schedules: Array<ScheduleDetail>
}

export type CreateScheduleForLocationInput = {
  locationUUID: Scalars["ID"]
  restaurantUUID: Scalars["ID"]
  schedules: Array<ScheduleDetail>
}

export type CreateSubscriptionPaymentIntentInput = {
  restaurant: RuuidInput
  subscriptionTier: SubscriptionTierEnum
}

export type CreateTableZoneInput = {
  locationUUID: Scalars["ID"]
  name: Scalars["String"]
}

export type CreateTaxInput = {
  applyFutureLocations: Scalars["Boolean"]
  description?: InputMaybe<Scalars["String"]>
  isGeneral: Scalars["Boolean"]
  name: Scalars["String"]
  restaurantUUID: Scalars["ID"]
  taxRate: Scalars["Float"]
}

export type CreateTempAttachmentInput = {
  attachments: Array<TempAttachmentInput>
  directory: AttachmentDirectoryEnum
}

export type CreateVariantInput = {
  attachment?: InputMaybe<CreateAttachmentInput>
  calories?: InputMaybe<Scalars["Int"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  itemUUID: Scalars["ID"]
  name: Scalars["String"]
  price?: InputMaybe<Scalars["Float"]>
  sku?: InputMaybe<Scalars["String"]>
}

export type CreateVariantModifierGroupInput = {
  ingredientsQuantity: Scalars["Int"]
  itemOrder: Scalars["Int"]
  maxSelectedOptions: Scalars["Int"]
  minSelectedOptions: Scalars["Int"]
  modifierGroupDescription?: InputMaybe<Scalars["String"]>
  modifierGroupName?: InputMaybe<Scalars["String"]>
  variantUUID: Scalars["ID"]
}

export type CustomerBasicModel = {
  __typename?: "CustomerBasicModel"
  firstName?: Maybe<Scalars["String"]>
  formattedPhone?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type CustomerInformationModel = {
  __typename?: "CustomerInformationModel"
  email?: Maybe<Scalars["String"]>
  firstName?: Maybe<Scalars["String"]>
  formattedPhone?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  lifeTimeSpend?: Maybe<Scalars["Float"]>
  locations: Array<LocationBasicModel>
  loyaltyPoints?: Maybe<Scalars["Int"]>
  phone?: Maybe<Scalars["String"]>
  totalOrders?: Maybe<Scalars["Int"]>
  uuid: Scalars["ID"]
}

export type CustomerPortalSubscriptionSession = {
  __typename?: "CustomerPortalSubscriptionSession"
  url: Scalars["String"]
}

export type CustomerSupportDetail = {
  __typename?: "CustomerSupportDetail"
  discounts: Scalars["Float"]
  loyalty: Scalars["Float"]
  refunds: Scalars["Float"]
  total: Scalars["Float"]
}

export type CustomerWithLocationModel = {
  __typename?: "CustomerWithLocationModel"
  email?: Maybe<Scalars["String"]>
  firstName?: Maybe<Scalars["String"]>
  formattedPhone?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  locations: Array<LocationBasicModel>
  phone?: Maybe<Scalars["String"]>
  userUUID: Scalars["ID"]
}

export type CustomerWithUserBasicModel = {
  __typename?: "CustomerWithUserBasicModel"
  user: UserBasicModel
  uuid: Scalars["ID"]
}

export type DeleteAttachmentInput = {
  attachmentUUID: Scalars["ID"]
  restaurantUUID: Scalars["ID"]
}

export type DeleteComboAttachmentInput = {
  comboUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type DeleteDeliveryLocationInput = {
  location: RuuidInput
  uuid: Scalars["ID"]
}

export type DeleteDeliveryPriceInput = {
  location: RuuidInput
  uuid: Scalars["ID"]
}

export type DeleteItemAttachmentInput = {
  itemUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type DeleteItemOrderInput = {
  orderDetailUUID: Scalars["ID"]
  orderUUID: Scalars["ID"]
}

export type DeleteItemTaxesByLocationsInput = {
  itemUUID: Scalars["ID"]
  locationUUIDs: Array<Scalars["ID"]>
  taxUUID: Scalars["ID"]
}

export type DeleteLocationIntegrationInput = {
  externalIntegration: RuuidInput
}

export type DeleteMemberInput = {
  email: Scalars["String"]
  inviteUUID: Scalars["ID"]
  restaurantUUID: Scalars["ID"]
  userUUID?: InputMaybe<Scalars["ID"]>
}

export type DeleteModifierGroupToVariantInput = {
  modifierGroupUUID: Scalars["ID"]
  variantUUID: Scalars["ID"]
}

export type DeleteModifierInput = {
  modifierGroupUUID: Scalars["ID"]
  modifierUUID: Scalars["ID"]
}

export type DeleteTaxLocationInput = {
  locationUUIDs: Array<Scalars["ID"]>
  taxUUID: Scalars["ID"]
}

export type DeleteTimEntryInput = {
  employee: RuuidInput
  uuid: Scalars["ID"]
}

export type DeletedLocationIntegration = {
  __typename?: "DeletedLocationIntegration"
  externalId: Scalars["String"]
  externalName: Scalars["String"]
}

export type DeletedRestaurantAttachmentModel = {
  __typename?: "DeletedRestaurantAttachmentModel"
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  type: Scalars["String"]
  uuid: Scalars["String"]
}

export type DeliveryIntegrationBasicModel = {
  __typename?: "DeliveryIntegrationBasicModel"
  integrationLabel: Scalars["String"]
  integrationName: DeliveryIntegrationEnum
  uuid: Scalars["ID"]
}

/** The integrated service names for delivery */
export enum DeliveryIntegrationEnum {
  DOORDASH = "DOORDASH",
  ONFLEET = "ONFLEET",
}

export type DeliveryIntegrationInOrder = {
  __typename?: "DeliveryIntegrationInOrder"
  order: Ruuid
  trackingURL: Scalars["String"]
}

export type DeliveryIntegrationWithLocationsModel = {
  __typename?: "DeliveryIntegrationWithLocationsModel"
  apiKey: Scalars["String"]
  integrationLabel: Scalars["String"]
  integrationName: DeliveryIntegrationEnum
  isActive: Scalars["Boolean"]
  issues?: Maybe<Scalars["String"]>
  relatedLocations?: Maybe<Array<LocationWithDeliveryDefaultModel>>
  settings?: Maybe<Scalars["JSON"]>
  uuid: Scalars["ID"]
}

export type DeliveryLocation = {
  __typename?: "DeliveryLocation"
  deliveryIntegration: RDeliveryIntegration
  id: Scalars["Float"]
  isDefault: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type DeliveryPrice = {
  __typename?: "DeliveryPrice"
  label?: Maybe<Scalars["String"]>
  maxDistance: Scalars["Int"]
  minDistance: Scalars["Int"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type DeviceAction = {
  __typename?: "DeviceAction"
  action: KdsDeviceActionsEnum
  location: Ruuid
}

export type DeviceAuthModel = {
  __typename?: "DeviceAuthModel"
  code: Scalars["String"]
  codeGeneratedAt?: Maybe<Scalars["DateTime"]>
  uuid: Scalars["ID"]
}

export type DeviceCode = {
  __typename?: "DeviceCode"
  code: Scalars["String"]
}

/** The function of the devices */
export enum DeviceFunctionEnum {
  KDS = "KDS",
  KIOSK = "KIOSK",
  POS = "POS",
}

export type DeviceModel = {
  __typename?: "DeviceModel"
  code?: Maybe<Scalars["String"]>
  deviceKey: Scalars["String"]
  functionType: DeviceFunctionEnum
  isActive: Scalars["Boolean"]
  lastChecking?: Maybe<Scalars["DateTime"]>
  location?: Maybe<BasicLocationModel>
  locationId: Scalars["Float"]
  status: DeviceStatusEnum
  uuid: Scalars["ID"]
  validatedAt?: Maybe<Scalars["DateTime"]>
}

/** The status of the devices */
export enum DeviceStatusEnum {
  CONNECTED = "CONNECTED",
  PENDING = "PENDING",
}

export type Devices = {
  __typename?: "Devices"
  deviceKey: Scalars["String"]
  functionType: DeviceFunctionEnum
  isActive: Scalars["Boolean"]
  lastChecking?: Maybe<Scalars["DateTime"]>
  settings?: Maybe<Scalars["JSON"]>
  status: DeviceStatusEnum
  uuid: Scalars["ID"]
  validatedAt?: Maybe<Scalars["DateTime"]>
}

export type DisableEmployeeInput = {
  uuid: Scalars["ID"]
}

export type DisableLocationScheduleInput = {
  locationUUID: Scalars["ID"]
  scheduleUUID: Scalars["ID"]
}

export type DisableLocationTableInput = {
  locationUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type DisableRestaurantInput = {
  adminUUID: Scalars["ID"]
  restaurantUUID: Scalars["ID"]
}

export type DisableRestaurantScheduleInput = {
  restaurantUUID: Scalars["ID"]
  scheduleUUID: Scalars["ID"]
}

export type DisableTableZoneInput = {
  locationUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type Discount = {
  __typename?: "Discount"
  code: Scalars["String"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  entryMethod: DiscountsEntryMethodsEnum
  fixedAmount?: Maybe<Scalars["Float"]>
  percentage?: Maybe<Scalars["Float"]>
  rule?: Maybe<Rule>
  status: DiscountStatusesEnum
  subStatus?: Maybe<DiscountSubStatusEnum>
  uuid: Scalars["ID"]
}

export type DiscountDetail = {
  __typename?: "DiscountDetail"
  amountApplied: Scalars["Float"]
  code?: Maybe<Scalars["String"]>
}

/** Discounts statuses */
export enum DiscountStatusesEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/** Discounts sub status of inactive status */
export enum DiscountSubStatusEnum {
  EXPIRED = "EXPIRED",
  MANUALLY_DISABLED = "MANUALLY_DISABLED",
  ORDER_TYPE_UNAVAILABLE = "ORDER_TYPE_UNAVAILABLE",
  USAGE_LIMIT_REACHED = "USAGE_LIMIT_REACHED",
}

/** Discounts entry methods */
export enum DiscountsEntryMethodsEnum {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

/** The order method for discounts */
export enum DiscountsOrderMethodEnum {
  ALL = "ALL",
  CURBSIDE = "CURBSIDE",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  DRIVE_THRU = "DRIVE_THRU",
  TAKE_OUT = "TAKE_OUT",
}

export type DiscountsSummary = {
  __typename?: "DiscountsSummary"
  code: Scalars["String"]
  discount: Scalars["Float"]
  discountType: Scalars["String"]
  quantity: Scalars["Float"]
  total: Scalars["Float"]
}

export type DraftRestaurant = {
  __typename?: "DraftRestaurant"
  snapshot: RestaurantSnapshot
  uuid: Scalars["ID"]
}

export type DuplicateComboInput = {
  comboUUID: Scalars["ID"]
  isDuplicateMenus?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
}

export type DuplicateItemInput = {
  isDuplicateMenus?: InputMaybe<Scalars["Boolean"]>
  isDuplicateTaxes?: InputMaybe<Scalars["Boolean"]>
  item: RuuidInput
  name: Scalars["String"]
}

export type DuplicatedComboModel = {
  __typename?: "DuplicatedComboModel"
  attachments?: Maybe<Array<AttachmentModel>>
  description?: Maybe<Scalars["String"]>
  discountPercentage: Scalars["Float"]
  isActive: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  price: Scalars["Float"]
  priceOverride: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type DuplicatedItem = {
  __typename?: "DuplicatedItem"
  attachment?: Maybe<AttachmentModel>
  createdAt: Scalars["DateTime"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name?: Maybe<Scalars["String"]>
  publishedAt?: Maybe<Scalars["DateTime"]>
  serves?: Maybe<Scalars["Int"]>
  status: ItemProgressStatusEnum
  uuid: Scalars["ID"]
}

export type Employee = {
  __typename?: "Employee"
  allowTip: Scalars["Boolean"]
  attachment?: Maybe<AttachmentModel>
  code: Scalars["String"]
  deactivatedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  payRate?: Maybe<Scalars["Float"]>
  phone: Scalars["String"]
  role: RoleInfo
  uuid: Scalars["ID"]
}

export type EmployeeBasic = {
  __typename?: "EmployeeBasic"
  deactivatedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  phone: Scalars["String"]
  uuid: Scalars["ID"]
}

/** The supported code for employee role */
export enum EmployeeRoleCodeEnum {
  REEMP = "REEMP",
}

/** The supported name for employe role */
export enum EmployeeRoleNameEnum {
  BARISTA = "BARISTA",
  BUSSER = "BUSSER",
  CASH = "CASH",
  DISWASHER = "DISWASHER",
  KITCHEN_MANAGER = "KITCHEN_MANAGER",
  LINE_COOK = "LINE_COOK",
  RESTAURANT_EMPLOYEE = "RESTAURANT_EMPLOYEE",
  RUNNER = "RUNNER",
  SERVER = "SERVER",
}

export type EmployeeTimeEntriesReport = {
  __typename?: "EmployeeTimeEntriesReport"
  employeeTimeEntries: Array<LocationEmployeeTimeEntry>
  notDistributedTips: Scalars["Float"]
  payrollReport: PayrollReport
  refundedInvoices: Array<RefundedInvoice>
}

export type EmployeeWithLastTimeEntry = {
  __typename?: "EmployeeWithLastTimeEntry"
  allowTip: Scalars["Boolean"]
  attachment?: Maybe<AttachmentModel>
  code: Scalars["String"]
  deactivatedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  lastTimeEntry?: Maybe<LastTimeEntry>
  payRate?: Maybe<Scalars["Float"]>
  phone: Scalars["String"]
  role: RoleInfo
  uuid: Scalars["ID"]
}

export type ExternalCurbside = {
  __typename?: "ExternalCurbside"
  customerArrivedAt?: Maybe<Scalars["DateTime"]>
  details?: Maybe<Scalars["String"]>
}

export type ExternalCustomer = {
  __typename?: "ExternalCustomer"
  firstName?: Maybe<Scalars["String"]>
}

export type ExternalDelivery = {
  __typename?: "ExternalDelivery"
  cancelReason?: Maybe<Scalars["String"]>
  dropoffNotes?: Maybe<Scalars["String"]>
  note?: Maybe<Scalars["String"]>
}

export type ExternalIntegration = {
  __typename?: "ExternalIntegration"
  accessToken: Scalars["String"]
  appId: Scalars["String"]
  externalId: Scalars["String"]
  locationIntegration?: Maybe<BasicLocationIntegration>
  locationOrderIntegrations: Array<BasicLocationIntegration>
  source: ExternalSourceEnum
  uuid: Scalars["String"]
}

/** The type of integration */
export enum ExternalIntegrationEnum {
  MENU = "MENU",
  ORDER_AGGREGATION = "ORDER_AGGREGATION",
}

export type ExternalItem = {
  __typename?: "ExternalItem"
  name?: Maybe<Scalars["String"]>
  notes?: Maybe<Scalars["String"]>
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
  variant?: Maybe<ExternalVariant>
}

export type ExternalLocation = {
  __typename?: "ExternalLocation"
  id: Scalars["String"]
  name: Scalars["String"]
}

export type ExternalLocationInput = {
  id: Scalars["String"]
  name: Scalars["String"]
}

export type ExternalModifier = {
  __typename?: "ExternalModifier"
  name: Scalars["String"]
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type ExternalOrder = {
  __typename?: "ExternalOrder"
  curbsideDetails?: Maybe<ExternalCurbside>
  customer: ExternalCustomer
  deliveryDate: Scalars["DateTime"]
  deliveryDetails?: Maybe<ExternalDelivery>
  externalId: Scalars["String"]
  externalOrderNumber?: Maybe<Scalars["String"]>
  /** this field came only on square production api */
  isScheduled: Scalars["Boolean"]
  notes?: Maybe<Scalars["String"]>
  orderDetails: Array<ExternalOrderDetails>
  orderMethod: OrderMethodEnum
  orderNumber?: Maybe<Scalars["String"]>
  orderTime: Scalars["DateTime"]
  paymentDetails: Array<ExternalPaymentDetails>
  pendingOrderTime?: Maybe<Scalars["DateTime"]>
  source: ExternalSourceEnum
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type ExternalOrderDetails = {
  __typename?: "ExternalOrderDetails"
  item?: Maybe<ExternalItem>
}

export type ExternalOrderLocationInput = {
  acceptInternalOrders: Scalars["Boolean"]
  id: Scalars["String"]
  name: Scalars["String"]
}

export type ExternalPaymentDetails = {
  __typename?: "ExternalPaymentDetails"
  amount: Scalars["Float"]
  externalId?: Maybe<Scalars["String"]>
  /** it is string to support many types of integrations */
  type: Scalars["String"]
}

/** The source of integration */
export enum ExternalSourceEnum {
  SQUARE = "SQUARE",
}

export type ExternalVariant = {
  __typename?: "ExternalVariant"
  modifiers: Array<ExternalModifier>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type FeeCategoryWithRestaurantModel = {
  __typename?: "FeeCategoryWithRestaurantModel"
  feeRate: Scalars["Float"]
  name: Scalars["String"]
  restaurants?: Maybe<Array<RestaurantBasicModel>>
  uuid: Scalars["ID"]
}

/** The supported field type */
export enum FieldTypeEnumItem {
  CHECKBOX = "CHECKBOX",
  COUNTER = "COUNTER",
  RADIO = "RADIO",
}

/** The supported file extensions */
export enum FileExtensionEnum {
  AVIF = "AVIF",
  JPEG = "JPEG",
  JPG = "JPG",
  PNG = "PNG",
  WEBP = "WEBP",
}

export type FinancialReport = {
  __typename?: "FinancialReport"
  accountBalance: Scalars["Float"]
  cardOrders: Scalars["Float"]
  cashOrders: Scalars["Float"]
  deliveryFees: Scalars["Float"]
  discounts: Scalars["Float"]
  grossSales: Scalars["Float"]
  incomingPayout: Scalars["Float"]
  loyaltyProgram: Scalars["Float"]
  netSales: Scalars["Float"]
  refunds: Scalars["Float"]
  storeCash: Scalars["Float"]
  taxes: Scalars["Float"]
  tips: Scalars["Float"]
  transactionFees: Scalars["Float"]
  transactions: Scalars["Float"]
}

export type FinancialSummary = {
  __typename?: "FinancialSummary"
  currentPeriod: FinancialReport
  previousPeriod: FinancialReport
}

export type GeneralDetail = {
  __typename?: "GeneralDetail"
  cateringFee: Scalars["Float"]
  serviceFee: Scalars["Float"]
  subtotal: Scalars["Float"]
  taxes: Scalars["Float"]
  total: Scalars["Float"]
}

export type GenerateDeviceCodeInput = {
  location: RuuidInput
  uuid: Scalars["ID"]
}

export type GrossSales = {
  __typename?: "GrossSales"
  amount: Scalars["Float"]
  items: Scalars["Float"]
  serviceCharges: Scalars["Float"]
}

export type Holiday = {
  __typename?: "Holiday"
  date: Scalars["DateTime"]
  endTime?: Maybe<Scalars["DateTime"]>
  isOpen: Scalars["Boolean"]
  name: Scalars["String"]
  startTime?: Maybe<Scalars["DateTime"]>
  uuid: Scalars["ID"]
}

export type IngredientBasicModel = {
  __typename?: "IngredientBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type IngredientBasicWithAttachmentModel = {
  __typename?: "IngredientBasicWithAttachmentModel"
  attachment?: Maybe<AttachmentModel>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type IngredientModel = {
  __typename?: "IngredientModel"
  attachment?: Maybe<AttachmentModel>
  containsNuts?: Maybe<Scalars["Boolean"]>
  description?: Maybe<Scalars["String"]>
  iconName?: Maybe<Scalars["String"]>
  imagePath?: Maybe<Scalars["String"]>
  isGlutenFree?: Maybe<Scalars["Boolean"]>
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  tags?: Maybe<Array<Scalars["String"]>>
  uuid: Scalars["ID"]
}

export type Integration = {
  __typename?: "Integration"
  accessToken: Scalars["String"]
  appId: Scalars["String"]
  externalId: Scalars["String"]
  source: ExternalSourceEnum
  uuid: Scalars["String"]
}

export type InviteBasicModel = {
  __typename?: "InviteBasicModel"
  email: Scalars["String"]
  firstName: Scalars["String"]
  inviteStatus: AdminInviteStatusEnum
  inviteUUID: Scalars["ID"]
  lastName: Scalars["String"]
}

export type Invoice = {
  __typename?: "Invoice"
  cashDiscount: Scalars["Float"]
  cateringFee: Scalars["Float"]
  deliveryFee: Scalars["Float"]
  discountDetails?: Maybe<Array<BasicDiscount>>
  discountsTotal: Scalars["Float"]
  invoiceNumber: Scalars["String"]
  serviceFee: Scalars["Float"]
  storeCash: Scalars["Float"]
  subTotal: Scalars["Float"]
  tip: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type InvoiceBasicModel = {
  __typename?: "InvoiceBasicModel"
  cateringFee?: Maybe<Scalars["Float"]>
  deliveryFee?: Maybe<Scalars["Float"]>
  discountDetails?: Maybe<Array<DiscountDetail>>
  discountsTotal: Scalars["Float"]
  serviceFee: Scalars["Float"]
  storeCash: Scalars["Float"]
  subTotal: Scalars["Float"]
  tip: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type ItemBasicModel = {
  __typename?: "ItemBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ItemBasicWithAttachmentModel = {
  __typename?: "ItemBasicWithAttachmentModel"
  attachment?: Maybe<AttachmentModel>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ItemCommonModel = {
  __typename?: "ItemCommonModel"
  description?: Maybe<Scalars["String"]>
  isPublished?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type ItemDetailModel = {
  __typename?: "ItemDetailModel"
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  orderDetailUUID: Scalars["ID"]
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
  variant: VariantDetailModel
}

export type ItemInput = {
  isDefault: Scalars["Boolean"]
  itemUUID: Scalars["ID"]
}

export type ItemList = {
  __typename?: "ItemList"
  attachment?: Maybe<AttachmentModel>
  createdAt: Scalars["DateTime"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name?: Maybe<Scalars["String"]>
  publishedAt?: Maybe<Scalars["DateTime"]>
  serves?: Maybe<Scalars["Int"]>
  status: ItemProgressStatusEnum
  uuid: Scalars["ID"]
}

export type ItemModel = {
  __typename?: "ItemModel"
  attachments?: Maybe<Array<AttachmentModel>>
  createdAt: Scalars["DateTime"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  isActive: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  menuItems?: Maybe<Array<MenuItemsModel>>
  name: Scalars["String"]
  optionGroups?: Maybe<Array<OptionGroupModel>>
  price: Scalars["Float"]
  restaurant: RestaurantBasicModel
  uuid: Scalars["String"]
}

/** The progress status on an item */
export enum ItemProgressStatusEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  UNPUBLISHED = "UNPUBLISHED",
}

export type ItemSalesSummary = {
  __typename?: "ItemSalesSummary"
  currentPeriod: Array<BestSellerItemsReport>
  previousPeriod: Array<BestSellerItemsReport>
}

/** The item status */
export enum ItemStatusEnum {
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
  OPEN = "OPEN",
  PREPARING = "PREPARING",
}

export type ItemTaxWithLocationModel = {
  __typename?: "ItemTaxWithLocationModel"
  item: ItemBasicModel
  location: LocationBasicModel
  tax: TaxBasicModel
  uuid: Scalars["ID"]
}

export type ItemWithAttachmentModel = {
  __typename?: "ItemWithAttachmentModel"
  attachments?: Maybe<Array<AttachmentModel>>
  description?: Maybe<Scalars["String"]>
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
  variants: Array<VariantWithAttachmentModel>
}

export type ItemWithAttachmentsModel = {
  __typename?: "ItemWithAttachmentsModel"
  attachments?: Maybe<Array<AttachmentModel>>
  description?: Maybe<Scalars["String"]>
  isPublished?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type ItemWithLocationModel = {
  __typename?: "ItemWithLocationModel"
  item: ItemBasicModel
  location: LocationBasicModel
}

export type ItemWithVariantsModel = {
  __typename?: "ItemWithVariantsModel"
  description?: Maybe<Scalars["String"]>
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
  variants?: Maybe<Array<VariantWithExtraPriceModel>>
}

/** The actions of the kds devices */
export enum KdsDeviceActionsEnum {
  REFETCH_ORDERS = "REFETCH_ORDERS",
}

export type KdsAuthModel = {
  __typename?: "KdsAuthModel"
  uuid: Scalars["ID"]
}

export type KdsBasicDiscount = {
  __typename?: "KdsBasicDiscount"
  amountApplied: Scalars["Float"]
  code?: Maybe<Scalars["String"]>
}

export type KdsCancelOrderInput = {
  comment: Scalars["String"]
  employeeCode: Scalars["String"]
  order: RuuidInput
}

export type KdsCancelledOrder = {
  __typename?: "KdsCancelledOrder"
  isPaidLater: Scalars["Boolean"]
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  refund?: Maybe<RefundBasic>
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type KdsCashPaymentIntentBasic = {
  __typename?: "KdsCashPaymentIntentBasic"
  amount: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsComboBasicModel = {
  __typename?: "KdsComboBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsComboDetails = {
  __typename?: "KdsComboDetails"
  comboIdentifier?: Maybe<Scalars["String"]>
  items: Array<KdsItemDetails>
  name: Scalars["String"]
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsComboModel = {
  __typename?: "KdsComboModel"
  comboIdentifier?: Maybe<Scalars["String"]>
  items: Array<KdsItemModel>
  name: Scalars["String"]
  status: ItemStatusEnum
  uuid: Scalars["ID"]
}

export type KdsConfirmOrderArrivalInput = {
  order: RuuidInput
}

export type KdsCustomer = {
  __typename?: "KdsCustomer"
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type KdsCustomerDetails = {
  __typename?: "KdsCustomerDetails"
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type KdsDeviceModel = {
  __typename?: "KdsDeviceModel"
  deviceKey: Scalars["String"]
  functionType: DeviceFunctionEnum
  isActive: Scalars["Boolean"]
  lastChecking?: Maybe<Scalars["DateTime"]>
  location: LocationBasicModel
  restaurant: KdsRestaurantAttachmentModel
  status: DeviceStatusEnum
  uuid: Scalars["ID"]
  validatedAt?: Maybe<Scalars["DateTime"]>
}

export type KdsDeviceWithAccessTokenModel = {
  __typename?: "KdsDeviceWithAccessTokenModel"
  accessToken: Scalars["String"]
  device: KdsDeviceModel
  expiresAt: Scalars["Timestamp"]
  refreshExpiresAt: Scalars["Timestamp"]
  refreshToken: Scalars["String"]
}

export type KdsExternalOrderStatus = {
  __typename?: "KdsExternalOrderStatus"
  externalOrderNumber?: Maybe<Scalars["String"]>
  location: Ruuid
  notes?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type KdsIngredientBasicModel = {
  __typename?: "KdsIngredientBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsInvoice = {
  __typename?: "KdsInvoice"
  deliveryFee: Scalars["Float"]
  discountDetails: Array<KdsBasicDiscount>
  discountsTotal: Scalars["Float"]
  serviceFee: Scalars["Float"]
  storeCash: Scalars["Float"]
  subTotal: Scalars["Float"]
  tip: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsItemBasicModel = {
  __typename?: "KdsItemBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsItemDetails = {
  __typename?: "KdsItemDetails"
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  orderDetailUUID: Scalars["ID"]
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
  variant: KdsVariantDetails
}

export type KdsItemModel = {
  __typename?: "KdsItemModel"
  name: Scalars["String"]
  notes?: Maybe<Scalars["String"]>
  orderDetailUUID: Scalars["ID"]
  quantity: Scalars["Int"]
  status: ItemStatusEnum
  uuid: Scalars["ID"]
  variant: KdsVariantModel
}

export type KdsOrder = {
  __typename?: "KdsOrder"
  carColor?: Maybe<Scalars["String"]>
  cashPaymentIntent?: Maybe<KdsCashPaymentIntentBasic>
  customer: KdsCustomer
  customerArrivedAt?: Maybe<Scalars["DateTime"]>
  deliveryDate?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  isScheduled: Scalars["Boolean"]
  locationTable?: Maybe<KdsTableBasic>
  notes?: Maybe<Scalars["String"]>
  orderDetails: Array<KdsOrderDetailListModel>
  orderIdentifier?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  orderTime: Scalars["DateTime"]
  parkingSpot?: Maybe<Scalars["String"]>
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  pendingOrderTime?: Maybe<Scalars["DateTime"]>
  status: OrderStatusEnum
  tableTentNumber?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type KdsOrderCompleted = {
  __typename?: "KdsOrderCompleted"
  completedAt: Scalars["DateTime"]
  orderIdentifier?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  total: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsOrderCompletedDetails = {
  __typename?: "KdsOrderCompletedDetails"
  createdAt: Scalars["DateTime"]
  customer: KdsCustomerDetails
  deliveryAddress?: Maybe<Scalars["String"]>
  deliveryDate?: Maybe<Scalars["DateTime"]>
  expiresAt: Scalars["DateTime"]
  invoice?: Maybe<KdsInvoice>
  isAvailableCancel: Scalars["Boolean"]
  isAvailableRefund: Scalars["Boolean"]
  isPaidLater: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  location?: Maybe<KdsTableBasic>
  notes?: Maybe<Scalars["String"]>
  orderDetails: Array<KdsOrderDetailListDetails>
  orderIdentifier?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  pendingOrderTime?: Maybe<Scalars["DateTime"]>
  pointsRewarded: Scalars["Int"]
  refundInformation?: Maybe<RefundBasic>
  reportMessage?: Maybe<Scalars["String"]>
  status: OrderStatusEnum
  storeCashRewarded: Scalars["Float"]
  subTotal: Scalars["Float"]
  tableTentNumber?: Maybe<Scalars["Float"]>
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsOrderDetailListDetails = {
  __typename?: "KdsOrderDetailListDetails"
  combo?: Maybe<KdsComboDetails>
  item?: Maybe<KdsItemDetails>
}

export type KdsOrderDetailListModel = {
  __typename?: "KdsOrderDetailListModel"
  combo?: Maybe<KdsComboModel>
  item?: Maybe<KdsItemModel>
}

export type KdsOrderDetailModel = {
  __typename?: "KdsOrderDetailModel"
  combo?: Maybe<KdsComboBasicModel>
  comboIdentifier?: Maybe<Scalars["String"]>
  item: KdsItemBasicModel
  notes?: Maybe<Scalars["String"]>
  orderVariantModifiers: Array<KdsOrderVariantModifierModel>
  quantity: Scalars["Int"]
  status: ItemStatusEnum
  uuid: Scalars["ID"]
  variant: KdsVariantBasicModel
}

export type KdsOrderNotificationListModel = {
  __typename?: "KdsOrderNotificationListModel"
  carColor?: Maybe<Scalars["String"]>
  customerArrivedAt: Scalars["DateTime"]
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  parkingSpot?: Maybe<Scalars["String"]>
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type KdsOrderStatus = {
  __typename?: "KdsOrderStatus"
  location: Ruuid
  notes?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  status: OrderStatusEnum
  uuid: Scalars["ID"]
}

export type KdsOrderSuccessfullyPaid = {
  __typename?: "KdsOrderSuccessfullyPaid"
  deliveryDate?: Maybe<Scalars["DateTime"]>
  isScheduled: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type KdsOrderUpdated = {
  __typename?: "KdsOrderUpdated"
  carColor?: Maybe<Scalars["String"]>
  cashPaymentIntent?: Maybe<KdsCashPaymentIntentBasic>
  customer: KdsCustomer
  customerArrivedAt?: Maybe<Scalars["DateTime"]>
  deliveryDate?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  location: Ruuid
  locationTable?: Maybe<KdsTableBasic>
  notes?: Maybe<Scalars["String"]>
  orderDetails: Array<KdsOrderDetailListModel>
  orderIdentifier?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  orderTime: Scalars["DateTime"]
  parkingSpot?: Maybe<Scalars["String"]>
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  pendingOrderTime?: Maybe<Scalars["DateTime"]>
  status: OrderStatusEnum
  tableTentNumber?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type KdsOrderVariantModifierDetails = {
  __typename?: "KdsOrderVariantModifierDetails"
  ingredient: KdsIngredientBasicModel
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type KdsOrderVariantModifierModel = {
  __typename?: "KdsOrderVariantModifierModel"
  ingredient: KdsIngredientBasicModel
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type KdsRestaurantAttachmentModel = {
  __typename?: "KdsRestaurantAttachmentModel"
  attachments?: Maybe<Array<AttachmentModel>>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsSendReadyNotificationInput = {
  order: RuuidInput
}

export type KdsTableBasic = {
  __typename?: "KdsTableBasic"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsUpdateComboStatusInput = {
  comboIdentifier: Scalars["String"]
  status: ItemStatusEnum
}

export type KdsUpdateItemStatusInput = {
  status: ItemStatusEnum
  uuid: Scalars["ID"]
}

export type KdsVariantBasicModel = {
  __typename?: "KdsVariantBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type KdsVariantDetails = {
  __typename?: "KdsVariantDetails"
  name: Scalars["String"]
  orderVariantModifiers: Array<KdsOrderVariantModifierDetails>
  uuid: Scalars["ID"]
}

export type KdsVariantModel = {
  __typename?: "KdsVariantModel"
  name: Scalars["String"]
  orderVariantModifiers: Array<KdsOrderVariantModifierModel>
  uuid: Scalars["ID"]
}

export type LaborCostReport = {
  __typename?: "LaborCostReport"
  employees?: Maybe<Array<TimeEntriesReport>>
  payroll: Scalars["Float"]
  tips: Scalars["Float"]
}

export type LaborCostSummary = {
  __typename?: "LaborCostSummary"
  currentPeriod?: Maybe<LaborCostReport>
  previousPeriod?: Maybe<LaborCostReport>
}

export type LastTimeEntry = {
  __typename?: "LastTimeEntry"
  startDate: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type LocationBasicModel = {
  __typename?: "LocationBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type LocationCateringRule = {
  __typename?: "LocationCateringRule"
  cancelPolicy: Array<CateringCancelPolicy>
  employees: Array<EmployeeBasic>
  orderMethodAcceptance: OrderMethodAcceptanceEnum
  orderScheduling: Scalars["Int"]
  prePayment: CateringPrePayment
  services: Array<CateringServices>
  uuid: Scalars["ID"]
}

export type LocationEmployeeTimeEntry = {
  __typename?: "LocationEmployeeTimeEntry"
  attachment?: Maybe<AttachmentModel>
  deactivatedAt?: Maybe<Scalars["DateTime"]>
  firstName: Scalars["String"]
  lastName: Scalars["String"]
  payRate?: Maybe<Scalars["Float"]>
  role: RoleInfo
  timeEntries: Array<BasicTimeEntry>
  totalAmount: Scalars["Float"]
  totalDurationInSeconds: Scalars["Int"]
  totalTips: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type LocationModel = {
  __typename?: "LocationModel"
  acceptCash: Scalars["Boolean"]
  address: Scalars["String"]
  attachment?: Maybe<AttachmentModel>
  bankingAccount?: Maybe<BankingAccountModel>
  deletedAt?: Maybe<Scalars["DateTime"]>
  estimatedPreparationTime?: Maybe<Scalars["Int"]>
  isActive: Scalars["Boolean"]
  isCateringActive: Scalars["Boolean"]
  latitude?: Maybe<Scalars["String"]>
  longitude?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  orderTypes: Array<OrderMethodEnum>
  phone?: Maybe<Scalars["String"]>
  timezone?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type LocationSnapshotInput = {
  address?: InputMaybe<Scalars["String"]>
  addressLine1?: InputMaybe<Scalars["String"]>
  addressLine2?: InputMaybe<Scalars["String"]>
  attachment?: InputMaybe<UpdateRestaurantAttachmentInput>
  city?: InputMaybe<Scalars["String"]>
  latitude?: InputMaybe<Scalars["String"]>
  longitude?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  orderTypes?: InputMaybe<Array<OrderMethodEnum>>
  phone?: InputMaybe<Scalars["String"]>
  postalCode?: InputMaybe<Scalars["String"]>
  state?: InputMaybe<Scalars["String"]>
  timezone?: InputMaybe<Scalars["String"]>
}

export type LocationSquare = {
  __typename?: "LocationSquare"
  address?: Maybe<SquareAddress>
  id?: Maybe<Scalars["String"]>
  merchantId?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  timezone?: Maybe<Scalars["String"]>
}

export type LocationTableModel = {
  __typename?: "LocationTableModel"
  capacity: Scalars["Int"]
  description?: Maybe<Scalars["String"]>
  isActive: Scalars["Boolean"]
  name: Scalars["String"]
  qrPath: Scalars["String"]
  smoking: Scalars["Boolean"]
  status: StatusTableEnum
  tableIdentifier: Scalars["String"]
  uuid: Scalars["ID"]
}

export type LocationTableZoneModel = {
  __typename?: "LocationTableZoneModel"
  capacity: Scalars["Int"]
  description?: Maybe<Scalars["String"]>
  isActive: Scalars["Boolean"]
  location: BasicLocationModel
  name: Scalars["String"]
  qrPath: Scalars["String"]
  smoking: Scalars["Boolean"]
  status: StatusTableEnum
  tableIdentifier: Scalars["String"]
  tableZone?: Maybe<BasicZoneModel>
  uuid: Scalars["ID"]
}

export type LocationTaxModel = {
  __typename?: "LocationTaxModel"
  location: LocationBasicModel
  tax: TaxBasicModel
  uuid: Scalars["ID"]
}

export type LocationWithBankingAccountModel = {
  __typename?: "LocationWithBankingAccountModel"
  bankingAccount: BankAccountBasicModel
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type LocationWithDeliveryDefaultModel = {
  __typename?: "LocationWithDeliveryDefaultModel"
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type LocationWithDeliveryIntegrationModel = {
  __typename?: "LocationWithDeliveryIntegrationModel"
  integrationName?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type LocationWithOrderTypesModel = {
  __typename?: "LocationWithOrderTypesModel"
  address: Scalars["String"]
  deletedAt?: Maybe<Scalars["DateTime"]>
  isActive: Scalars["Boolean"]
  name: Scalars["String"]
  orderTypes: Array<OrderMethodEnum>
  uuid: Scalars["ID"]
}

export type LocationWithSchedulesModel = {
  __typename?: "LocationWithSchedulesModel"
  acceptCash: Scalars["Boolean"]
  address: Scalars["String"]
  attachment?: Maybe<AttachmentModel>
  bankingAccount?: Maybe<BankingAccountModel>
  deletedAt?: Maybe<Scalars["DateTime"]>
  estimatedPreparationTime?: Maybe<Scalars["Int"]>
  hasDeliveryIntegration: Scalars["Boolean"]
  isActive: Scalars["Boolean"]
  isCateringActive: Scalars["Boolean"]
  latitude?: Maybe<Scalars["String"]>
  longitude?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  orderTypes: Array<OrderMethodEnum>
  phone?: Maybe<Scalars["String"]>
  schedules: Array<ScheduleDetailsModel>
  timezone?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type LoyaltyCustomerPointsModel = {
  __typename?: "LoyaltyCustomerPointsModel"
  isLoyaltyProgramActive: Scalars["Boolean"]
  points: Scalars["Int"]
  storeCash: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type LoyaltyProgramModel = {
  __typename?: "LoyaltyProgramModel"
  conversionRate: Scalars["Int"]
  increment: Scalars["Int"]
  isActive: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type LoyaltyProgramWithRestaurantModel = {
  __typename?: "LoyaltyProgramWithRestaurantModel"
  conversionRate: Scalars["Int"]
  increment: Scalars["Int"]
  isActive: Scalars["Boolean"]
  restaurant: RestaurantBasicModel
  uuid: Scalars["ID"]
}

export type MemberWithInviteAndLocationsModel = {
  __typename?: "MemberWithInviteAndLocationsModel"
  email: Scalars["String"]
  firstName: Scalars["String"]
  inviteStatus: AdminInviteStatusEnum
  inviteUUID: Scalars["ID"]
  lastName: Scalars["String"]
  locations: Array<LocationBasicModel>
  restaurantUUID: Scalars["ID"]
  roleName: AdminRoleNameEnum
  userUUID?: Maybe<Scalars["ID"]>
}

export type MenuBasicModel = {
  __typename?: "MenuBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type MenuCounterModel = {
  __typename?: "MenuCounterModel"
  comboCount: Scalars["Int"]
  itemCount: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type MenuItemBasicModel = {
  __typename?: "MenuItemBasicModel"
  menu?: Maybe<MenuBasicModel>
}

export type MenuItemsBasicModel = {
  __typename?: "MenuItemsBasicModel"
  menuItems: Array<MenuItemBasicModel>
  name: Scalars["String"]
  uuid: Scalars["String"]
}

export type MenuItemsModel = {
  __typename?: "MenuItemsModel"
  menu?: Maybe<MenuBasicModel>
}

export type MenuList = {
  __typename?: "MenuList"
  applyToAllLocations?: Maybe<Scalars["Boolean"]>
  combosCount: Scalars["Int"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  itemsCount: Scalars["Int"]
  menuType: MenuTypeEnum
  name: Scalars["String"]
  status: MenuStatusEnum
  uuid: Scalars["ID"]
}

/** The status on a menu */
export enum MenuStatusEnum {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

/** The supported menu types */
export enum MenuTypeEnum {
  BEVERAGE = "BEVERAGE",
  CATERING = "CATERING",
  FOOD = "FOOD",
  RETAIL = "RETAIL",
}

export type Message = {
  __typename?: "Message"
  message: Scalars["String"]
}

export type MinimalRestaurantModel = {
  __typename?: "MinimalRestaurantModel"
  locationsQuantity?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  priceCategory?: Maybe<PriceCategoriesEnum>
  urlIdentifier: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ModifierGroupBasicModel = {
  __typename?: "ModifierGroupBasicModel"
  deletedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  modifiers: Array<ModifierModel>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ModifierGroupModel = {
  __typename?: "ModifierGroupModel"
  description?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type ModifierInput = {
  ingredientUUID: Scalars["ID"]
  modifierUUID?: InputMaybe<Scalars["ID"]>
}

export type ModifierModel = {
  __typename?: "ModifierModel"
  ingredient: IngredientBasicWithAttachmentModel
  itemOrder: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type Mutation = {
  __typename?: "Mutation"
  KdsSendReadyNotification: KdsOrderStatus
  acceptCateringOrder: AcceptedCateringOrder
  addComboItems: Array<ComboItemWithVariantsModel>
  addCommentToOrder: OrderActionModel
  addItemToOrder: OrderItemTaxesModel
  addLocationIntegration: ExternalIntegration
  addModifierGroup: ModifierGroupBasicModel
  addModifierGroupToItem: Array<ModifierGroupBasicModel>
  addModifierGroupToVariant: VariantModifierGroupWithVariantModifierModel
  addOrUpdateVariantModifierToItem: OrderWithVariantModifiersModel
  addQrConfigToRestaurant: RestaurantQrConfigModel
  addRelationBankingAccount: LocationWithBankingAccountModel
  cancelOrder: CancelledOrder
  createAddress: AddressModel
  createAttachmentIngredient: AttachmentModel
  createCheckoutSession: SubscriptionPaymentIntentModel
  createCombo: ComboModel
  createComboAttachment: AttachmentModel
  createComboWithComboItemGroup: ComboWithComboItemGroupsModel
  createComment: OrderComment
  createCustomer: CustomerBasicModel
  createCustomerPortalSubscriptionSession: CustomerPortalSubscriptionSession
  createDeliveryIntegration: DeliveryIntegrationWithLocationsModel
  createDeliveryPrice: DeliveryPrice
  createDevice: DeviceModel
  createDiscount: Discount
  createEmployee: Employee
  createExternalAccount: BankingAccountModel
  createIngredient: RCreatedIngredient
  createItemAttachment: AttachmentModel
  createItemTaxLocation: TaxItemLocationModel
  createLocation: LocationModel
  createLocationCateringRules: LocationCateringRule
  createLocationTable: LocationTableModel
  createLocationTax: TaxLocationModel
  createLocationtAttachment: AttachmentModel
  createManyDeliveryLocations: Array<DeliveryLocation>
  createMenuItems: MenuItemsBasicModel
  createOnboardingLink: StripeAccountModel
  createOrUpdateHoliday: Holiday
  createOrUpdateLoyaltyProgram: LoyaltyProgramWithRestaurantModel
  createOrUpdateRestaurantSnapshot: DraftRestaurant
  createOrUpdateWhiteLabelConfigToRestaurant: RestaurantWhiteLabelConfigModel
  createOrder: OrderTaxesModel
  createOrderThrottling: OrderThrottling
  createRestaurant: RestaurantModel
  createRestaurantAttachment: RestaurantAttachmentModel
  createRestaurantMember: MemberWithInviteAndLocationsModel
  createScheduleForLocation: ScheduleBasicWithDetailsModel
  createScheduleLocation: ScheduleModel
  createScheduleRestaurant: ScheduleModel
  createTableZone: TableZoneModel
  createTax: TaxModel
  createTempComboAttachment: AttachmentModel
  createTempItemAttachment: AttachmentModel
  createTempRestaurantAttachment: Array<TempAttachmentModel>
  createTempVariantAttachment: AttachmentModel
  createVariant: VariantModel
  createVariantModifierGroup: VariantModifierGroupModel
  deactivateDiscount: Ruuid
  declineCateringOrder: Message
  deleteCombo: Ruuid
  deleteComboAttachment: ComboWithAttachmentsModel
  deleteComboItem: ComboItemBasicModel
  deleteComboItemGroup: ComboItemGroupWithComboItemModel
  deleteDeliveryIntegration: DeliveryIntegrationWithLocationsModel
  deleteDeliveryLocation: BasicDeliveryLocation
  deleteDeliveryPrice: DeliveryPrice
  deleteDiscount: Ruuid
  deleteHoliday: Holiday
  deleteIngredient: RBasicEntityData
  deleteItem: Ruuid
  deleteItemAttachment: ItemModel
  deleteItemFromOrder: OrderItemTaxesModel
  deleteItemTaxesByLocations: Array<ItemTaxWithLocationModel>
  deleteLocation: LocationModel
  deleteLocationOrderIntegration: Array<DeletedLocationIntegration>
  deleteLocationTax: Array<LocationTaxModel>
  deleteMenu: Ruuid
  deleteModifier: ModifierGroupBasicModel
  deleteModifierGroup: RDeletedModifierGroup
  deleteModifierGroupToVariant: BasicVariantModifierGroupModel
  deleteRestaurant: MinimalRestaurantModel
  deleteRestaurantAttachment: DeletedRestaurantAttachmentModel
  deleteRestaurantMember: MemberWithInviteAndLocationsModel
  deleteTax: TaxBasicModel
  deleteTimeEntry: Scalars["String"]
  deleteVariant: VariantModel
  deleteVariantModifier: VariantModifierModel
  disableBankingAccount: BankingAccountModel
  disableEmployee: EmployeeBasic
  disableItem: ItemModel
  disableLocationTable: LocationTableModel
  disableScheduleLocation: ScheduleModel
  disableScheduleRestaurant: ScheduleModel
  disableTableZone: TableZoneLocationModel
  disableVariantModifierGroup: BasicVariantModifierGroupModel
  duplicateCombo: DuplicatedComboModel
  duplicateComboItemGroup: ComboItemGroupDetailModel
  duplicateItem: DuplicatedItem
  duplicateModifierGroup: ModifierGroupBasicModel
  duplicateVariant: VariantOptionModel
  enableEmployee: EmployeeBasic
  generateDeviceCode: DeviceAuthModel
  hideOrShowMenu: RHidedOrShowedMenu
  kdsCancelOrder: KdsCancelledOrder
  kdsClearOrders: Array<Ruuid>
  kdsConfirmOrderArrival: Ruuid
  kdsLogOut: KdsAuthModel
  kdsRefreshToken: KdsDeviceWithAccessTokenModel
  kdsSaveSettings: Devices
  kdsUpdateComboStatus: KdsOrderDetailModel
  kdsUpdateExternalOrderStatus: KdsExternalOrderStatus
  kdsUpdateItemStatus: KdsOrderDetailModel
  kdsUpdateOrderStatus: KdsOrderStatus
  kdsValidateCode: KdsDeviceWithAccessTokenModel
  kioskCreateConnectionToken: RKioskConnectionToken
  publishCombo: RPublishedCombo
  publishDraftCombo: RPublishedCombo
  publishDraftItem: RBasicEntityData
  publishDraftMenu: RPublishedDraftMenu
  publishDraftModifierGroup: RBasicEntityData
  publishItem: RPublishedItem
  publishMenu: RPublishedMenu
  publishModifierGroup: RPublishedModifierGroup
  publishRestaurant: RestaurantBasicModel
  refreshOnboarding: StripeAccountUrlModel
  removeRelationBankingAccount: RBasicEntityData
  resendInviteToMember: InviteBasicModel
  resetComboPrice: ResetComboPriceModel
  saveCombo: RSavedCombo
  saveCorporateComboByLocation: RSavedCorporateCombo
  saveCorporateItemByLocation: RSavedCorporateItem
  saveCorporateModifierGroupByLocation: RSavedCorporateModifierGroup
  saveItem: RSavedItem
  saveMenu: RDraftMenu
  saveModifierGroup: RSavedModifierGroup
  sendRefundPayment: RefundBasic
  setComboItemAsDefault: ComboItemGroupDetailModel
  setOnboardingCompleted: AdminOnboardingModel
  setupApiKeys: Integration
  setupIntegration: Integration
  sortComboItemGroup: SortModel
  sortMenu: SortModel
  sortMenuCombo: SortModel
  sortMenuItem: SortModel
  sortModifierGroupToVariant: SortModel
  sortVariant: SortModel
  unpublishCombo: RBasicEntityData
  unpublishItem: RBasicEntityData
  unpublishMenu: RUnpublishMenu
  unpublishModifierGroup: RBasicEntityData
  updateAddress: AddressModel
  updateAttachmentIngredient: AttachmentModel
  updateBankingAccount: BankingAccountModel
  updateCombo: UpdatedComboModel
  updateComboAttachment: AttachmentModel
  updateComboItemGroup: ComboItemGroupBasicModel
  updateCustomer: UserFormattedPhoneModel
  updateDeliveryIntegration: DeliveryIntegrationWithLocationsModel
  updateDeliveryIntegrationInOrder: DeliveryIntegrationInOrder
  updateDeliveryLocation: DeliveryLocation
  updateDeliveryPrice: DeliveryPrice
  updateDevice: DeviceModel
  updateDiscount: Discount
  updateEmployee: Employee
  updateExternalAccount: BankingAccountModel
  updateFutureLocationsInTax: TaxModel
  updateIngredient: RBasicEntityData
  updateItemAttachment: AttachmentModel
  updateItemInOrder: OrderItemTaxesModel
  updateItemStatus: OrderItemModel
  updateLocation: LocationModel
  updateLocationCateringRules: LocationCateringRule
  updateLocationTable: LocationTableZoneModel
  updateMenuCombos: ComboModel
  updateOpenFlagForSchedules: Array<RScheduleDetail>
  updateOrder: OrderWithActionsModel
  updateOrderThrottling: OrderThrottling
  updateRestaurant: RestaurantModel
  updateRestaurantAttachment: RestaurantAttachmentModel
  updateRestaurantMember: MemberWithInviteAndLocationsModel
  updateScheduleForLocation: Array<ScheduleDetailModel>
  updateScheduleLocation: ScheduleModel
  updateScheduleRestaurant: ScheduleModel
  updateSortingField: SortingFieldMenu
  updateTableZone: TableZoneModel
  updateTax: TaxModel
  updateVariant: VariantModel
  updateVariantModifier: VariantModifierModel
  updateVariantModifierGroup: BasicVariantModifierGroupModel
  upsertAttachmentVariant: AttachmentModel
  upsertOrderMethodSetting: OrderMethodSettings
  upsertTimeEntry: UpsertedTimeEntry
}

export type MutationKdsSendReadyNotificationArgs = {
  input: KdsSendReadyNotificationInput
}

export type MutationAcceptCateringOrderArgs = {
  order: RuuidInput
}

export type MutationAddComboItemsArgs = {
  data: AddComboItemsInput
}

export type MutationAddCommentToOrderArgs = {
  data: AddCommentToOrderInput
}

export type MutationAddItemToOrderArgs = {
  data: AddItemToOrderInput
}

export type MutationAddLocationIntegrationArgs = {
  data: AddLocationIntegrationInput
}

export type MutationAddModifierGroupArgs = {
  data: AddModifierGroupInput
}

export type MutationAddModifierGroupToItemArgs = {
  data: AddModifierGroupToItemInput
}

export type MutationAddModifierGroupToVariantArgs = {
  data: AddModifierGroupToVariantInput
}

export type MutationAddOrUpdateVariantModifierToItemArgs = {
  data: AddOrUpdateVariantModifierToItemInput
}

export type MutationAddQrConfigToRestaurantArgs = {
  data: AddQrConfigToRestaurantInput
}

export type MutationAddRelationBankingAccountArgs = {
  data: AddRelationBankingAccountInput
}

export type MutationCancelOrderArgs = {
  input: CancelOrderInput
}

export type MutationCreateAddressArgs = {
  data: CreateAddressInput
}

export type MutationCreateAttachmentIngredientArgs = {
  data: CreateAttachmentIngredientInput
}

export type MutationCreateCheckoutSessionArgs = {
  data: CreateSubscriptionPaymentIntentInput
}

export type MutationCreateComboArgs = {
  data: CreateComboInput
}

export type MutationCreateComboAttachmentArgs = {
  data: CreateComboAttachmentInput
}

export type MutationCreateComboWithComboItemGroupArgs = {
  data: CreateComboItemGroupInput
}

export type MutationCreateCommentArgs = {
  data: CreateCommentInput
}

export type MutationCreateCustomerArgs = {
  data: CreateCustomerInput
}

export type MutationCreateDeliveryIntegrationArgs = {
  data: AddDeliveryIntegrationInput
}

export type MutationCreateDeliveryPriceArgs = {
  data: CreateDeliveryPriceInput
}

export type MutationCreateDeviceArgs = {
  data: CreateDeviceInput
}

export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput
}

export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput
}

export type MutationCreateExternalAccountArgs = {
  data: CreateExternalAccountInput
}

export type MutationCreateIngredientArgs = {
  input: RCreateIngredientInput
}

export type MutationCreateItemAttachmentArgs = {
  data: CreateItemAttachmentInput
}

export type MutationCreateItemTaxLocationArgs = {
  data: CreateItemTaxLocationInput
}

export type MutationCreateLocationArgs = {
  data: CreateLocationInput
}

export type MutationCreateLocationCateringRulesArgs = {
  input: CreateLocationCateringRulesInput
}

export type MutationCreateLocationTableArgs = {
  data: CreateLocationTableInput
}

export type MutationCreateLocationTaxArgs = {
  data: CreateLocationTaxInput
}

export type MutationCreateLocationtAttachmentArgs = {
  attachment: CreateAttachmentInput
  locationUUID: Scalars["ID"]
}

export type MutationCreateManyDeliveryLocationsArgs = {
  data: CreateDeliveryLocationManyInput
}

export type MutationCreateMenuItemsArgs = {
  data: CreateMenuItemsInput
}

export type MutationCreateOnboardingLinkArgs = {
  data: CreateOnboardingLinkInput
}

export type MutationCreateOrUpdateHolidayArgs = {
  data: CreateOrUpdateHolidayInput
}

export type MutationCreateOrUpdateLoyaltyProgramArgs = {
  data: CreateOrUpdateLoyaltyProgramInput
}

export type MutationCreateOrUpdateRestaurantSnapshotArgs = {
  data: CreateOrUpdateRestaurantSnapshotInput
}

export type MutationCreateOrUpdateWhiteLabelConfigToRestaurantArgs = {
  data: AddWhiteLabelConfigInput
}

export type MutationCreateOrderArgs = {
  data: CreateOrderInput
}

export type MutationCreateOrderThrottlingArgs = {
  data: CreateOrderThrottlingInput
}

export type MutationCreateRestaurantArgs = {
  data: CreateRestaurantInput
}

export type MutationCreateRestaurantAttachmentArgs = {
  data: CreateRestaurantAttachmentInput
  restaurantUUID: Scalars["ID"]
}

export type MutationCreateRestaurantMemberArgs = {
  data: CreateAdminManagerInput
}

export type MutationCreateScheduleForLocationArgs = {
  data: CreateScheduleForLocationInput
}

export type MutationCreateScheduleLocationArgs = {
  data: CreateLocationScheduleInput
}

export type MutationCreateScheduleRestaurantArgs = {
  data: CreateRestaurantScheduleInput
}

export type MutationCreateTableZoneArgs = {
  data: CreateTableZoneInput
}

export type MutationCreateTaxArgs = {
  data: CreateTaxInput
}

export type MutationCreateTempComboAttachmentArgs = {
  input: CreateAttachmentInput
}

export type MutationCreateTempItemAttachmentArgs = {
  input: CreateAttachmentInput
}

export type MutationCreateTempRestaurantAttachmentArgs = {
  input: CreateTempAttachmentInput
  uuid: Scalars["ID"]
}

export type MutationCreateTempVariantAttachmentArgs = {
  input: CreateAttachmentInput
}

export type MutationCreateVariantArgs = {
  data: CreateVariantInput
}

export type MutationCreateVariantModifierGroupArgs = {
  data: CreateVariantModifierGroupInput
}

export type MutationDeactivateDiscountArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeclineCateringOrderArgs = {
  order: RuuidInput
}

export type MutationDeleteComboArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteComboAttachmentArgs = {
  data: DeleteComboAttachmentInput
}

export type MutationDeleteComboItemArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteComboItemGroupArgs = {
  comboItemGroupUUID: Scalars["ID"]
}

export type MutationDeleteDeliveryIntegrationArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteDeliveryLocationArgs = {
  data: DeleteDeliveryLocationInput
}

export type MutationDeleteDeliveryPriceArgs = {
  data: DeleteDeliveryPriceInput
}

export type MutationDeleteDiscountArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteHolidayArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteIngredientArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteItemArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteItemAttachmentArgs = {
  data: DeleteItemAttachmentInput
}

export type MutationDeleteItemFromOrderArgs = {
  data: DeleteItemOrderInput
}

export type MutationDeleteItemTaxesByLocationsArgs = {
  data: DeleteItemTaxesByLocationsInput
}

export type MutationDeleteLocationArgs = {
  locationUUID: Scalars["ID"]
}

export type MutationDeleteLocationOrderIntegrationArgs = {
  data: DeleteLocationIntegrationInput
}

export type MutationDeleteLocationTaxArgs = {
  data: DeleteTaxLocationInput
}

export type MutationDeleteMenuArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteModifierArgs = {
  data: DeleteModifierInput
}

export type MutationDeleteModifierGroupArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteModifierGroupToVariantArgs = {
  data: DeleteModifierGroupToVariantInput
}

export type MutationDeleteRestaurantArgs = {
  data: DisableRestaurantInput
}

export type MutationDeleteRestaurantAttachmentArgs = {
  data: DeleteAttachmentInput
}

export type MutationDeleteRestaurantMemberArgs = {
  data: DeleteMemberInput
}

export type MutationDeleteTaxArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteTimeEntryArgs = {
  input: DeleteTimEntryInput
}

export type MutationDeleteVariantArgs = {
  uuid: Scalars["ID"]
}

export type MutationDeleteVariantModifierArgs = {
  uuid: Scalars["ID"]
}

export type MutationDisableBankingAccountArgs = {
  uuid: Scalars["ID"]
}

export type MutationDisableEmployeeArgs = {
  input: DisableEmployeeInput
}

export type MutationDisableItemArgs = {
  uuid: Scalars["ID"]
}

export type MutationDisableLocationTableArgs = {
  data: DisableLocationTableInput
}

export type MutationDisableScheduleLocationArgs = {
  data: DisableLocationScheduleInput
}

export type MutationDisableScheduleRestaurantArgs = {
  data: DisableRestaurantScheduleInput
}

export type MutationDisableTableZoneArgs = {
  data: DisableTableZoneInput
}

export type MutationDisableVariantModifierGroupArgs = {
  uuid: Scalars["ID"]
}

export type MutationDuplicateComboArgs = {
  data: DuplicateComboInput
}

export type MutationDuplicateComboItemGroupArgs = {
  comboItemGroupUUID: Scalars["ID"]
}

export type MutationDuplicateItemArgs = {
  data: DuplicateItemInput
}

export type MutationDuplicateModifierGroupArgs = {
  modifierGroupUUID: Scalars["ID"]
}

export type MutationDuplicateVariantArgs = {
  variantUUID: Scalars["ID"]
}

export type MutationEnableEmployeeArgs = {
  input: DisableEmployeeInput
}

export type MutationGenerateDeviceCodeArgs = {
  data: GenerateDeviceCodeInput
}

export type MutationHideOrShowMenuArgs = {
  uuid: Scalars["ID"]
}

export type MutationKdsCancelOrderArgs = {
  input: KdsCancelOrderInput
}

export type MutationKdsConfirmOrderArrivalArgs = {
  data: KdsConfirmOrderArrivalInput
}

export type MutationKdsSaveSettingsArgs = {
  settings: Scalars["JSON"]
}

export type MutationKdsUpdateComboStatusArgs = {
  data: KdsUpdateComboStatusInput
}

export type MutationKdsUpdateExternalOrderStatusArgs = {
  data: UpdateExternalOrderStatusInput
}

export type MutationKdsUpdateItemStatusArgs = {
  data: KdsUpdateItemStatusInput
}

export type MutationKdsUpdateOrderStatusArgs = {
  data: UpdateOrderStatusInput
}

export type MutationKdsValidateCodeArgs = {
  code: Scalars["String"]
}

export type MutationKioskCreateConnectionTokenArgs = {
  locationUUID: Scalars["ID"]
}

export type MutationPublishComboArgs = {
  input: RPublishComboInput
}

export type MutationPublishDraftComboArgs = {
  uuid: Scalars["ID"]
}

export type MutationPublishDraftItemArgs = {
  uuid: Scalars["ID"]
}

export type MutationPublishDraftMenuArgs = {
  uuid: Scalars["ID"]
}

export type MutationPublishDraftModifierGroupArgs = {
  uuid: Scalars["ID"]
}

export type MutationPublishItemArgs = {
  input: RPublishItemInput
}

export type MutationPublishMenuArgs = {
  input: RPublishMenuInput
}

export type MutationPublishModifierGroupArgs = {
  input: RPublishModifierGroupInput
}

export type MutationPublishRestaurantArgs = {
  uuid: Scalars["ID"]
}

export type MutationRefreshOnboardingArgs = {
  uuid: Scalars["ID"]
}

export type MutationResendInviteToMemberArgs = {
  inviteUUID: Scalars["ID"]
}

export type MutationResetComboPriceArgs = {
  uuid: Scalars["ID"]
}

export type MutationSaveComboArgs = {
  input: RSaveComboInput
}

export type MutationSaveCorporateComboByLocationArgs = {
  input: RSaveCorporateComboInput
}

export type MutationSaveCorporateItemByLocationArgs = {
  input: RSaveCorporateItemInput
}

export type MutationSaveCorporateModifierGroupByLocationArgs = {
  input: RSaveCorporateModifierGroupInput
}

export type MutationSaveItemArgs = {
  input: RSaveItemInput
}

export type MutationSaveMenuArgs = {
  input: RSaveMenuInput
}

export type MutationSaveModifierGroupArgs = {
  input: RSaveModifierGroupInput
}

export type MutationSendRefundPaymentArgs = {
  data: CreateRefundInput
}

export type MutationSetComboItemAsDefaultArgs = {
  comboItemUUID: Scalars["ID"]
}

export type MutationSetOnboardingCompletedArgs = {
  uuid: Scalars["ID"]
}

export type MutationSetupApiKeysArgs = {
  data: SaveIntegrationApiKeysInput
}

export type MutationSetupIntegrationArgs = {
  data: SetupIntegrationInput
}

export type MutationSortComboItemGroupArgs = {
  data: SortInput
}

export type MutationSortMenuArgs = {
  data: SortInput
}

export type MutationSortMenuComboArgs = {
  data: SortMenuComboInput
}

export type MutationSortMenuItemArgs = {
  data: SortMenuItemInput
}

export type MutationSortModifierGroupToVariantArgs = {
  data: SortModifierGroupToVariantInput
}

export type MutationSortVariantArgs = {
  data: SortInput
}

export type MutationUnpublishComboArgs = {
  uuid: Scalars["ID"]
}

export type MutationUnpublishItemArgs = {
  uuid: Scalars["ID"]
}

export type MutationUnpublishMenuArgs = {
  uuid: Scalars["ID"]
}

export type MutationUnpublishModifierGroupArgs = {
  uuid: Scalars["ID"]
}

export type MutationUpdateAddressArgs = {
  data: UpdateAddressInput
}

export type MutationUpdateAttachmentIngredientArgs = {
  data: UpdateAttachmentInput
}

export type MutationUpdateBankingAccountArgs = {
  data: UpdateBankingAccountInput
}

export type MutationUpdateComboArgs = {
  data: UpdateComboInput
}

export type MutationUpdateComboAttachmentArgs = {
  data: UpdateComboAttachmentInput
}

export type MutationUpdateComboItemGroupArgs = {
  data: UpdateComboItemGroupInput
}

export type MutationUpdateCustomerArgs = {
  data: UpdateCustomerInput
}

export type MutationUpdateDeliveryIntegrationArgs = {
  data: UpdateDeliveryIntegrationInput
}

export type MutationUpdateDeliveryIntegrationInOrderArgs = {
  data: UpdateDeliveryIntegrationInOrderInput
}

export type MutationUpdateDeliveryLocationArgs = {
  data: UpdateDeliveryLocationInput
}

export type MutationUpdateDeliveryPriceArgs = {
  data: UpdateDeliveryPriceInput
}

export type MutationUpdateDeviceArgs = {
  data: UpdateDeviceInput
}

export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput
}

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput
}

export type MutationUpdateExternalAccountArgs = {
  data: CreateExternalAccountInput
}

export type MutationUpdateFutureLocationsInTaxArgs = {
  data: UpdateFutureLocationsInTaxInput
}

export type MutationUpdateIngredientArgs = {
  input: RUpdateIngredientInput
}

export type MutationUpdateItemAttachmentArgs = {
  data: UpdateItemAttachmentInput
}

export type MutationUpdateItemInOrderArgs = {
  data: UpdateItemInOrderInput
}

export type MutationUpdateItemStatusArgs = {
  data: UpdateItemStatusInput
}

export type MutationUpdateLocationArgs = {
  data: UpdateLocationInput
}

export type MutationUpdateLocationCateringRulesArgs = {
  input: UpdateLocationCateringRulesInput
}

export type MutationUpdateLocationTableArgs = {
  data: UpdateLocationTableInput
}

export type MutationUpdateMenuCombosArgs = {
  data: UpdateMenuCombosInput
}

export type MutationUpdateOpenFlagForSchedulesArgs = {
  data: RUpdateOpenFlagInput
}

export type MutationUpdateOrderArgs = {
  data: UpdateOrderInput
}

export type MutationUpdateOrderThrottlingArgs = {
  data: UpdateOrderThrottlingInput
}

export type MutationUpdateRestaurantArgs = {
  data: UpdateRestaurantInput
}

export type MutationUpdateRestaurantAttachmentArgs = {
  data: UpdateRestaurantAttachmentInput
  restaurantUUID: Scalars["ID"]
}

export type MutationUpdateRestaurantMemberArgs = {
  data: UpdateMemberInput
}

export type MutationUpdateScheduleForLocationArgs = {
  data: UpdateLocationScheduleInput
}

export type MutationUpdateScheduleLocationArgs = {
  data: UpdateLocationScheduleInput
}

export type MutationUpdateScheduleRestaurantArgs = {
  data: UpdateRestaurantScheduleInput
}

export type MutationUpdateSortingFieldArgs = {
  input: SortFieldInput
}

export type MutationUpdateTableZoneArgs = {
  data: UpdateTableZoneInput
}

export type MutationUpdateTaxArgs = {
  data: UpdateTaxInput
}

export type MutationUpdateVariantArgs = {
  data: UpdateVariantInput
}

export type MutationUpdateVariantModifierArgs = {
  data: UpdateVariantModifierInput
}

export type MutationUpdateVariantModifierGroupArgs = {
  data: UpdateVariantModifierGroupInput
}

export type MutationUpsertAttachmentVariantArgs = {
  data: UpsertAttachmentVariantInput
}

export type MutationUpsertOrderMethodSettingArgs = {
  data: UpsertOrderMethodSettingInput
}

export type MutationUpsertTimeEntryArgs = {
  input: UpsertTimEntryInput
}

export type NearByLocationModel = {
  __typename?: "NearByLocationModel"
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  name: Scalars["String"]
  restaurantName: Scalars["String"]
  uuid: Scalars["String"]
}

export type NetSales = {
  __typename?: "NetSales"
  amount: Scalars["Float"]
  discounts: Scalars["Float"]
  grossSales: GrossSales
  storeCashes: Scalars["Float"]
}

export type NetTotal = {
  __typename?: "NetTotal"
  amount: Scalars["Float"]
  fees: Scalars["Float"]
  totalCollects: Array<TotalCollects>
}

export type NextAvailableTime = {
  __typename?: "NextAvailableTime"
  estimatedDeliveryTime: Scalars["Int"]
  nextAvailableTime: Scalars["DateTime"]
}

export type NotificationSettings = {
  __typename?: "NotificationSettings"
  orderReadyAdditionalText?: Maybe<Scalars["String"]>
  orderVerificationAdditionalText?: Maybe<Scalars["String"]>
  sendOrderReadyNotification: Scalars["Boolean"]
  sendOrderVerificationNotification: Scalars["Boolean"]
}

export type NotificationSettingsInput = {
  orderReadyAdditionalText?: InputMaybe<Scalars["String"]>
  orderVerificationAdditionalText?: InputMaybe<Scalars["String"]>
  sendOrderReadyNotification: Scalars["Boolean"]
  sendOrderVerificationNotification: Scalars["Boolean"]
}

export type Option = {
  __typename?: "Option"
  itemOrder: Scalars["Int"]
  label: Scalars["String"]
  uuid: Scalars["ID"]
}

export type OptionGroupItemOption = {
  __typename?: "OptionGroupItemOption"
  itemOrder: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type OptionGroupModel = {
  __typename?: "OptionGroupModel"
  description?: Maybe<Scalars["String"]>
  itemOptions: Array<OptionGroupItemOption>
  options?: Maybe<Array<Option>>
  title: Scalars["String"]
  uuid: Scalars["ID"]
}

export type OrderActionModel = {
  __typename?: "OrderActionModel"
  actionAt: Scalars["DateTime"]
  actionType: OrderActionTypeEnum
  admin?: Maybe<AdminBasicModel>
  comment?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

/** The order action type */
export enum OrderActionTypeEnum {
  CANCELLED = "CANCELLED",
  CANCELLED_PAYMENT_INTENT = "CANCELLED_PAYMENT_INTENT",
  CHARGE_EXPIRED = "CHARGE_EXPIRED",
  CHECK_OUT = "CHECK_OUT",
  CLOSED = "CLOSED",
  COMMENT = "COMMENT",
  COMPLETED = "COMPLETED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  ESTIMATED_TIME_ARRIVAL = "ESTIMATED_TIME_ARRIVAL",
  IN_REVISION = "IN_REVISION",
  OPEN = "OPEN",
  ORDER_REASSIGNED = "ORDER_REASSIGNED",
  PARTIAL_PAID = "PARTIAL_PAID",
  PAYMENT_AWAITING = "PAYMENT_AWAITING",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED",
  PENDING = "PENDING",
  PENDING_CAPTURE = "PENDING_CAPTURE",
  PENDING_REASSIGN = "PENDING_REASSIGN",
  PICKED_UP = "PICKED_UP",
  PLACED = "PLACED",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUNDED = "REFUNDED",
}

export type OrderComment = {
  __typename?: "OrderComment"
  admin?: Maybe<Ruuid>
  content: Scalars["String"]
  createdAt: Scalars["DateTime"]
  employee?: Maybe<Ruuid>
  uuid: Scalars["ID"]
}

export type OrderDeposit = {
  __typename?: "OrderDeposit"
  amount: Scalars["Float"]
  createdAt: Scalars["DateTime"]
  type: PaymentMethodTypeEnum
  uuid: Scalars["ID"]
}

export type OrderDetailInformationModel = {
  __typename?: "OrderDetailInformationModel"
  combo?: Maybe<ComboDetailModel>
  item?: Maybe<ItemDetailModel>
}

export type OrderFilterModel = {
  __typename?: "OrderFilterModel"
  customers: Array<CustomerWithUserBasicModel>
  locations: Array<LocationBasicModel>
  orderMethods: Array<OrderMethodEnum>
  orderNumber?: Maybe<Scalars["String"]>
  paymentMethodTypes: Array<PaymentMethodTypeEnum>
  status: Array<OrderStatusEnum>
}

export type OrderInformationModel = {
  __typename?: "OrderInformationModel"
  catering?: Maybe<CateringOrderInformation>
  createdAt: Scalars["DateTime"]
  customer: CustomerWithUserBasicModel
  deliveryDate?: Maybe<Scalars["DateTime"]>
  deliveryIntegration?: Maybe<DeliveryIntegrationBasicModel>
  expiresAt?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  location: LocationBasicModel
  locationTable?: Maybe<TableBasicModel>
  notes?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  status: OrderStatusEnum
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type OrderInformationSummary = {
  __typename?: "OrderInformationSummary"
  catering?: Maybe<CateringOrder>
  /** this field will replace notes on regular orders */
  comments: Array<OrderComment>
  createdAt: Scalars["DateTime"]
  customer: CustomerBasicModel
  deliveryDate?: Maybe<Scalars["DateTime"]>
  deliveryIntegration?: Maybe<DeliveryIntegrationBasicModel>
  deposits: Array<OrderDeposit>
  expiresAt: Scalars["DateTime"]
  invoice?: Maybe<InvoiceBasicModel>
  isAvailableCancel: Scalars["Boolean"]
  isAvailableRefund: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  location: LocationWithDeliveryIntegrationModel
  notes?: Maybe<Scalars["String"]>
  orderDetails: Array<OrderDetailInformationModel>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  pointsRewarded: Scalars["Int"]
  refundInformation?: Maybe<RefundBasic>
  reportMessage?: Maybe<Scalars["String"]>
  status: OrderStatusEnum
  storeCashRewarded: Scalars["Float"]
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type OrderItemModel = {
  __typename?: "OrderItemModel"
  item: ItemBasicModel
  notes?: Maybe<Scalars["String"]>
  quantity?: Maybe<Scalars["Int"]>
  status: ItemStatusEnum
  unitPrice?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
  variant: VariantBasicModel
}

export type OrderItemTaxesModel = {
  __typename?: "OrderItemTaxesModel"
  item: ItemBasicModel
  itemTaxes: Array<TaxByItemDetailModel>
  notes?: Maybe<Scalars["String"]>
  orderTaxes: Array<TaxBasicModel>
  quantity?: Maybe<Scalars["Int"]>
  status: ItemStatusEnum
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  unitPrice?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
  variant: VariantBasicModel
}

/** The order method acceptance on a catering */
export enum OrderMethodAcceptanceEnum {
  AUTOMATIC = "AUTOMATIC",
  MANUAL = "MANUAL",
}

/** The order method */
export enum OrderMethodEnum {
  CATERING = "CATERING",
  CURBSIDE = "CURBSIDE",
  DELIVERY = "DELIVERY",
  DINE_IN = "DINE_IN",
  DRIVE_THRU = "DRIVE_THRU",
  TAKE_OUT = "TAKE_OUT",
}

export type OrderMethodSettings = {
  __typename?: "OrderMethodSettings"
  createdAt: Scalars["DateTime"]
  location: BasicLocationModel
  notificationSettings?: Maybe<NotificationSettings>
  orderMethod: OrderMethodEnum
  tipSettings?: Maybe<TipSettings>
  uuid: Scalars["ID"]
}

export type OrderModel = {
  __typename?: "OrderModel"
  deliveryDate?: Maybe<Scalars["DateTime"]>
  expiresAt?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  locationTable?: Maybe<TableBasicModel>
  notes?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  status: OrderStatusEnum
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

/** The payment status of an order */
export enum OrderPaymentStatusEnum {
  AWAITING = "AWAITING",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  NONE = "NONE",
  PARTIAL_PAID = "PARTIAL_PAID",
  PENDING_CAPTURE = "PENDING_CAPTURE",
  REFUNDED = "REFUNDED",
  SUCCEEDED = "SUCCEEDED",
}

/** The order status */
export enum OrderStatusEnum {
  CANCELLED = "CANCELLED",
  CHECK_OUT = "CHECK_OUT",
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
  DELIVERY_FAILED = "DELIVERY_FAILED",
  IN_REVISION = "IN_REVISION",
  OPEN = "OPEN",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PENDING = "PENDING",
  PENDING_REASSIGN = "PENDING_REASSIGN",
  PICKED_UP = "PICKED_UP",
  PLACED = "PLACED",
  PREPARING = "PREPARING",
  READY = "READY",
  REFUNDED = "REFUNDED",
}

export type OrderStatusThroughoutInput = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
}

export type OrderSummaryModel = {
  __typename?: "OrderSummaryModel"
  customer: CustomerBasicModel
  orderActions: Array<OrderActionModel>
  orderNumber: Scalars["String"]
  tableTentNumber?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type OrderTaxesModel = {
  __typename?: "OrderTaxesModel"
  deliveryDate?: Maybe<Scalars["DateTime"]>
  expiresAt?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  locationTable?: Maybe<TableBasicModel>
  notes?: Maybe<Scalars["String"]>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  orderTaxes: Array<TaxBasicModel>
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  status: OrderStatusEnum
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type OrderThrottling = {
  __typename?: "OrderThrottling"
  defaultIntervalTime: Scalars["Int"]
  defaultOrdersLimit: Scalars["Int"]
  isActive: Scalars["Boolean"]
  rules: Array<OrderThrottlingRule>
  uuid: Scalars["ID"]
}

export type OrderThrottlingRule = {
  __typename?: "OrderThrottlingRule"
  day: WeekDaysEnum
  endDate: Scalars["DateTime"]
  endMin: Scalars["Int"]
  ordersLimit: Scalars["Int"]
  startDate: Scalars["DateTime"]
  startMin: Scalars["Int"]
  type: OrderThrottlingTypeEnum
  uuid: Scalars["ID"]
}

/** The type of order throttling interval */
export enum OrderThrottlingTypeEnum {
  NORMAL = "NORMAL",
  OVERRIDE = "OVERRIDE",
  SNOOZE = "SNOOZE",
}

export type OrderVariantModifierModel = {
  __typename?: "OrderVariantModifierModel"
  ingredient: IngredientBasicModel
  quantity: Scalars["Int"]
  totalPrice: Scalars["Float"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type OrderWithActionsModel = {
  __typename?: "OrderWithActionsModel"
  deliveryDate?: Maybe<Scalars["DateTime"]>
  expiresAt?: Maybe<Scalars["DateTime"]>
  isPaidLater: Scalars["Boolean"]
  isReported: Scalars["Boolean"]
  locationTable?: Maybe<TableBasicModel>
  notes?: Maybe<Scalars["String"]>
  orderActions: Array<OrderActionModel>
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  paymentMethodType?: Maybe<PaymentMethodTypeEnum>
  paymentStatus: OrderPaymentStatusEnum
  status: OrderStatusEnum
  subTotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type OrderWithVariantModifiersModel = {
  __typename?: "OrderWithVariantModifiersModel"
  order: OrderModel
  orderDetailModifiers: Array<VariantModifierToItemModel>
}

export type PaginatedAppHistoryModel = {
  __typename?: "PaginatedAppHistoryModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<AppHistoryModel>>
}

export type PaginatedBasicDiscount = {
  __typename?: "PaginatedBasicDiscount"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<BasicDiscount>>
}

export type PaginatedComboList = {
  __typename?: "PaginatedComboList"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<RComboList>>
}

export type PaginatedComboModel = {
  __typename?: "PaginatedComboModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<ComboModel>>
}

export type PaginatedCustomerWithLocationModel = {
  __typename?: "PaginatedCustomerWithLocationModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<CustomerWithLocationModel>>
}

export type PaginatedDeviceModel = {
  __typename?: "PaginatedDeviceModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<DeviceModel>>
}

export type PaginatedIngredientModel = {
  __typename?: "PaginatedIngredientModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<IngredientModel>>
}

export type PaginatedItemList = {
  __typename?: "PaginatedItemList"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<ItemList>>
}

export type PaginatedItemModel = {
  __typename?: "PaginatedItemModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<ItemModel>>
}

export type PaginatedKdsExternalOrder = {
  __typename?: "PaginatedKdsExternalOrder"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<ExternalOrder>>
}

export type PaginatedKdsOrder = {
  __typename?: "PaginatedKdsOrder"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<KdsOrder>>
}

export type PaginatedKdsOrderCompleted = {
  __typename?: "PaginatedKdsOrderCompleted"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<KdsOrderCompleted>>
}

export type PaginatedKdsOrderNotificationListModel = {
  __typename?: "PaginatedKdsOrderNotificationListModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<KdsOrderNotificationListModel>>
}

export type PaginatedListDiscounts = {
  __typename?: "PaginatedListDiscounts"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<Discount>>
}

export type PaginatedLocationModel = {
  __typename?: "PaginatedLocationModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<LocationModel>>
}

export type PaginatedLocationTableZoneModel = {
  __typename?: "PaginatedLocationTableZoneModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<LocationTableZoneModel>>
}

export type PaginatedLocationWithOrderTypesModel = {
  __typename?: "PaginatedLocationWithOrderTypesModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<LocationWithOrderTypesModel>>
}

export type PaginatedMenuList = {
  __typename?: "PaginatedMenuList"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<MenuList>>
}

export type PaginatedModifierGroupList = {
  __typename?: "PaginatedModifierGroupList"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<RModifierGroupList>>
}

export type PaginatedModifierGroupModel = {
  __typename?: "PaginatedModifierGroupModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<ModifierGroupBasicModel>>
}

export type PaginatedNearByLocationModel = {
  __typename?: "PaginatedNearByLocationModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<NearByLocationModel>>
}

export type PaginatedOrderInformationModel = {
  __typename?: "PaginatedOrderInformationModel"
  endCursor?: Maybe<Scalars["String"]>
  filters: OrderFilterModel
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<OrderInformationModel>>
}

export type PaginatedRestaurantIdentifierModel = {
  __typename?: "PaginatedRestaurantIdentifierModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<RestaurantIdentifierModel>>
}

export type PaginatedStripeSubscriptionInvoice = {
  __typename?: "PaginatedStripeSubscriptionInvoice"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<StripeSubscriptionInvoice>>
}

export type PaginatedUserFormattedPhoneModel = {
  __typename?: "PaginatedUserFormattedPhoneModel"
  endCursor?: Maybe<Scalars["String"]>
  hasNextPage: Scalars["Boolean"]
  results?: Maybe<Array<UserFormattedPhoneModel>>
}

export type PaymentDetailByMode = {
  __typename?: "PaymentDetailByMode"
  general: GeneralDetail
  remaining: PaymentDetailsByMethod
}

export type PaymentDetailsByMethod = {
  __typename?: "PaymentDetailsByMethod"
  adjustedSubTotal: Scalars["Float"]
  cashDiscount: Scalars["Float"]
  cateringFee: Scalars["Float"]
  discountDetails: Array<CalculatedDiscount>
  discountsTotal: Scalars["Float"]
  serviceFee: Scalars["Float"]
  storeCash: Scalars["Float"]
  subtotal: Scalars["Float"]
  taxes: Scalars["Float"]
  total: Scalars["Float"]
  underLimit: Scalars["Boolean"]
}

/** The payment method used */
export enum PaymentMethodTypeEnum {
  CARD = "CARD",
  CASH = "CASH",
  FREE_OF_CHARGE = "FREE_OF_CHARGE",
}

/** The payment service used */
export enum PaymentServiceTypeEnum {
  CASH = "CASH",
  EREADER = "EREADER",
  PAYMENTS = "PAYMENTS",
  PAYMENT_LINKS = "PAYMENT_LINKS",
  TAP_TO_PAY = "TAP_TO_PAY",
}

export type Payments = {
  __typename?: "Payments"
  netTotal: NetTotal
}

export type PayrollReport = {
  __typename?: "PayrollReport"
  employeesWorked: Scalars["Int"]
  tipsReceived: Scalars["Float"]
  totalCost: Scalars["Float"]
  totalDuration: Scalars["Int"]
}

export type PrePaymentInput = {
  amount?: InputMaybe<Scalars["Float"]>
  percentage?: InputMaybe<Scalars["Float"]>
}

/** List of restaurant products price ranges: CHEAP $0-$10 | AVG $10-$25 | MID $25-$50 | FINE $50+ */
export enum PriceCategoriesEnum {
  AVG = "AVG",
  CHEAP = "CHEAP",
  FINE = "FINE",
  MID = "MID",
}

export type Query = {
  __typename?: "Query"
  bestSellerItems: ItemSalesSummary
  calculateAmountsByItemOrCombo: RTaxByItemOrCombo
  calculateCateringPaymentAmount: CalculatedCateringPaymentAmount
  calculateExtraPriceFromAComboItem: Scalars["Float"]
  discountsSummary?: Maybe<Array<DiscountsSummary>>
  employees: Array<EmployeeWithLastTimeEntry>
  financialReport: FinancialSummary
  generateDiscountCode: Scalars["String"]
  generateNewDeviceCode: DeviceCode
  getAllCustomersByRestaurant: PaginatedCustomerWithLocationModel
  getAllDevices: PaginatedDeviceModel
  getAllIngredientsByRestaurant: PaginatedIngredientModel
  getAllItems: PaginatedItemModel
  getAllLocations: PaginatedLocationModel
  getAllLocationsByRestaurant: PaginatedLocationWithOrderTypesModel
  getAllLocationsByUser: PaginatedLocationModel
  getAllMembersByRestaurant: Array<MemberWithInviteAndLocationsModel>
  getAllRestaurantsByUser: Array<RestaurantByUserModel>
  getAllTablesByLocation: PaginatedLocationTableZoneModel
  getAllTablesZoneByLocation: Array<TableZoneDetailModel>
  getAppHistory: PaginatedAppHistoryModel
  getBankingAccount: BankingAccountDataModel
  getBankingAccountsByRestaurant: Array<BankingAccountModel>
  getBasicDiscountCodes: PaginatedBasicDiscount
  getBillingSubscriptionPlan: SubscriptionPlan
  getBillingSubscriptionPlans: Array<SubscriptionPlan>
  getComboDetail: RComboDetail
  getComboItemGroupByCombo: Array<ComboItemGroupDetailModel>
  getComboList: PaginatedComboList
  getCombos: PaginatedComboModel
  getCombosByItem: Array<ComboBasicModel>
  getCustomer: CustomerInformationModel
  getDeliveryIntegrationsByRestaurant: Array<DeliveryIntegrationWithLocationsModel>
  getDeliveryLocations: Array<DeliveryLocation>
  getDeliveryPricesByLocation: Array<DeliveryPrice>
  getDiscountByCode?: Maybe<BasicDiscount>
  getExternalLocations: Array<ExternalLocation>
  getHolidaysList: Array<Holiday>
  getItemDetail: RItemDetail
  getItemList: PaginatedItemList
  getLastBuildStatus: AppHistoryModel
  getListDiscounts: PaginatedListDiscounts
  getListOfDiscountCodes: PaginatedBasicDiscount
  getLocationIntegration?: Maybe<ExternalIntegration>
  getLoyaltyProgramByRestaurant?: Maybe<LoyaltyProgramModel>
  getMenuDetail: RMenuDetail
  getMenuList: PaginatedMenuList
  getModifierGroupByRestaurantOrItem: PaginatedModifierGroupModel
  getModifierGroupDetail: RModifierGroupDetail
  getModifierGroupList: PaginatedModifierGroupList
  getNextAvailableTime: NextAvailableTime
  getOneDiscount: Discount
  getOneFeeCategory: FeeCategoryWithRestaurantModel
  getOneItem: ItemModel
  getOneTax: TaxWithLocationOrItemModel
  getOrderDetails: OrderInformationSummary
  getOrderMethodSetting?: Maybe<OrderMethodSettings>
  getOrderSummary: OrderSummaryModel
  getOrdersByRestaurant: PaginatedOrderInformationModel
  getPointsAndStoreCash: LoyaltyCustomerPointsModel
  getQrConfigRestaurant: RestaurantQrConfigModel
  getRestaurantSnapshot?: Maybe<DraftRestaurant>
  getRestaurantSubscription: StripeSubscription
  getRestaurantWhiteLabelConfig: RestaurantConfigsModel
  getRestaurantWithCombosAndItems: RestaurantWithCombosAndItemsModel
  getRestaurantsWithPublishedApp: PaginatedRestaurantIdentifierModel
  getRoles: Array<RoleInfo>
  getScheduleAvailableTime: ScheduleAvailableTime
  getScheduleLocation: ScheduleDetailsModel
  getScheduleRestaurant: ScheduleDetailsModel
  getSchedulesLocation: Array<ScheduleDetailsModel>
  getSchedulesRestaurant: Array<ScheduleDetailsModel>
  getSingleLocation: LocationWithSchedulesModel
  getSortingField: SortingFieldMenu
  getSquareAuthUrl: Scalars["String"]
  getSquareLocations: Array<LocationSquare>
  getStripeConfig: StripeKeyModel
  getSummaryReport: SummaryReport
  getTaxesByRestaurant: Array<TaxLocationModel>
  getUrlIdentifier: RestaurantUrlIdentifierModel
  getUserAddresses: Array<AddressModel>
  getUsers: PaginatedUserFormattedPhoneModel
  getVariantsByItem: Array<VariantOptionModel>
  getVariantsByItems: Array<RItemWithVariants>
  ingredient: RIngredient
  kdsGetCompletedOrderDetails: KdsOrderCompletedDetails
  kdsGetCompletedOrders: PaginatedKdsOrderCompleted
  kdsGetConfirmedExternalOrders: PaginatedKdsExternalOrder
  kdsGetConfirmedOrders: PaginatedKdsOrder
  kdsGetEstimatedPreparationTime: RKdsEstimatedPreparationTime
  kdsGetNotificationsList: PaginatedKdsOrderNotificationListModel
  kdsGetSettings: Array<Devices>
  kdsGetSummaryReport: SummaryReport
  laborCostSummary: LaborCostSummary
  locationCateringRule?: Maybe<LocationCateringRule>
  nearByLocations: PaginatedNearByLocationModel
  orderThrottling?: Maybe<OrderThrottling>
  salesSummary: SalesSummary
  sendDeviceAuthCode: DeviceAuthModel
  timeEntriesByLocationEmployee: EmployeeTimeEntriesReport
}

export type QueryBestSellerItemsArgs = {
  endDate: Scalars["String"]
  locations: Array<Scalars["String"]>
  startDate: Scalars["String"]
}

export type QueryCalculateAmountsByItemOrComboArgs = {
  combo?: InputMaybe<RCalculateComboAmountsInput>
  item?: InputMaybe<RCalculateItemAmountsInput>
  location: RLocationUuidInput
}

export type QueryCalculateCateringPaymentAmountArgs = {
  customAmount?: InputMaybe<Scalars["Float"]>
  order: RuuidInput
  serviceTypes?: InputMaybe<Array<PaymentServiceTypeEnum>>
}

export type QueryCalculateExtraPriceFromAComboItemArgs = {
  combo: RCalculateComboExtraPriceInput
}

export type QueryDiscountsSummaryArgs = {
  endDate: Scalars["String"]
  locations: Array<Scalars["String"]>
  startDate: Scalars["String"]
}

export type QueryEmployeesArgs = {
  isActive?: InputMaybe<Scalars["Boolean"]>
}

export type QueryFinancialReportArgs = {
  endDate: Scalars["String"]
  locations: Array<Scalars["String"]>
  startDate: Scalars["String"]
}

export type QueryGetAllCustomersByRestaurantArgs = {
  after?: InputMaybe<Scalars["String"]>
  email?: InputMaybe<Scalars["String"]>
  locationUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  phone?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetAllDevicesArgs = {
  after?: InputMaybe<Scalars["String"]>
  deviceKey?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID?: InputMaybe<Scalars["ID"]>
  take?: InputMaybe<Scalars["Int"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type QueryGetAllIngredientsByRestaurantArgs = {
  after?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetAllItemsArgs = {
  after?: InputMaybe<Scalars["String"]>
  comboUUID?: InputMaybe<Scalars["ID"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  isPublished?: InputMaybe<Scalars["Boolean"]>
  menuUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type QueryGetAllLocationsArgs = {
  after?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
  uuid?: InputMaybe<Scalars["String"]>
}

export type QueryGetAllLocationsByRestaurantArgs = {
  address?: InputMaybe<Scalars["String"]>
  after?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetAllLocationsByUserArgs = {
  after?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  locationUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
  userUUID: Scalars["ID"]
}

export type QueryGetAllMembersByRestaurantArgs = {
  restaurantUUID: Scalars["ID"]
}

export type QueryGetAllRestaurantsByUserArgs = {
  userUUID: Scalars["ID"]
}

export type QueryGetAllTablesByLocationArgs = {
  after?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  locationUUID: Scalars["ID"]
  tableIdentifier?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetAllTablesZoneByLocationArgs = {
  locationUUID: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
}

export type QueryGetAppHistoryArgs = {
  after?: InputMaybe<Scalars["String"]>
  platform?: InputMaybe<AppPlatformEnum>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetBankingAccountArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetBankingAccountsByRestaurantArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
}

export type QueryGetBasicDiscountCodesArgs = {
  after?: InputMaybe<Scalars["String"]>
  code: Scalars["String"]
  status?: InputMaybe<DiscountStatusesEnum>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetBillingSubscriptionPlanArgs = {
  stLookUpkey: SubscriptionTierEnum
}

export type QueryGetComboDetailArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetComboItemGroupByComboArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetComboListArgs = {
  after?: InputMaybe<Scalars["String"]>
  isPublished?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetCombosArgs = {
  after?: InputMaybe<Scalars["String"]>
  comboUUID?: InputMaybe<Scalars["ID"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  isPublished?: InputMaybe<Scalars["Boolean"]>
  menuUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetCombosByItemArgs = {
  itemUUID: Scalars["ID"]
}

export type QueryGetCustomerArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
  userUUID: Scalars["ID"]
}

export type QueryGetDeliveryIntegrationsByRestaurantArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
}

export type QueryGetDeliveryLocationsArgs = {
  location: RuuidInput
}

export type QueryGetDeliveryPricesByLocationArgs = {
  location: RuuidInput
}

export type QueryGetDiscountByCodeArgs = {
  code: Scalars["String"]
}

export type QueryGetExternalLocationsArgs = {
  externalIntegration: RuuidInput
  integrationType: ExternalIntegrationEnum
}

export type QueryGetHolidaysListArgs = {
  location: RuuidInput
}

export type QueryGetItemDetailArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetItemListArgs = {
  after?: InputMaybe<Scalars["String"]>
  hasBeenPublished?: InputMaybe<Scalars["Boolean"]>
  isPublished?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetLastBuildStatusArgs = {
  platform: AppPlatformEnum
  restaurant: RuuidInput
}

export type QueryGetListDiscountsArgs = {
  after?: InputMaybe<Scalars["String"]>
  code?: InputMaybe<Scalars["String"]>
  discountCodes?: InputMaybe<Array<Scalars["String"]>>
  endDate?: InputMaybe<Scalars["DateTime"]>
  locations?: InputMaybe<Array<Scalars["String"]>>
  orderTypes?: InputMaybe<Array<DiscountsOrderMethodEnum>>
  startDate?: InputMaybe<Scalars["DateTime"]>
  status?: InputMaybe<Array<DiscountStatusesEnum>>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetLocationIntegrationArgs = {
  source: ExternalSourceEnum
}

export type QueryGetLoyaltyProgramByRestaurantArgs = {
  restaurantUUID: Scalars["ID"]
}

export type QueryGetMenuDetailArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetMenuListArgs = {
  after?: InputMaybe<Scalars["String"]>
  isMain?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  sort?: InputMaybe<SortingByMenuEnum>
  status?: InputMaybe<MenuStatusEnum>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetModifierGroupByRestaurantOrItemArgs = {
  after?: InputMaybe<Scalars["String"]>
  itemUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetModifierGroupDetailArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetModifierGroupListArgs = {
  after?: InputMaybe<Scalars["String"]>
  isPublished?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetNextAvailableTimeArgs = {
  location: RuuidInput
}

export type QueryGetOneDiscountArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetOneFeeCategoryArgs = {
  name?: InputMaybe<Scalars["String"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type QueryGetOneItemArgs = {
  isActive?: InputMaybe<Scalars["Boolean"]>
  itemUUID: Scalars["ID"]
}

export type QueryGetOneTaxArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetOrderDetailsArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  orderUUID: Scalars["ID"]
}

export type QueryGetOrderMethodSettingArgs = {
  orderMethod: OrderMethodEnum
}

export type QueryGetOrderSummaryArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  orderUUID: Scalars["ID"]
}

export type QueryGetOrdersByRestaurantArgs = {
  after?: InputMaybe<Scalars["String"]>
  endDate: Scalars["DateTime"]
  locationUUIDs?: InputMaybe<Array<Scalars["ID"]>>
  orderMethods?: InputMaybe<Array<OrderMethodEnum>>
  orderNumber?: InputMaybe<Scalars["String"]>
  paymentMethodTypes?: InputMaybe<Array<PaymentMethodTypeEnum>>
  restaurantUUID: Scalars["ID"]
  startDate: Scalars["DateTime"]
  status?: InputMaybe<Array<OrderStatusEnum>>
  take?: InputMaybe<Scalars["Int"]>
  userUUIDs?: InputMaybe<Array<Scalars["ID"]>>
}

export type QueryGetPointsAndStoreCashArgs = {
  restaurantUUID: Scalars["ID"]
  userUUID: Scalars["ID"]
}

export type QueryGetQrConfigRestaurantArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
}

export type QueryGetRestaurantSnapshotArgs = {
  userUUID: Scalars["ID"]
}

export type QueryGetRestaurantSubscriptionArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetRestaurantWhiteLabelConfigArgs = {
  platform: AppPlatformEnum
  restaurantUUID: Scalars["ID"]
}

export type QueryGetRestaurantWithCombosAndItemsArgs = {
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type QueryGetRestaurantsWithPublishedAppArgs = {
  after?: InputMaybe<Scalars["String"]>
  platform: AppPlatformEnum
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetScheduleAvailableTimeArgs = {
  location: RuuidInput
}

export type QueryGetScheduleLocationArgs = {
  isArchived?: InputMaybe<Scalars["Boolean"]>
  locationUUID: Scalars["ID"]
  scheduleUUID: Scalars["ID"]
}

export type QueryGetScheduleRestaurantArgs = {
  isArchived?: InputMaybe<Scalars["Boolean"]>
  isMain?: InputMaybe<Scalars["Boolean"]>
  restaurantUUID: Scalars["ID"]
  scheduleUUID: Scalars["ID"]
}

export type QueryGetSchedulesLocationArgs = {
  isArchived?: InputMaybe<Scalars["Boolean"]>
  locationUUID: Scalars["ID"]
}

export type QueryGetSchedulesRestaurantArgs = {
  isArchived?: InputMaybe<Scalars["Boolean"]>
  isMain?: InputMaybe<Scalars["Boolean"]>
  restaurantUUID: Scalars["ID"]
}

export type QueryGetSingleLocationArgs = {
  locationUUID: Scalars["ID"]
}

export type QueryGetSortingFieldArgs = {
  uuid: Scalars["ID"]
}

export type QueryGetSquareAuthUrlArgs = {
  integrationType: ExternalIntegrationEnum
}

export type QueryGetSummaryReportArgs = {
  endDate: Scalars["DateTime"]
  startDate: Scalars["DateTime"]
}

export type QueryGetTaxesByRestaurantArgs = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
}

export type QueryGetUrlIdentifierArgs = {
  urlIdentifier: Scalars["String"]
  userUUID: Scalars["ID"]
}

export type QueryGetUserAddressesArgs = {
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["ID"]
  userUUID: Scalars["ID"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type QueryGetUsersArgs = {
  after?: InputMaybe<Scalars["String"]>
  search?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryGetVariantsByItemArgs = {
  itemUUID: Scalars["ID"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type QueryGetVariantsByItemsArgs = {
  items: Array<RuuidInput>
}

export type QueryIngredientArgs = {
  uuid: Scalars["ID"]
}

export type QueryKdsGetCompletedOrderDetailsArgs = {
  order: RuuidInput
}

export type QueryKdsGetCompletedOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>
  location: RuuidInput
  paymentMethodType?: InputMaybe<PaymentMethodTypeEnum>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryKdsGetConfirmedExternalOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>
  isScheduled?: InputMaybe<Scalars["Boolean"]>
  order?: InputMaybe<RuuidInput>
  orderMethods?: InputMaybe<Array<OrderMethodEnum>>
  source?: InputMaybe<ExternalSourceEnum>
  status: Array<OrderStatusEnum>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryKdsGetConfirmedOrdersArgs = {
  after?: InputMaybe<Scalars["String"]>
  isScheduled?: InputMaybe<Scalars["Boolean"]>
  locationUUID: Scalars["ID"]
  orderMethods?: InputMaybe<Array<OrderMethodEnum>>
  orderUUID?: InputMaybe<Scalars["ID"]>
  restaurantUUID: Scalars["ID"]
  status: Array<OrderStatusEnum>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryKdsGetNotificationsListArgs = {
  after?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["ID"]
  restaurantUUID: Scalars["ID"]
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryKdsGetSummaryReportArgs = {
  endDate: Scalars["DateTime"]
  startDate: Scalars["DateTime"]
}

export type QueryLaborCostSummaryArgs = {
  endDate: Scalars["String"]
  locations: Array<Scalars["String"]>
  startDate: Scalars["String"]
}

export type QueryNearByLocationsArgs = {
  after?: InputMaybe<Scalars["String"]>
  categories?: InputMaybe<Array<Scalars["String"]>>
  distance: Scalars["Int"]
  latitude: Scalars["Float"]
  longitude: Scalars["Float"]
  priceCategories?: InputMaybe<Array<PriceCategoriesEnum>>
  take?: InputMaybe<Scalars["Int"]>
}

export type QueryOrderThrottlingArgs = {
  location: RuuidInput
}

export type QuerySalesSummaryArgs = {
  endDate: Scalars["String"]
  locations: Array<Scalars["String"]>
  startDate: Scalars["String"]
}

export type QuerySendDeviceAuthCodeArgs = {
  data: SendDeviceCodeInput
}

export type QueryTimeEntriesByLocationEmployeeArgs = {
  endDate: Scalars["String"]
  startDate?: InputMaybe<Scalars["String"]>
}

export type RBasicEntityData = {
  __typename?: "RBasicEntityData"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RBasicMenuDetail = {
  __typename?: "RBasicMenuDetail"
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RCalculateComboAmountsInput = {
  comboGroups: Array<RCalculateComboItemGroupAmountsInput>
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type RCalculateComboExtraPriceInput = {
  comboGroup: RCalculateComboItemGroupExtraPriceInput
  uuid: Scalars["ID"]
}

export type RCalculateComboItemAmountsInput = {
  item: RCalculateItemByComboAmountsInput
}

export type RCalculateComboItemGroupAmountsInput = {
  comboItems: Array<RCalculateComboItemAmountsInput>
  uuid: Scalars["ID"]
}

export type RCalculateComboItemGroupExtraPriceInput = {
  comboItem: RCalculateComboItemAmountsInput
  uuid: Scalars["ID"]
}

export type RCalculateItemAmountsInput = {
  modifierGroups?: InputMaybe<Array<RCalculateModifierGroupAmountsInput>>
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
  variant: RVariantUuidInput
}

export type RCalculateItemByComboAmountsInput = {
  modifierGroups?: InputMaybe<Array<RCalculateModifierGroupAmountsInput>>
  uuid: Scalars["ID"]
  variant: RVariantUuidInput
}

export type RCalculateModifierAmountsInput = {
  quantity: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type RCalculateModifierGroupAmountsInput = {
  modifiers: Array<RCalculateModifierAmountsInput>
  uuid: Scalars["ID"]
}

export type RComboDetail = {
  __typename?: "RComboDetail"
  attachment?: Maybe<AttachmentModel>
  comboItemGroups: Array<RComboItemGroupDetail>
  createdAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  discountPercentage?: Maybe<Scalars["Float"]>
  fullPrice?: Maybe<Scalars["Float"]>
  hasSnapshot?: Maybe<Scalars["Boolean"]>
  isMain: Scalars["Boolean"]
  isPublished?: Maybe<Scalars["Boolean"]>
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  menus: Array<RBasicMenuDetail>
  name: Scalars["String"]
  priceOverride?: Maybe<Scalars["Float"]>
  publishedAt?: Maybe<Scalars["DateTime"]>
  snapshotUpdatedAt?: Maybe<Scalars["DateTime"]>
  updatedAt?: Maybe<Scalars["DateTime"]>
  uuid: Scalars["ID"]
}

export type RComboItemDetail = {
  __typename?: "RComboItemDetail"
  isDefault: Scalars["Boolean"]
  item: RItemComboDetail
  uuid: Scalars["ID"]
}

export type RComboItemGroupDetail = {
  __typename?: "RComboItemGroupDetail"
  comboItems: Array<RComboItemDetail>
  name?: Maybe<Scalars["String"]>
  uuid?: Maybe<Scalars["ID"]>
}

export type RComboList = {
  __typename?: "RComboList"
  attachment?: Maybe<AttachmentModel>
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RCreateIngredientInput = {
  name: Scalars["String"]
}

export type RCreatedIngredient = {
  __typename?: "RCreatedIngredient"
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RCustomerWithUserInformation = {
  __typename?: "RCustomerWithUserInformation"
  user: RUserPersonalInformation
  uuid: Scalars["ID"]
}

export type RDayIsOpenInput = {
  day: WeekDaysEnum
  isOpen: Scalars["Boolean"]
}

export type RDeletedModifierGroup = {
  __typename?: "RDeletedModifierGroup"
  uuid: Scalars["ID"]
}

export type RDeliveryIntegration = {
  __typename?: "RDeliveryIntegration"
  apiKey: Scalars["String"]
  developerId?: Maybe<Scalars["String"]>
  integrationLabel?: Maybe<Scalars["String"]>
  integrationName: DeliveryIntegrationEnum
  isActive: Scalars["Boolean"]
  issues?: Maybe<Scalars["String"]>
  settings?: Maybe<Scalars["JSON"]>
  signingSecret?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type RDraftMenu = {
  __typename?: "RDraftMenu"
  applyToAllLocations?: Maybe<Scalars["Boolean"]>
  combos: Array<RDraftMenuCombo>
  createdAt: Scalars["DateTime"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  itemSortBy?: Maybe<SortingByMenuEnum>
  items: Array<RDraftMenuItem>
  locations?: Maybe<Array<RBasicEntityData>>
  menuType: MenuTypeEnum
  name: Scalars["String"]
  publishedAt?: Maybe<Scalars["DateTime"]>
  snapshotUpdatedAt?: Maybe<Scalars["DateTime"]>
  status: MenuStatusEnum
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type RDraftMenuCombo = {
  __typename?: "RDraftMenuCombo"
  attachment?: Maybe<AttachmentModel>
  createdAt?: Maybe<Scalars["DateTime"]>
  isPublished: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RDraftMenuItem = {
  __typename?: "RDraftMenuItem"
  attachment?: Maybe<AttachmentModel>
  createdAt?: Maybe<Scalars["DateTime"]>
  isPublished: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RHidedOrShowedMenu = {
  __typename?: "RHidedOrShowedMenu"
  name: Scalars["String"]
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type RIngredient = {
  __typename?: "RIngredient"
  name: Scalars["String"]
  tags: Array<RIngredientTagModel>
  uuid: Scalars["ID"]
}

export type RIngredientDetail = {
  __typename?: "RIngredientDetail"
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RIngredientTagModel = {
  __typename?: "RIngredientTagModel"
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
}

export type RItemComboDetail = {
  __typename?: "RItemComboDetail"
  isMain: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
  variants: Array<RVariantComboDetail>
}

export type RItemDetail = {
  __typename?: "RItemDetail"
  attachment?: Maybe<AttachmentModel>
  createdAt: Scalars["DateTime"]
  description?: Maybe<Scalars["String"]>
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  menus: Array<RBasicMenuDetail>
  name?: Maybe<Scalars["String"]>
  publishedAt?: Maybe<Scalars["DateTime"]>
  serves?: Maybe<Scalars["Int"]>
  snapshotUpdatedAt?: Maybe<Scalars["DateTime"]>
  status: ItemProgressStatusEnum
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
  variants: Array<RItemVariant>
  variantsSortBy?: Maybe<SortingByMenuEnum>
}

export type RItemIngredient = {
  __typename?: "RItemIngredient"
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RItemModifier = {
  __typename?: "RItemModifier"
  ingredient: RItemIngredient
  uuid: Scalars["ID"]
}

export type RItemModifierGroup = {
  __typename?: "RItemModifierGroup"
  isMain: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RItemVariant = {
  __typename?: "RItemVariant"
  attachment?: Maybe<AttachmentModel>
  calories?: Maybe<Scalars["Int"]>
  createdAt?: Maybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name?: Maybe<Scalars["String"]>
  price?: Maybe<Scalars["Float"]>
  sku?: Maybe<Scalars["String"]>
  updatedAt?: Maybe<Scalars["DateTime"]>
  uuid: Scalars["ID"]
  variantModifierGroups: Array<RItemVariantModifierGroup>
  variantModifierGroupsSortBy?: Maybe<SortingByMenuEnum>
}

export type RItemVariantModifier = {
  __typename?: "RItemVariantModifier"
  calories?: Maybe<Scalars["Int"]>
  isIncluded: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  modifier: RItemModifier
  price?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type RItemVariantModifierGroup = {
  __typename?: "RItemVariantModifierGroup"
  createdAt?: Maybe<Scalars["DateTime"]>
  fieldType?: Maybe<FieldTypeEnumItem>
  isRequired?: Maybe<Scalars["Boolean"]>
  maxSelectedOptions?: Maybe<Scalars["Int"]>
  minSelectedOptions?: Maybe<Scalars["Int"]>
  modifierGroup: RItemModifierGroup
  uuid: Scalars["ID"]
  variantModifiers: Array<RItemVariantModifier>
}

export type RItemWithVariants = {
  __typename?: "RItemWithVariants"
  isMain: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name?: Maybe<Scalars["String"]>
  price: Scalars["Float"]
  uuid: Scalars["ID"]
  variants: Array<RVariant>
}

export type RKdsEstimatedPreparationTime = {
  __typename?: "RKdsEstimatedPreparationTime"
  estimatedPreparationTime: Scalars["Int"]
}

export type RKioskConnectionToken = {
  __typename?: "RKioskConnectionToken"
  stSecret: Scalars["String"]
}

export type RLocationUuidInput = {
  uuid: Scalars["ID"]
}

export type RMenuDetail = {
  __typename?: "RMenuDetail"
  applyToAllLocations?: Maybe<Scalars["Boolean"]>
  combos: Array<RDraftMenuCombo>
  createdAt: Scalars["DateTime"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  itemSortBy?: Maybe<SortingByMenuEnum>
  items: Array<RDraftMenuItem>
  locations?: Maybe<Array<RBasicEntityData>>
  menuType: MenuTypeEnum
  name: Scalars["String"]
  publishedAt?: Maybe<Scalars["DateTime"]>
  snapshotUpdatedAt?: Maybe<Scalars["DateTime"]>
  status: MenuStatusEnum
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type RModifierDetail = {
  __typename?: "RModifierDetail"
  ingredient: RIngredientDetail
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  uuid: Scalars["ID"]
}

export type RModifierGroupDetail = {
  __typename?: "RModifierGroupDetail"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  modifiers: Array<RModifierDetail>
  name: Scalars["String"]
  publishedAt?: Maybe<Scalars["DateTime"]>
  snapshotUpdatedAt?: Maybe<Scalars["DateTime"]>
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type RModifierGroupList = {
  __typename?: "RModifierGroupList"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["String"]
}

export type ROrderGeneralInformation = {
  __typename?: "ROrderGeneralInformation"
  createdAt: Scalars["DateTime"]
  customer: RCustomerWithUserInformation
  location: RBasicEntityData
  orderMethod: OrderMethodEnum
  orderNumber: Scalars["String"]
  restaurant: RBasicEntityData
  status: OrderStatusEnum
  total: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RPublishAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RPublishComboInput = {
  attachment?: InputMaybe<RSaveAttachmentInput>
  comboItemGroups: Array<RPublishComboItemGroupInput>
  description?: InputMaybe<Scalars["String"]>
  discountPercentage: Scalars["Float"]
  fullPrice: Scalars["Float"]
  isMain: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  menus: Array<RuuidInput>
  name: Scalars["String"]
  priceOverride: Scalars["Float"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishComboItemGroupInput = {
  comboItems: Array<RPublishComboItemInput>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  name: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishComboItemInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  item: RuuidInput
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishItemInput = {
  attachment?: InputMaybe<RPublishAttachmentInput>
  description?: InputMaybe<Scalars["String"]>
  isMain: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  menus: Array<RuuidInput>
  name: Scalars["String"]
  serves?: InputMaybe<Scalars["Int"]>
  uuid?: InputMaybe<Scalars["ID"]>
  variants: Array<RPublishVariantInput>
  variantsSortBy?: InputMaybe<SortingByMenuEnum>
}

export type RPublishMenuInput = {
  applyToAllLocations: Scalars["Boolean"]
  comboSortBy?: InputMaybe<SortingByMenuEnum>
  combos?: InputMaybe<Array<RuuidInput>>
  isMain: Scalars["Boolean"]
  itemSortBy?: InputMaybe<SortingByMenuEnum>
  items?: InputMaybe<Array<RuuidInput>>
  locations?: InputMaybe<Array<RuuidInput>>
  menuType: MenuTypeEnum
  name: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishModifierGroupInput = {
  isMain: Scalars["Boolean"]
  modifiers: Array<RSaveModifierInput>
  name: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishVariantInput = {
  attachment?: InputMaybe<RPublishAttachmentInput>
  calories?: InputMaybe<Scalars["Int"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  sku?: InputMaybe<Scalars["String"]>
  uuid?: InputMaybe<Scalars["ID"]>
  variantModifierGroups: Array<RPublishVariantModifierGroupInput>
  variantModifierGroupsSortBy?: InputMaybe<SortingByMenuEnum>
}

export type RPublishVariantModifierGroupInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  fieldType: FieldTypeEnumItem
  isRequired: Scalars["Boolean"]
  maxSelectedOptions: Scalars["Int"]
  minSelectedOptions: Scalars["Int"]
  modifierGroup: RuuidInput
  uuid?: InputMaybe<Scalars["ID"]>
  variantModifiers: Array<RPublishVariantModifierInput>
}

export type RPublishVariantModifierInput = {
  calories?: InputMaybe<Scalars["Int"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isIncluded: Scalars["Boolean"]
  modifier: RuuidInput
  price: Scalars["Float"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RPublishedCombo = {
  __typename?: "RPublishedCombo"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RPublishedDraftMenu = {
  __typename?: "RPublishedDraftMenu"
  menuType: MenuTypeEnum
  name: Scalars["String"]
  uuid: Scalars["String"]
}

export type RPublishedItem = {
  __typename?: "RPublishedItem"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  serves?: Maybe<Scalars["Int"]>
  uuid: Scalars["ID"]
  variantsSortBy?: Maybe<SortingByMenuEnum>
}

export type RPublishedMenu = {
  __typename?: "RPublishedMenu"
  applyToAllLocations?: Maybe<Scalars["Boolean"]>
  combosCount: Scalars["Int"]
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  itemSortBy?: Maybe<SortingByMenuEnum>
  itemsCount: Scalars["Int"]
  menuType: MenuTypeEnum
  name: Scalars["String"]
  status: MenuStatusEnum
  uuid: Scalars["ID"]
}

export type RPublishedModifierGroup = {
  __typename?: "RPublishedModifierGroup"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSaveAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSaveComboInput = {
  attachment?: InputMaybe<RSaveAttachmentInput>
  comboItemGroups: Array<RSaveComboItemGroupInput>
  description?: InputMaybe<Scalars["String"]>
  discountPercentage?: InputMaybe<Scalars["Float"]>
  fullPrice?: InputMaybe<Scalars["Float"]>
  isMain: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  menus: Array<RuuidInput>
  name?: InputMaybe<Scalars["String"]>
  priceOverride?: InputMaybe<Scalars["Float"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveComboItemGroupInput = {
  comboItems: Array<RSaveComboItemInput>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  name?: InputMaybe<Scalars["String"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveComboItemInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  item: RuuidInput
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveCorporateComboInput = {
  discountPercentage: Scalars["Float"]
  isSoldOut: Scalars["Boolean"]
  isVisible: Scalars["Boolean"]
  menus: Array<RuuidInput>
  name: Scalars["String"]
  priceOverride: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RSaveCorporateItemInput = {
  isSoldOut: Scalars["Boolean"]
  isVisible: Scalars["Boolean"]
  menus: Array<RuuidInput>
  uuid: Scalars["ID"]
  variants: Array<RSaveCorporateVariantInput>
}

export type RSaveCorporateModifierGroupInput = {
  modifiers: Array<RSaveCorporateModifierInput>
  uuid: Scalars["ID"]
}

export type RSaveCorporateModifierInput = {
  isSoldOut: Scalars["Boolean"]
  isVisible: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type RSaveCorporateVariantInput = {
  isSoldOut: Scalars["Boolean"]
  isVisible: Scalars["Boolean"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
  variantModifierGroups: Array<RSaveCorporateVariantModifierGroupInput>
}

export type RSaveCorporateVariantModifierGroupInput = {
  uuid: Scalars["ID"]
  variantModifiers: Array<RSaveCorporateVariantModifierInput>
}

export type RSaveCorporateVariantModifierInput = {
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RSaveItemInput = {
  attachment?: InputMaybe<RSaveAttachmentInput>
  description?: InputMaybe<Scalars["String"]>
  isMain: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  menus: Array<RuuidInput>
  name?: InputMaybe<Scalars["String"]>
  serves?: InputMaybe<Scalars["Int"]>
  uuid?: InputMaybe<Scalars["ID"]>
  variants: Array<RSaveVariantInput>
  variantsSortBy?: InputMaybe<SortingByMenuEnum>
}

export type RSaveMenuInput = {
  applyToAllLocations: Scalars["Boolean"]
  comboSortBy?: InputMaybe<SortingByMenuEnum>
  combos?: InputMaybe<Array<RuuidInput>>
  isMain: Scalars["Boolean"]
  itemSortBy?: InputMaybe<SortingByMenuEnum>
  items?: InputMaybe<Array<RuuidInput>>
  locations?: InputMaybe<Array<RuuidInput>>
  menuType: MenuTypeEnum
  name: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveModifierGroupInput = {
  isMain: Scalars["Boolean"]
  modifiers: Array<RSaveModifierInput>
  name: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveModifierInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  ingredient: RuuidInput
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  isVisible?: InputMaybe<Scalars["Boolean"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSaveVariantInput = {
  attachment?: InputMaybe<RSaveAttachmentInput>
  calories?: InputMaybe<Scalars["Int"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isDefault: Scalars["Boolean"]
  isSoldOut?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["Float"]>
  sku?: InputMaybe<Scalars["String"]>
  uuid?: InputMaybe<Scalars["ID"]>
  variantModifierGroups: Array<RSaveVariantModifierGroupInput>
  variantModifierGroupsSortBy?: InputMaybe<SortingByMenuEnum>
}

export type RSaveVariantModifierGroupInput = {
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  fieldType?: InputMaybe<FieldTypeEnumItem>
  isRequired?: InputMaybe<Scalars["Boolean"]>
  maxSelectedOptions?: InputMaybe<Scalars["Int"]>
  minSelectedOptions?: InputMaybe<Scalars["Int"]>
  modifierGroup: RuuidInput
  uuid?: InputMaybe<Scalars["ID"]>
  variantModifiers: Array<RSaveVariantModifierInput>
}

export type RSaveVariantModifierInput = {
  calories?: InputMaybe<Scalars["Int"]>
  deletedAt?: InputMaybe<Scalars["DateTime"]>
  isIncluded: Scalars["Boolean"]
  modifier: RuuidInput
  price?: InputMaybe<Scalars["Float"]>
  uuid?: InputMaybe<Scalars["ID"]>
}

export type RSavedCombo = {
  __typename?: "RSavedCombo"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSavedCorporateCombo = {
  __typename?: "RSavedCorporateCombo"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSavedCorporateItem = {
  __typename?: "RSavedCorporateItem"
  isVisible: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSavedCorporateModifierGroup = {
  __typename?: "RSavedCorporateModifierGroup"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RSavedItem = {
  __typename?: "RSavedItem"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  serves?: Maybe<Scalars["Int"]>
  uuid: Scalars["ID"]
  variantsSortBy?: Maybe<SortingByMenuEnum>
}

export type RSavedModifierGroup = {
  __typename?: "RSavedModifierGroup"
  hasSnapshot: Scalars["Boolean"]
  isMain: Scalars["Boolean"]
  isPublished: Scalars["Boolean"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RScheduleDetail = {
  __typename?: "RScheduleDetail"
  createdAt: Scalars["DateTime"]
  day: WeekDaysEnum
  endDate: Scalars["DateTime"]
  isOpen: Scalars["Boolean"]
  startDate: Scalars["DateTime"]
  updatedAt: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type RTaxByItemOrCombo = {
  __typename?: "RTaxByItemOrCombo"
  subtotal: Scalars["Float"]
  total: Scalars["Float"]
  totalTaxes: Scalars["Float"]
  unitPrice: Scalars["Float"]
  unitPriceTax: Scalars["Float"]
}

export type Ruuid = {
  __typename?: "RUUID"
  uuid: Scalars["ID"]
}

export type RuuidInput = {
  uuid: Scalars["ID"]
}

export type RUnpublishMenu = {
  __typename?: "RUnpublishMenu"
  menuType: MenuTypeEnum
  name: Scalars["String"]
  uuid: Scalars["String"]
}

export type RUpdateIngredientInput = {
  categoryName?: InputMaybe<Scalars["String"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RUpdateOpenFlagInput = {
  location: RuuidInput
  restaurant: RuuidInput
  schedules: Array<RDayIsOpenInput>
}

export type RUserPersonalInformation = {
  __typename?: "RUserPersonalInformation"
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phone: Scalars["String"]
  stCustomerId: Scalars["String"]
}

export type RVariant = {
  __typename?: "RVariant"
  attachment?: Maybe<AttachmentModel>
  extraPrice: Scalars["Float"]
  isDefault: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RVariantComboDetail = {
  __typename?: "RVariantComboDetail"
  attachment?: Maybe<AttachmentModel>
  extraPrice: Scalars["Float"]
  isDefault: Scalars["Boolean"]
  isSoldOut?: Maybe<Scalars["Boolean"]>
  isVisible?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RVariantUuidInput = {
  uuid: Scalars["ID"]
}

export type RefundBasic = {
  __typename?: "RefundBasic"
  amount: Scalars["Float"]
  amountDetails: Array<AmountDetail>
  comment: Scalars["String"]
  storeCash: Scalars["Float"]
}

/** The refund method type of a refund */
export enum RefundMethodTypeEnum {
  CARD = "CARD",
  CASH = "CASH",
}

/** The refund type of an order */
export enum RefundTypeEnum {
  PARTIAL = "PARTIAL",
  TOTAL = "TOTAL",
}

export type RefundedInvoice = {
  __typename?: "RefundedInvoice"
  totalTip: Scalars["Float"]
  type: RefundTypeEnum
}

export type ResetComboPriceModel = {
  __typename?: "ResetComboPriceModel"
  discountPercentage: Scalars["Float"]
  price: Scalars["Float"]
  priceOverride: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RestaurantAttachmentModel = {
  __typename?: "RestaurantAttachmentModel"
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  signedUrl: Scalars["String"]
  type: Scalars["String"]
  uuid: Scalars["String"]
}

export type RestaurantBasicModel = {
  __typename?: "RestaurantBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RestaurantByUserModel = {
  __typename?: "RestaurantByUserModel"
  attachments?: Maybe<Array<RestaurantAttachmentModel>>
  isCateringActive: Scalars["Boolean"]
  isTestMode: Scalars["Boolean"]
  locationsQuantity?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  passFeeToCustomer: Scalars["Boolean"]
  priceCategory?: Maybe<PriceCategoriesEnum>
  urlIdentifier: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RestaurantComboModel = {
  __typename?: "RestaurantComboModel"
  description?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type RestaurantConfigsModel = {
  __typename?: "RestaurantConfigsModel"
  appClipConfigFile?: Maybe<Scalars["String"]>
  attachment?: Maybe<RestaurantAttachmentModel>
  defaultImage?: Maybe<Scalars["String"]>
  platformConfigFile: Scalars["String"]
  uuid: Scalars["ID"]
  whiteLabelConfig?: Maybe<Scalars["JSON"]>
}

export type RestaurantIdentifierModel = {
  __typename?: "RestaurantIdentifierModel"
  appClipConfigFile?: Maybe<Scalars["String"]>
  attachment?: Maybe<RestaurantAttachmentModel>
  platformConfigFile: Scalars["String"]
  uuid: Scalars["ID"]
  whiteLabelConfig?: Maybe<Scalars["JSON"]>
}

export type RestaurantLocation = {
  __typename?: "RestaurantLocation"
  address?: Maybe<Scalars["String"]>
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  attachment?: Maybe<RestaurantAttachmentModel>
  city?: Maybe<Scalars["String"]>
  latitude?: Maybe<Scalars["String"]>
  longitude?: Maybe<Scalars["String"]>
  name?: Maybe<Scalars["String"]>
  orderTypes?: Maybe<Array<OrderMethodEnum>>
  phone?: Maybe<Scalars["String"]>
  postalCode?: Maybe<Scalars["String"]>
  state?: Maybe<Scalars["String"]>
  timezone?: Maybe<Scalars["String"]>
}

export type RestaurantModel = {
  __typename?: "RestaurantModel"
  attachments?: Maybe<Array<RestaurantAttachmentModel>>
  categories?: Maybe<Array<Categories>>
  deletedAt?: Maybe<Scalars["DateTime"]>
  isActive: Scalars["Boolean"]
  isCateringActive: Scalars["Boolean"]
  locationsQuantity?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  passFeeToCustomer?: Maybe<Scalars["Boolean"]>
  priceCategory?: Maybe<PriceCategoriesEnum>
  urlIdentifier: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RestaurantQrConfigModel = {
  __typename?: "RestaurantQrConfigModel"
  qrConfig?: Maybe<Scalars["JSON"]>
  uuid: Scalars["ID"]
}

export type RestaurantSnapshot = {
  __typename?: "RestaurantSnapshot"
  brandColor?: Maybe<Scalars["String"]>
  currentStep?: Maybe<Scalars["Int"]>
  location?: Maybe<RestaurantLocation>
  locationsQuantity?: Maybe<Scalars["String"]>
  logos?: Maybe<Array<RestaurantAttachmentModel>>
  menuAttachments?: Maybe<Array<AttachmentSnapshot>>
  name?: Maybe<Scalars["String"]>
  notes?: Maybe<Scalars["String"]>
  urlIdentifier?: Maybe<Scalars["String"]>
}

export type RestaurantSnapshotInput = {
  brandColor?: InputMaybe<Scalars["String"]>
  currentStep?: InputMaybe<Scalars["Int"]>
  location?: InputMaybe<LocationSnapshotInput>
  locationsQuantity?: InputMaybe<Scalars["String"]>
  logos?: InputMaybe<Array<UpdateRestaurantAttachmentInput>>
  menuAttachments?: InputMaybe<Array<AttachmentSnapshotInput>>
  name?: InputMaybe<Scalars["String"]>
  notes?: InputMaybe<Scalars["String"]>
  urlIdentifier?: InputMaybe<Scalars["String"]>
}

export type RestaurantUrlIdentifierModel = {
  __typename?: "RestaurantUrlIdentifierModel"
  isUsed: Scalars["Boolean"]
  urlIdentifierReturnMessage?: Maybe<Scalars["String"]>
}

export type RestaurantWhiteLabelConfigModel = {
  __typename?: "RestaurantWhiteLabelConfigModel"
  attachment?: Maybe<RestaurantAttachmentModel>
  uuid: Scalars["ID"]
  whiteLabelConfig: Scalars["JSON"]
}

export type RestaurantWithCombosAndItemsModel = {
  __typename?: "RestaurantWithCombosAndItemsModel"
  attachments?: Maybe<Array<RestaurantAttachmentModel>>
  categories?: Maybe<Array<Categories>>
  combos: Array<RestaurantComboModel>
  deletedAt?: Maybe<Scalars["DateTime"]>
  isActive: Scalars["Boolean"]
  isCateringActive: Scalars["Boolean"]
  items: Array<ItemCommonModel>
  locationsQuantity?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  passFeeToCustomer?: Maybe<Scalars["Boolean"]>
  priceCategory?: Maybe<PriceCategoriesEnum>
  urlIdentifier: Scalars["String"]
  uuid: Scalars["ID"]
}

export type RoleInfo = {
  __typename?: "RoleInfo"
  name: Scalars["String"]
  roleCode: EmployeeRoleCodeEnum
  uuid: Scalars["ID"]
}

export type Rule = {
  __typename?: "Rule"
  allCombos: Scalars["Boolean"]
  allItems: Scalars["Boolean"]
  availableQuantity?: Maybe<Scalars["Float"]>
  combos: Array<ComboBasicModel>
  endDate?: Maybe<Scalars["DateTime"]>
  generatedQuantity?: Maybe<Scalars["Float"]>
  items: Array<ItemBasicModel>
  lifetimeSpend?: Maybe<Scalars["Float"]>
  limitPerCustomer?: Maybe<Scalars["Float"]>
  locations: Array<LocationBasicModel>
  orderTypes?: Maybe<Array<DiscountsOrderMethodEnum>>
  stackingAvailable?: Maybe<Scalars["Boolean"]>
  startDate?: Maybe<Scalars["DateTime"]>
  timesUsed: Scalars["Int"]
  users: Array<UserBasicModel>
  uuid: Scalars["ID"]
}

export type Sales = {
  __typename?: "Sales"
  totalSales: TotalSales
}

export type SalesByPaymentType = {
  __typename?: "SalesByPaymentType"
  card: Scalars["Float"]
  cash: Scalars["Float"]
  total: Scalars["Float"]
}

export type SalesSummary = {
  __typename?: "SalesSummary"
  payments: Payments
  sales: Sales
}

export type SalesSummaryDetail = {
  __typename?: "SalesSummaryDetail"
  deliveryFees: Scalars["Float"]
  grossSales: Scalars["Float"]
  netSales: Scalars["Float"]
  serviceFees: Scalars["Float"]
  taxes: Scalars["Float"]
  tips: Scalars["Float"]
}

export type SaveIntegrationApiKeysInput = {
  integrationAccessToken: Scalars["String"]
  integrationAppId: Scalars["String"]
  source: ExternalSourceEnum
}

export type ScheduleAvailableTime = {
  __typename?: "ScheduleAvailableTime"
  today: Array<Scalars["DateTime"]>
  tomorrow: Array<Scalars["DateTime"]>
}

export type ScheduleBasicWithDetailsModel = {
  __typename?: "ScheduleBasicWithDetailsModel"
  scheduleDetail: Array<ScheduleDetailModel>
  uuid: Scalars["ID"]
}

export type ScheduleDetail = {
  day?: InputMaybe<WeekDaysEnum>
  endDate: Scalars["String"]
  startDate: Scalars["String"]
}

export type ScheduleDetailModel = {
  __typename?: "ScheduleDetailModel"
  day: Scalars["String"]
  endDate: Scalars["DateTime"]
  startDate: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type ScheduleDetailsModel = {
  __typename?: "ScheduleDetailsModel"
  archivedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  scheduleDetail: Array<ScheduleDetailModel>
  uuid: Scalars["ID"]
}

export type ScheduleModel = {
  __typename?: "ScheduleModel"
  archivedAt?: Maybe<Scalars["DateTime"]>
  description?: Maybe<Scalars["String"]>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type SendDeviceCodeInput = {
  email?: InputMaybe<Scalars["String"]>
  location: RuuidInput
  phoneNumber?: InputMaybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type ServicesInput = {
  feeFixedAmount?: InputMaybe<Scalars["Float"]>
  feePercentage?: InputMaybe<Scalars["Float"]>
  minimumAmount?: InputMaybe<Scalars["Float"]>
  type: CateringServiceEnum
  uuid?: InputMaybe<Scalars["ID"]>
}

export type SetupIntegrationInput = {
  code: Scalars["String"]
  source: ExternalSourceEnum
}

export type SortFieldInput = {
  restaurantUUID?: InputMaybe<Scalars["ID"]>
  sortBy: SortingByMenuEnum
  sortUUID?: InputMaybe<Scalars["ID"]>
}

export type SortInput = {
  index: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type SortMenuComboInput = {
  comboUUID: Scalars["ID"]
  index: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type SortMenuItemInput = {
  index: Scalars["Int"]
  itemUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type SortModel = {
  __typename?: "SortModel"
  index: Scalars["Int"]
  uuid: Scalars["ID"]
}

export type SortModifierGroupToVariantInput = {
  index: Scalars["Int"]
  uuid: Scalars["ID"]
  variantUUID: Scalars["ID"]
}

export type SortingByMenu = {
  __typename?: "SortingByMenu"
  createdAt?: Maybe<Scalars["String"]>
  itemOrder?: Maybe<Scalars["String"]>
}

/** Menu sorting */
export enum SortingByMenuEnum {
  CUSTOM = "CUSTOM",
  DATE_ASC = "DATE_ASC",
  DATE_DESC = "DATE_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export type SortingFieldMenu = {
  __typename?: "SortingFieldMenu"
  sortBy: SortingByMenu
  sortingType?: Maybe<SortingByMenuEnum>
  uuid?: Maybe<Scalars["ID"]>
}

export type SquareAddress = {
  __typename?: "SquareAddress"
  addressLine1?: Maybe<Scalars["String"]>
  addressLine2?: Maybe<Scalars["String"]>
  addressLine3?: Maybe<Scalars["String"]>
}

/** The supported status */
export enum StatusTableEnum {
  AVAILABLE = "AVAILABLE",
  BOOKED = "BOOKED",
}

export type StripeAccountModel = {
  __typename?: "StripeAccountModel"
  isActive: Scalars["Boolean"]
  name: Scalars["String"]
  stIsConnected: Scalars["Boolean"]
  url: Scalars["String"]
  uuid: Scalars["ID"]
}

export type StripeAccountUrlModel = {
  __typename?: "StripeAccountUrlModel"
  url: Scalars["String"]
}

export type StripeKeyModel = {
  __typename?: "StripeKeyModel"
  publishableKey: Scalars["String"]
}

export type StripeSubscription = {
  __typename?: "StripeSubscription"
  gracePeriodEndDate?: Maybe<Scalars["DateTime"]>
  invoices: PaginatedStripeSubscriptionInvoice
  numberOfActiveLocations: Scalars["Int"]
  numberOfLocations: Scalars["Int"]
  status: SubscriptionStatusEnum
  tier: SubscriptionTierEnum
  trialEndDate: Scalars["DateTime"]
  uuid: Scalars["ID"]
}

export type StripeSubscriptionInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]>
  take?: InputMaybe<Scalars["Int"]>
}

export type StripeSubscriptionInvoice = {
  __typename?: "StripeSubscriptionInvoice"
  dueDate: Scalars["DateTime"]
  paidAt?: Maybe<Scalars["DateTime"]>
  stInvoiceId: Scalars["String"]
  stInvoiceStatus: SubscriptionInvoiceStatusEnum
  total: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type Subscription = {
  __typename?: "Subscription"
  kdsDeviceActionStream: DeviceAction
  kdsExternalOrdersPaid: ExternalOrder
  kdsExternalOrdersUpdated: ExternalOrder
  kdsOrderPaidSuccessfully: KdsOrderSuccessfullyPaid
  kdsOrderStatusUpdated: KdsOrderUpdated
  kdsOrderWithPendingCashPayment: Ruuid
  orderStatusThroughout: ROrderGeneralInformation
}

export type SubscriptionKdsDeviceActionStreamArgs = {
  location: RuuidInput
}

export type SubscriptionKdsExternalOrdersPaidArgs = {
  location: RuuidInput
}

export type SubscriptionKdsExternalOrdersUpdatedArgs = {
  location: RuuidInput
}

export type SubscriptionKdsOrderPaidSuccessfullyArgs = {
  locationUUID: Scalars["ID"]
}

export type SubscriptionKdsOrderStatusUpdatedArgs = {
  location: RuuidInput
}

export type SubscriptionKdsOrderWithPendingCashPaymentArgs = {
  location: RuuidInput
}

export type SubscriptionOrderStatusThroughoutArgs = {
  data: OrderStatusThroughoutInput
}

/** The status of the stripe subscription invoice */
export enum SubscriptionInvoiceStatusEnum {
  DRAFT = "DRAFT",
  OPEN = "OPEN",
  PAID = "PAID",
  UNCOLLECTIBLE = "UNCOLLECTIBLE",
  VOID = "VOID",
}

export type SubscriptionPaymentIntentModel = {
  __typename?: "SubscriptionPaymentIntentModel"
  createdAt: Scalars["DateTime"]
  stCheckoutSessionURL: Scalars["String"]
  uuid: Scalars["ID"]
}

export type SubscriptionPlan = {
  __typename?: "SubscriptionPlan"
  features: Scalars["JSON"]
  name: Scalars["String"]
  price: Scalars["Float"]
  stLookUpKey: SubscriptionTierEnum
  trialDays: Scalars["Int"]
  uuid: Scalars["ID"]
}

/** The status of the subscription */
export enum SubscriptionStatusEnum {
  ACTIVE = "ACTIVE",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  PAST_DUE = "PAST_DUE",
  PAUSED = "PAUSED",
  TRIALING = "TRIALING",
  UNPAID = "UNPAID",
}

/** The tier of the subscription */
export enum SubscriptionTierEnum {
  BASIC = "BASIC",
  CUSTOM = "CUSTOM",
  STANDARD = "STANDARD",
}

export type SummaryReport = {
  __typename?: "SummaryReport"
  customerSupportDetail: CustomerSupportDetail
  salesByPaymentType: SalesByPaymentType
  salesSummaryDetail: SalesSummaryDetail
}

export type TableBasicModel = {
  __typename?: "TableBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type TableZoneDetailModel = {
  __typename?: "TableZoneDetailModel"
  isActive: Scalars["Boolean"]
  location: LocationBasicModel
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type TableZoneLocationModel = {
  __typename?: "TableZoneLocationModel"
  isActive: Scalars["Boolean"]
  locationTables?: Maybe<Array<BasicLocationTable>>
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type TableZoneModel = {
  __typename?: "TableZoneModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type Tags = {
  __typename?: "Tags"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type TaxBasicModel = {
  __typename?: "TaxBasicModel"
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TaxByItemDetailModel = {
  __typename?: "TaxByItemDetailModel"
  itemUUID: Scalars["ID"]
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TaxItemLocationModel = {
  __typename?: "TaxItemLocationModel"
  applyFutureLocations?: Maybe<Scalars["Boolean"]>
  description?: Maybe<Scalars["String"]>
  isGeneral?: Maybe<Scalars["Boolean"]>
  itemsWithLocations: Array<ItemWithLocationModel>
  name: Scalars["String"]
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TaxLocationModel = {
  __typename?: "TaxLocationModel"
  applyFutureLocations?: Maybe<Scalars["Boolean"]>
  description?: Maybe<Scalars["String"]>
  isGeneral?: Maybe<Scalars["Boolean"]>
  locations?: Maybe<Array<LocationBasicModel>>
  name: Scalars["String"]
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TaxModel = {
  __typename?: "TaxModel"
  applyFutureLocations?: Maybe<Scalars["Boolean"]>
  description?: Maybe<Scalars["String"]>
  isGeneral?: Maybe<Scalars["Boolean"]>
  name: Scalars["String"]
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TaxWithItemModel = {
  __typename?: "TaxWithItemModel"
  item: ItemBasicWithAttachmentModel
  location: ItemBasicModel
  uuid: Scalars["ID"]
}

export type TaxWithLocationModel = {
  __typename?: "TaxWithLocationModel"
  location: ItemBasicModel
  uuid: Scalars["ID"]
}

export type TaxWithLocationOrItemModel = {
  __typename?: "TaxWithLocationOrItemModel"
  applyFutureLocations?: Maybe<Scalars["Boolean"]>
  description?: Maybe<Scalars["String"]>
  isGeneral?: Maybe<Scalars["Boolean"]>
  itemTaxes: Array<TaxWithItemModel>
  locationTaxes: Array<TaxWithLocationModel>
  name: Scalars["String"]
  taxRate: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type TempAttachmentInput = {
  contentType: AttachmentContentTypeEnum
  ext: AttachmentExtensionEnum
  fileId?: InputMaybe<Scalars["String"]>
  fileName: Scalars["String"]
  type: AttachmentTypeEnum
}

export type TempAttachmentModel = {
  __typename?: "TempAttachmentModel"
  contentType: AttachmentContentTypeEnum
  ext: AttachmentExtensionEnum
  fileId?: Maybe<Scalars["String"]>
  fileName: Scalars["String"]
  signedUrl: Scalars["String"]
  type: Scalars["String"]
  uuid: Scalars["String"]
}

/** Possible times before an event */
export enum TimeBeforeEventEnum {
  _24H = "_24H",
  _48H = "_48H",
  _72H = "_72H",
}

export type TimeEntriesReport = {
  __typename?: "TimeEntriesReport"
  duration: Scalars["Float"]
  id: Scalars["ID"]
  laborCost: Scalars["Float"]
  name: Scalars["String"]
  payRate: Scalars["Float"]
  tip: Scalars["Float"]
}

export type TipSettingInput = {
  amounts: Array<Scalars["Float"]>
  defaultAmount: Scalars["Float"]
  requestTipAfter: Scalars["Boolean"]
  requestTipBefore: Scalars["Boolean"]
  valueType: TipValueTypeEnum
}

export type TipSettings = {
  __typename?: "TipSettings"
  amounts: Array<Scalars["Float"]>
  defaultAmount: Scalars["Float"]
  requestTipAfter?: Maybe<Scalars["Boolean"]>
  requestTipBefore?: Maybe<Scalars["Boolean"]>
  valueType: TipValueTypeEnum
}

/** Type of the value that a tip can be given */
export enum TipValueTypeEnum {
  AMOUNTS = "AMOUNTS",
  PERCENTAGE = "PERCENTAGE",
}

export type TotalCollects = {
  __typename?: "TotalCollects"
  amount: Scalars["Float"]
  paymentMethod: PaymentMethodTypeEnum
}

export type TotalSales = {
  __typename?: "TotalSales"
  amount: Scalars["Float"]
  netSales: NetSales
  returns: Scalars["Float"]
  taxes: Scalars["Float"]
  tips: Scalars["Float"]
}

export type UuidIsDefaultInput = {
  isDefault: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type UpdateAddressInput = {
  addressUUID: Scalars["ID"]
  apartment?: InputMaybe<Scalars["String"]>
  city: Scalars["String"]
  country: Scalars["String"]
  isDefault: Scalars["Boolean"]
  latitude: Scalars["String"]
  longitude: Scalars["String"]
  name: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  number: Scalars["String"]
  postalCode: Scalars["String"]
  restaurantUUID: Scalars["ID"]
  state: Scalars["String"]
  street: Scalars["String"]
  userUUID: Scalars["ID"]
}

export type UpdateAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  uuid: Scalars["ID"]
}

export type UpdateBankingAccountInput = {
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type UpdateComboAttachmentInput = {
  comboUUID: Scalars["ID"]
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  uuid: Scalars["ID"]
}

export type UpdateComboInput = {
  description?: InputMaybe<Scalars["String"]>
  discountPercentage?: InputMaybe<Scalars["Float"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  priceOverride?: InputMaybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type UpdateComboItemGroupInput = {
  name?: InputMaybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type UpdateCustomerInput = {
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  phone: Scalars["String"]
  restaurantUUID: Scalars["String"]
  userUUID: Scalars["ID"]
}

export type UpdateDeliveryIntegrationInOrderInput = {
  deliveryIntegration: RuuidInput
  order: RuuidInput
}

export type UpdateDeliveryIntegrationInput = {
  integrationLabel?: InputMaybe<Scalars["String"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  settings?: InputMaybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type UpdateDeliveryLocationInput = {
  isDefault: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type UpdateDeliveryPriceInput = {
  label?: InputMaybe<Scalars["String"]>
  location: RuuidInput
  maxDistance?: InputMaybe<Scalars["Int"]>
  price?: InputMaybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type UpdateDeviceInput = {
  code?: InputMaybe<Scalars["String"]>
  deviceKey?: InputMaybe<Scalars["String"]>
  functionType?: InputMaybe<DeviceFunctionEnum>
  isActive?: InputMaybe<Scalars["Boolean"]>
  locationUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type UpdateDiscountInput = {
  code?: InputMaybe<Scalars["String"]>
  entryMethod?: InputMaybe<DiscountsEntryMethodsEnum>
  fixedAmount?: InputMaybe<Scalars["Float"]>
  percentage?: InputMaybe<Scalars["Float"]>
  rule?: InputMaybe<UpdateDiscountRuleInput>
  uuid: Scalars["ID"]
}

export type UpdateDiscountRuleInput = {
  endDate?: InputMaybe<Scalars["String"]>
  generatedQuantity?: InputMaybe<Scalars["Int"]>
  limitPerCustomer?: InputMaybe<Scalars["Int"]>
  stackingAvailable: Scalars["Boolean"]
  startDate: Scalars["String"]
}

export type UpdateEmployeeInput = {
  allowTip?: InputMaybe<Scalars["Boolean"]>
  attachment?: InputMaybe<CreateAttachmentInput>
  code?: InputMaybe<Scalars["String"]>
  firstName?: InputMaybe<Scalars["String"]>
  lastName?: InputMaybe<Scalars["String"]>
  payRate?: InputMaybe<Scalars["Float"]>
  phone?: InputMaybe<Scalars["String"]>
  role?: InputMaybe<EmployeeRoleNameEnum>
  uuid: Scalars["ID"]
}

export type UpdateExternalOrderStatusInput = {
  order: RuuidInput
  status: OrderStatusEnum
}

export type UpdateFutureLocationsInTaxInput = {
  applyFutureLocations: Scalars["Boolean"]
  uuid: Scalars["ID"]
}

export type UpdateItemAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  itemUUID: Scalars["ID"]
  uuid: Scalars["ID"]
}

export type UpdateItemInOrderInput = {
  itemUUID: Scalars["ID"]
  notes?: InputMaybe<Scalars["String"]>
  orderDetailUUID: Scalars["ID"]
  quantity?: InputMaybe<Scalars["Int"]>
  variantUUID: Scalars["ID"]
}

export type UpdateItemStatusInput = {
  notes?: InputMaybe<Scalars["String"]>
  status: ItemStatusEnum
  uuid: Scalars["ID"]
}

export type UpdateLocationCateringRulesInput = {
  cancelPolicies?: InputMaybe<Array<CancelPolicyInput>>
  employees?: InputMaybe<Array<RuuidInput>>
  orderMethodAcceptance?: InputMaybe<OrderMethodAcceptanceEnum>
  orderScheduling?: InputMaybe<Scalars["Float"]>
  prePayment?: InputMaybe<PrePaymentInput>
  services?: InputMaybe<Array<ServicesInput>>
  uuid: Scalars["ID"]
}

export type UpdateLocationInput = {
  acceptCash?: InputMaybe<Scalars["Boolean"]>
  address?: InputMaybe<Scalars["String"]>
  attachment?: InputMaybe<UpdateAttachmentInput>
  countryCode?: InputMaybe<Scalars["String"]>
  estimatedPreparationTime?: InputMaybe<Scalars["Int"]>
  latitude?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["ID"]
  longitude?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  orderTypes?: InputMaybe<Array<OrderMethodEnum>>
  phone?: InputMaybe<Scalars["String"]>
  timezone?: InputMaybe<Scalars["String"]>
}

export type UpdateLocationScheduleInput = {
  description?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  scheduleUUID: Scalars["ID"]
  schedules: Array<ScheduleDetail>
}

export type UpdateLocationTableInput = {
  capacity?: InputMaybe<Scalars["Int"]>
  description?: InputMaybe<Scalars["String"]>
  locationUUID: Scalars["ID"]
  name?: InputMaybe<Scalars["String"]>
  qrPath?: InputMaybe<Scalars["String"]>
  smoking?: InputMaybe<Scalars["Boolean"]>
  status?: InputMaybe<StatusTableEnum>
  tableIdentifier?: InputMaybe<Scalars["String"]>
  tableZoneUUID?: InputMaybe<Scalars["ID"]>
  uuid: Scalars["ID"]
}

export type UpdateMemberInput = {
  email: Scalars["String"]
  firstName: Scalars["String"]
  inviteUUID: Scalars["ID"]
  lastName: Scalars["String"]
  locationUUIDs?: InputMaybe<Array<Scalars["ID"]>>
  restaurantUUID: Scalars["ID"]
  roleName: AdminRoleNameEnum
  userUUID?: InputMaybe<Scalars["ID"]>
}

export type UpdateMenuCombosInput = {
  comboUUID: Scalars["ID"]
  menusUUID: Array<Scalars["ID"]>
}

export type UpdateOrderInput = {
  locationUUID?: InputMaybe<Scalars["ID"]>
  notes?: InputMaybe<Scalars["String"]>
  orderMethod?: InputMaybe<OrderMethodEnum>
  status?: InputMaybe<OrderStatusEnum>
  tableUUID?: InputMaybe<Scalars["ID"]>
  uuid: Scalars["ID"]
}

export type UpdateOrderStatusInput = {
  orderUUID: Scalars["ID"]
  status: OrderStatusEnum
}

export type UpdateOrderThrottlingInput = {
  defaultIntervalTime?: InputMaybe<Scalars["Float"]>
  defaultOrdersLimit?: InputMaybe<Scalars["Float"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  rules?: InputMaybe<Array<CreateOrderThrottlingRuleInput>>
  uuid: Scalars["ID"]
}

export type UpdateRestaurantAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  type: AttachmentTypeEnum
  uuid: Scalars["ID"]
}

export type UpdateRestaurantInput = {
  isCateringActive?: InputMaybe<Scalars["Boolean"]>
  locationsQuantity?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  passFeeToCustomer?: InputMaybe<Scalars["Boolean"]>
  priceCategory?: InputMaybe<PriceCategoriesEnum>
  restaurantUUID: Scalars["ID"]
}

export type UpdateRestaurantScheduleInput = {
  description?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  restaurantUUID: Scalars["String"]
  scheduleUUID: Scalars["ID"]
  schedules: Array<ScheduleDetail>
}

export type UpdateTableZoneInput = {
  locationUUID: Scalars["ID"]
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type UpdateTaxInput = {
  description?: InputMaybe<Scalars["String"]>
  name?: InputMaybe<Scalars["String"]>
  taxRate?: InputMaybe<Scalars["Float"]>
  uuid: Scalars["String"]
}

export type UpdateVariantInput = {
  calories?: InputMaybe<Scalars["Int"]>
  isActive?: InputMaybe<Scalars["Boolean"]>
  isDefault?: InputMaybe<Scalars["Boolean"]>
  name?: InputMaybe<Scalars["String"]>
  price?: InputMaybe<Scalars["Float"]>
  sku?: InputMaybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type UpdateVariantModifierGroupInput = {
  fieldType?: InputMaybe<FieldTypeEnumItem>
  isRequired?: InputMaybe<Scalars["Boolean"]>
  itemOrder?: InputMaybe<Scalars["Int"]>
  maxSelectedOptions?: InputMaybe<Scalars["Int"]>
  minSelectedOptions?: InputMaybe<Scalars["Int"]>
  variantModifierGroupUUID: Scalars["ID"]
}

export type UpdateVariantModifierInput = {
  calories?: InputMaybe<Scalars["Int"]>
  isIncluded?: InputMaybe<Scalars["Boolean"]>
  itemOrder?: InputMaybe<Scalars["Int"]>
  price?: InputMaybe<Scalars["Float"]>
  variantModifierUUID: Scalars["ID"]
}

export type UpdatedComboModel = {
  __typename?: "UpdatedComboModel"
  description?: Maybe<Scalars["String"]>
  discountPercentage: Scalars["Float"]
  isActive: Scalars["Boolean"]
  name: Scalars["String"]
  price: Scalars["Float"]
  priceOverride: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type UpsertAttachmentInput = {
  contentType: ContentTypeEnum
  ext: FileExtensionEnum
  fileName: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type UpsertAttachmentVariantInput = {
  attachment: UpsertAttachmentInput
  variantUUID: Scalars["ID"]
}

export type UpsertOrderMethodSettingInput = {
  notificationSettings: NotificationSettingsInput
  orderMethod: OrderMethodEnum
  tipSettings: TipSettingInput
}

export type UpsertTimEntryInput = {
  employee: RuuidInput
  endDate: Scalars["String"]
  notes?: InputMaybe<Scalars["String"]>
  startDate: Scalars["String"]
  uuid?: InputMaybe<Scalars["ID"]>
}

export type UpsertedTimeEntry = {
  __typename?: "UpsertedTimeEntry"
  endDate?: Maybe<Scalars["DateTime"]>
  notes?: Maybe<Scalars["String"]>
  startDate: Scalars["DateTime"]
  uuid: Scalars["String"]
}

export type UserBasicModel = {
  __typename?: "UserBasicModel"
  firstName?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
  uuid: Scalars["String"]
}

export type UserFormattedPhoneModel = {
  __typename?: "UserFormattedPhoneModel"
  firstName?: Maybe<Scalars["String"]>
  formattedPhone?: Maybe<Scalars["String"]>
  lastName?: Maybe<Scalars["String"]>
  phone?: Maybe<Scalars["String"]>
  uuid: Scalars["String"]
}

export type VariantBasicModel = {
  __typename?: "VariantBasicModel"
  name: Scalars["String"]
  uuid: Scalars["ID"]
}

export type VariantDetailModel = {
  __typename?: "VariantDetailModel"
  name: Scalars["String"]
  orderVariantModifiers: Array<OrderVariantModifierModel>
  uuid: Scalars["ID"]
}

export type VariantModel = {
  __typename?: "VariantModel"
  attachment?: Maybe<AttachmentModel>
  calories?: Maybe<Scalars["Int"]>
  deletedAt?: Maybe<Scalars["DateTime"]>
  isActive: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  sku?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

export type VariantModifierGroupBasicModel = {
  __typename?: "VariantModifierGroupBasicModel"
  modifierGroup: ModifierGroupModel
  uuid: Scalars["ID"]
}

export type VariantModifierGroupInput = {
  modifierGroupUUID: Scalars["ID"]
  variantModifierUUIDs: Array<Scalars["ID"]>
}

export type VariantModifierGroupModel = {
  __typename?: "VariantModifierGroupModel"
  fieldType?: Maybe<FieldTypeEnumItem>
  isActive: Scalars["Boolean"]
  isRequired?: Maybe<Scalars["Boolean"]>
  itemOrder: Scalars["Int"]
  maxSelectedOptions?: Maybe<Scalars["Int"]>
  minSelectedOptions?: Maybe<Scalars["Int"]>
  modifierGroup: ModifierGroupBasicModel
  uuid: Scalars["ID"]
}

export type VariantModifierGroupWithVariantModifierModel = {
  __typename?: "VariantModifierGroupWithVariantModifierModel"
  fieldType?: Maybe<FieldTypeEnumItem>
  isActive: Scalars["Boolean"]
  isRequired?: Maybe<Scalars["Boolean"]>
  itemOrder: Scalars["Int"]
  maxSelectedOptions?: Maybe<Scalars["Int"]>
  minSelectedOptions?: Maybe<Scalars["Int"]>
  modifierGroup: ModifierGroupBasicModel
  uuid: Scalars["ID"]
  variantModifiers: Array<VariantModifierWithIngredientModel>
}

export type VariantModifierModel = {
  __typename?: "VariantModifierModel"
  calories: Scalars["Int"]
  isActive: Scalars["Boolean"]
  isIncluded: Scalars["Boolean"]
  itemOrder: Scalars["Int"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type VariantModifierToItemModel = {
  __typename?: "VariantModifierToItemModel"
  quantity: Scalars["Int"]
  unitPrice: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type VariantModifierWithIngredientModel = {
  __typename?: "VariantModifierWithIngredientModel"
  calories: Scalars["Int"]
  ingredient: IngredientModel
  isActive: Scalars["Boolean"]
  isIncluded: Scalars["Boolean"]
  itemOrder: Scalars["Int"]
  price: Scalars["Float"]
  uuid: Scalars["ID"]
}

export type VariantOptionModel = {
  __typename?: "VariantOptionModel"
  attachment?: Maybe<AttachmentModel>
  calories?: Maybe<Scalars["Int"]>
  deletedAt?: Maybe<Scalars["DateTime"]>
  isActive: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  sku?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
  variantModifierGroups: Array<VariantModifierGroupWithVariantModifierModel>
}

export type VariantWithAttachmentModel = {
  __typename?: "VariantWithAttachmentModel"
  attachment?: Maybe<AttachmentModel>
  extraPrice?: Maybe<Scalars["Float"]>
  isActive: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  uuid: Scalars["ID"]
}

export type VariantWithExtraPriceModel = {
  __typename?: "VariantWithExtraPriceModel"
  attachment?: Maybe<AttachmentModel>
  calories?: Maybe<Scalars["Int"]>
  deletedAt?: Maybe<Scalars["DateTime"]>
  extraPrice?: Maybe<Scalars["Float"]>
  isActive: Scalars["Boolean"]
  isDefault: Scalars["Boolean"]
  name: Scalars["String"]
  price?: Maybe<Scalars["Float"]>
  sku?: Maybe<Scalars["String"]>
  uuid: Scalars["ID"]
}

/** Days of the week */
export enum WeekDaysEnum {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}
