import { MantineCard } from "../../../../../ui/MantineCard"
import {
  Box,
  Card,
  Flex,
  Grid,
  Group,
  Skeleton,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconX } from "@tabler/icons-react"
import { useIntl } from "react-intl"

const SKELETON_WIDTH_SM = 250
const SKELETON_HEIGHT_SM = 35

export const CateringDetailSkeleton = () => {
  const mantineTheme = useMantineTheme()
  const intl = useIntl()

  return (
    <Stack spacing={24}>
      <Card shadow="sm" withBorder bg="gray.1">
        <Card.Section px={24} py={16} bg="white">
          <Group position="apart">
            <Flex gap={8}>
              <Text fw={"bold"}>
                {intl.formatMessage({
                  id: "restaurants.catering.detail.card.order.skeleton.order.detail",
                  defaultMessage: "Order detail",
                })}
              </Text>
              <Skeleton h={SKELETON_HEIGHT_SM / 2} w={SKELETON_WIDTH_SM / 2} />
            </Flex>
            <IconX size={32} color={mantineTheme.colors.gray[6]} />
          </Group>
        </Card.Section>
        <Box pt={16}>
          <Grid gutter={24}>
            <Grid.Col lg={8}>
              <Stack spacing={24}>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.order.info",
                    defaultMessage: "Order information",
                  })}
                >
                  <Stack spacing={24}>
                    <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                    <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                    <Group position="apart">
                      <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                      <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                    </Group>
                    <Skeleton h={SKELETON_HEIGHT_SM * 4} w="100%" />
                    <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                  </Stack>
                </MantineCard>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.contact.info",
                    defaultMessage: "Contact information",
                  })}
                >
                  <Group position="apart">
                    <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                    <Skeleton h={SKELETON_HEIGHT_SM} w={SKELETON_WIDTH_SM} />
                  </Group>
                </MantineCard>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.receipt.info",
                    defaultMessage: "Receipt",
                  })}
                >
                  <Skeleton h={SKELETON_HEIGHT_SM * 8} w={"100%"} />
                </MantineCard>
                <Skeleton h={400} w="100%" />
              </Stack>
            </Grid.Col>
            <Grid.Col lg={4}>
              <Stack spacing={24}>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.receipt.info",
                    defaultMessage: "Status",
                  })}
                >
                  <Skeleton h={SKELETON_HEIGHT_SM} w="100%" />
                </MantineCard>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.receipt.info",
                    defaultMessage: "Comments",
                  })}
                >
                  <Skeleton h={SKELETON_HEIGHT_SM * 3} w="100%" />
                </MantineCard>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.receipt.info",
                    defaultMessage: "Timeline",
                  })}
                >
                  <Skeleton h={SKELETON_HEIGHT_SM * 4} w="100%" />
                </MantineCard>
                <MantineCard
                  title={intl.formatMessage({
                    id: "restaurants.catering.detail.card.order.skeleton.receipt.info",
                    defaultMessage: "Danger Zone",
                  })}
                >
                  <Skeleton h={SKELETON_HEIGHT_SM * 3} w="100%" />
                </MantineCard>
              </Stack>
            </Grid.Col>
          </Grid>
        </Box>
      </Card>
    </Stack>
  )
}
