import { Collapsible } from "../../../../../../ui/Collapsible"
import { RulesMessage } from "../../shared/config"
import { CateringForm } from "../../shared/hookforms.intefaces"
import { List } from "@mantine/core"
import { useFormContext, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"

export const RefundPolicyForm = () => {
  const intl = useIntl()

  const { control } = useFormContext<CateringForm>()

  const [isActive] = useWatch({
    control,
    name: ["isActive"],
  })

  return (
    <Collapsible
      label={intl.formatMessage(RulesMessage.refundPolicy.title)}
      disabled={!isActive}
    >
      <List pl={10}>
        {RulesMessage.refundPolicy.list.map((item, index) => (
          <List.Item key={index}>{intl.formatMessage(item)}</List.Item>
        ))}
      </List>
    </Collapsible>
  )
}
