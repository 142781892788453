import { phoneMask } from "../../../../../../../../shared/utils/constant/phoneMask"
import { WebsiteTabsForm } from "../../../../hookform/hookforms.interface"
import { validationWebsiteErrors } from "../../../../hookform/website-validation-messages"
import styles from "./ContactInformation.module.css"
import { Box, Input, Stack, Text, TextInput } from "@mantine/core"
import { IconMail, IconMapPin, IconPhone } from "@tabler/icons-react"
import { Controller, useFormContext } from "react-hook-form"
import { IMaskInput } from "react-imask"
import { useIntl } from "react-intl"

type Props = {
  disabledFields?: boolean
}

export const ContactInformation = ({ disabledFields }: Props) => {
  const intl = useIntl()
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<WebsiteTabsForm>()

  const formErrors = {
    emailError: errors.settingsForm?.contactEmail?.message,
    phoneError: errors.settingsForm?.contactPhone?.message,
    addressError: errors.settingsForm?.contactAddress?.message,
    altAddressError: errors.settingsForm?.contactAddressAlt?.message,
  }

  return (
    <Stack spacing={0}>
      <Text fz={"md"} fw={"bold"}>
        {intl.formatMessage({
          defaultMessage: "2. Contact information",
          id: "restaurant.settings.website.settings.contact.information",
        })}
      </Text>
      <Box pl={18}>
        <Text mt={4}>
          {intl.formatMessage({
            defaultMessage:
              "Please fill in your corporate information so customers know how to contact you.",
            id: "restaurant.settings.website.settings.contact.information.subtitle",
          })}
        </Text>
        <Stack spacing={16} mt={16}>
          <TextInput
            {...register("settingsForm.contactEmail")}
            error={
              formErrors.emailError
                ? intl.formatMessage(
                    validationWebsiteErrors[formErrors.emailError]
                  )
                : undefined
            }
            disabled={disabledFields}
            withAsterisk
            classNames={{ label: styles["label"] }}
            icon={<IconMail size={"1rem"} />}
            label={intl.formatMessage({
              defaultMessage: "Email",
              id: "restaurant.settings.website.settings.contact.information.email",
            })}
          />
          <Input.Wrapper
            classNames={{
              label: styles["label"],
            }}
            error={
              formErrors.phoneError
                ? intl.formatMessage(
                    validationWebsiteErrors[formErrors.phoneError]
                  )
                : undefined
            }
            withAsterisk
            label={
              <Text component="span" size="sm" fw={600}>
                {intl.formatMessage({
                  id: "restaurant.settings.website.settings.contact.information.phone",
                  defaultMessage: "Phone number",
                })}
              </Text>
            }
          >
            <Controller
              name="settingsForm.contactPhone"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  error={formErrors.phoneError}
                  disabled={disabledFields}
                  icon={<IconPhone size={"1rem"} />}
                  component={IMaskInput}
                  mask={phoneMask.US.mask}
                />
              )}
            />
          </Input.Wrapper>
          <TextInput
            {...register("settingsForm.contactAddress")}
            error={
              formErrors.addressError
                ? intl.formatMessage(
                    validationWebsiteErrors[formErrors.addressError]
                  )
                : undefined
            }
            disabled={disabledFields}
            withAsterisk
            classNames={{ label: styles["label"] }}
            icon={<IconMapPin size={"1rem"} />}
            label={intl.formatMessage({
              defaultMessage: "Address Line 1",
              id: "restaurant.settings.website.settings.contact.information.address.one",
            })}
          />
          <TextInput
            {...register("settingsForm.contactAddressAlt")}
            error={
              formErrors.altAddressError
                ? intl.formatMessage(
                    validationWebsiteErrors[formErrors.altAddressError]
                  )
                : undefined
            }
            disabled={disabledFields}
            withAsterisk
            classNames={{ label: styles["label"] }}
            icon={<IconMapPin size={"1rem"} />}
            label={intl.formatMessage({
              defaultMessage: "Address Line 2",
              id: "restaurant.settings.website.settings.contact.information.address.two",
            })}
          />
        </Stack>
      </Box>
    </Stack>
  )
}
