import { WebsiteTabsForm } from "../../../hookform/hookforms.interface"
import { WebsiteContentLayout } from "../WebsiteContentLayout"
import { ContactInformation } from "./ContactInformation"
import { DomainSetup } from "./DomainSetup"
import { SocialMedia } from "./SocialMedia"
import { Flex, Stack, Switch, Title } from "@mantine/core"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

export const SettingsContent = () => {
  const intl = useIntl()
  const { control, watch, clearErrors } = useFormContext<WebsiteTabsForm>()
  const disabledFields = !watch("settingsForm.enabled")
  return (
    <WebsiteContentLayout>
      <Stack spacing={32}>
        <Flex justify={"space-between"} align={"center"}>
          <Title order={4}>
            {intl.formatMessage({
              defaultMessage: "Website service",
              id: "restaurant.settings.website.settings.title",
            })}
          </Title>
          <Controller
            control={control}
            name="settingsForm.enabled"
            render={({ field: { value, onChange } }) => (
              <Switch
                size="md"
                checked={value}
                onChange={(val) => {
                  onChange(val)
                  if (value === true) {
                    clearErrors()
                  }
                }}
              />
            )}
          />
        </Flex>
        <Stack spacing={32}>
          <DomainSetup disabledFields={disabledFields} />
          <ContactInformation disabledFields={disabledFields} />
          <SocialMedia disabledFields={disabledFields} />
        </Stack>
      </Stack>
    </WebsiteContentLayout>
  )
}
