import { IconCatering } from "../../components/IconCatering"
import {
  IconBuildingStore,
  IconCategory,
  IconHeadset,
  IconHome,
  IconSettings,
} from "@tabler/icons-react"
import { ReactNode } from "react"

const DEFAULT_ICON_SIZE = 12

export const WebsiteDefaultTabsIds = {
  SETTINGS: "website.tab.settings",
  HOME: "website.tab.home",
  MENU: "website.tab.menu",
  LOCATIONS: "website.tab.locations",
  CATERING: "website.tab.catering",
  CONTACT_US: "website.tab.contact.us",
  ADD: "website.tab.add",
} as const

export type WebsiteDefaultTabIdType =
  typeof WebsiteDefaultTabsIds[keyof typeof WebsiteDefaultTabsIds]

export type WebsiteDefaultTabTitleType = {
  tabId: WebsiteDefaultTabIdType
  name: string
  icon?: ReactNode
}

export const WebsiteDefaultTabsTitles: WebsiteDefaultTabTitleType[] = [
  {
    tabId: WebsiteDefaultTabsIds.SETTINGS,
    name: "",
    icon: <IconSettings size={DEFAULT_ICON_SIZE} />,
  },
  {
    tabId: WebsiteDefaultTabsIds.HOME,
    name: "Home",
    icon: <IconHome size={DEFAULT_ICON_SIZE} />,
  },
  {
    tabId: WebsiteDefaultTabsIds.MENU,
    name: "Menu",
    icon: <IconCategory size={DEFAULT_ICON_SIZE} />,
  },
  {
    tabId: WebsiteDefaultTabsIds.LOCATIONS,
    name: "Locations",
    icon: <IconBuildingStore size={DEFAULT_ICON_SIZE} />,
  },
  {
    tabId: WebsiteDefaultTabsIds.CATERING,
    name: "Catering",
    icon: <IconCatering />,
  },
  {
    tabId: WebsiteDefaultTabsIds.CONTACT_US,
    name: "Contact Us",
    icon: <IconHeadset size={DEFAULT_ICON_SIZE} />,
  },
]
