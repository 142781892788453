interface WebsiteTabObject {
  tabId: string
  name: string
}

export interface WebsiteTabsForm {
  tabs: WebsiteTabObject[]
  currentTab: string | null
  settingsForm?: WebsiteSettingsForm
  homeForm?: HomeForm
}
export interface HomeForm {
  imageURL?: string
  title: string
  subtitle?: string
}
export enum WebsiteDomainTypeEnum {
  SUB_DOMAIN,
  OWN_DOMAIN,
}
export interface WebsiteCustomDomain {
  uuid: string
  url: string
}
export interface WebsiteSettingsForm {
  enabled: boolean
  domainType: WebsiteDomainTypeEnum
  crmbDomainURL?: string
  customDomainURL?: string
  contactEmail: string
  contactPhone: string
  contactAddress: string
  contactAddressAlt: string
  facebook?: string
  instagram?: string
  tiktok?: string
  socialX?: string
}
