import { OrderStatus } from "../../../../components/OrderStatus"
import { PaymentStatus } from "../../../../components/PaymentStatus"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { OrderStatusEnum } from "../../../../shared/graphql/generated/types"
import { DateQueryParam } from "../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import paths from "../../../../shared/routes/paths"
import {
  YYYY_MM_DD,
  hh_mm_A,
  isoStringDateFormat,
} from "../../../../shared/utils/constant/dateFormats"
import { toFormat } from "../../../../shared/utils/currency/toFormat.dinero"
import { cateringOrderMethodMapping } from "../../../../shared/utils/helpers/formatToOrderMethod"
import { MantineTable } from "../../../../ui/MantineTable"
import {
  StatusFilterEnum,
  statusFilterParam,
} from "../CateringHeader/CateringHeader"
import { CateringTableSkeleton } from "../CateringTableSkeleton"
import {
  GetCateringOrdersByRestaurantQuery,
  useGetCateringOrdersByRestaurantQuery,
} from "../Graphql/getCateringOrdersByRestaurant.generated"
import { OrderMethodEnum } from "../types"
import { tableHeaders } from "../utils/table-headers"
import { Box, Group, Text } from "@mantine/core"
import moment from "moment-timezone"
import { useCallback } from "react"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"

export const CateringTable = () => {
  const intl = useIntl()
  const { push } = useHistory()
  const { getParam } = useSearchParams()

  const { locationUUID } = useParams<{ locationUUID: string }>()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const today = moment().format(YYYY_MM_DD)

  const startDateParam = getParam(DateQueryParam.start) ?? today
  const endDateParam = getParam(DateQueryParam.end) ?? today
  const statusFilter = getParam(statusFilterParam) ?? ""

  const { data, fetchMore, loading } = useGetCateringOrdersByRestaurantQuery({
    variables: {
      restaurantUUID: currentRestaurantUUID,
      startDate: moment(startDateParam)
        .set("hour", 0)
        .set("minute", 0)
        .toISOString(false),
      endDate: moment(endDateParam)
        .set("hour", 23)
        .set("minute", 59)
        .toISOString(false),
      orderMethods: [OrderMethodEnum.CATERING],
      ...(!!locationUUID && {
        locationUUIDs: [locationUUID],
      }),
      ...(statusFilter === StatusFilterEnum.ON_WAIT && {
        status: [OrderStatusEnum.PLACED, OrderStatusEnum.IN_REVISION],
      }),
    },
    skip: !currentRestaurantUUID || !startDateParam || !endDateParam,
    fetchPolicy: "cache-and-network",
  })

  const ordersList = data?.getOrdersByRestaurant.results ?? []
  const hasNextPage = !!data?.getOrdersByRestaurant.hasNextPage
  const endCursor = data?.getOrdersByRestaurant.endCursor ?? undefined

  const fetchMoreOrders = useCallback(async () => {
    await fetchMore({
      variables: { currentRestaurantUUID, after: endCursor, take: 20 },
      updateQuery: (
        prev: GetCateringOrdersByRestaurantQuery,
        { fetchMoreResult }
      ) => {
        if (
          !fetchMoreResult ||
          prev?.getOrdersByRestaurant?.endCursor ===
            fetchMoreResult?.getOrdersByRestaurant?.endCursor
        ) {
          return prev
        }
        return Object.assign({}, prev, {
          getOrdersByRestaurant: {
            ...fetchMoreResult.getOrdersByRestaurant,
            results: [
              ...(prev.getOrdersByRestaurant.results ?? []),
              ...(fetchMoreResult.getOrdersByRestaurant.results ?? []),
            ],
          },
        })
      },
    })
  }, [currentRestaurantUUID, endCursor, fetchMore])

  if (ordersList.length === 0 && loading) {
    return (
      <Box px={24} pb={24}>
        <CateringTableSkeleton headers={tableHeaders} />
      </Box>
    )
  }

  return (
    <Box px={24} pb={24}>
      <MantineTable
        headers={tableHeaders}
        fetchMore={fetchMoreOrders}
        hasNextPage={hasNextPage}
        dataLength={ordersList.length}
      >
        {ordersList?.map((item, index) => (
          <tr
            key={index}
            onClick={() => push(paths.restaurants.cateringDetail(item.uuid))}
          >
            <td>#{item.orderNumber}</td>
            <td>
              {item.catering && item.catering.serviceType && (
                <Text>
                  {intl.formatMessage(
                    cateringOrderMethodMapping[item.catering.serviceType]
                  )}
                </Text>
              )}
            </td>
            <td>
              <OrderStatus status={item.status} />
            </td>
            <td>
              <PaymentStatus status={item.paymentStatus} />
            </td>
            <td>
              <Text>
                {item.customer.user.firstName} {item.customer.user.lastName}
              </Text>
            </td>
            <td>
              {item.catering &&
                item.catering.placedAt &&
                moment(item.catering.placedAt).format("L")}
            </td>
            <td>
              <Group spacing={8}>
                <Text>
                  {item.deliveryDate
                    ? moment(item.deliveryDate).format(isoStringDateFormat)
                    : ""}
                </Text>
                <Text>
                  {item.deliveryDate
                    ? moment(item.deliveryDate).format(hh_mm_A)
                    : ""}
                </Text>
              </Group>
            </td>
            <td>
              <Text ta={"end"}>{item.catering?.headCount}</Text>
            </td>
            <td>
              <Text ta={"end"}>{toFormat(item.total)}</Text>
            </td>
          </tr>
        ))}
      </MantineTable>
    </Box>
  )
}
