interface Mask {
  [key: string]: {
    mask: string
  }
}
export const phoneMask: Mask = {
  US: {
    mask: "(000) 000-0000",
  },
}
