import { WebsiteTabsForm } from "../hookform/hookforms.interface"
import styles from "./WebsiteTabs.module.css"
import { HomeContent } from "./components/HomeContent"
import { SettingsContent } from "./components/SettingsContent"
import {
  WebsiteDefaultTabsIds,
  WebsiteDefaultTabsTitles,
} from "./utils/DefaultTabs"
import { ActionIcon, Tabs, TextInput } from "@mantine/core"
import { uuid4 } from "@sentry/utils"
import { IconPlus, IconX } from "@tabler/icons-react"
import { debounce } from "lodash"
import { useFieldArray, useFormContext } from "react-hook-form"

export const WebsiteTabs = () => {
  const { control, watch, setValue } = useFormContext<WebsiteTabsForm>()

  const { append, remove, update } = useFieldArray({
    control,
    name: "tabs",
  })

  const tabs = watch("tabs")

  const currentTab = watch("currentTab")

  const handleTabTextChange = debounce(
    (value: string, tabId: string, tabIdx: number) => {
      update(tabIdx, { name: value, tabId })
    },
    300
  )

  const handleTabChange = (tabId: string) => {
    tabId !== currentTab && setValue("currentTab", tabId)
  }

  const handleTabRemove = (index: number) => {
    remove(index)
  }
  const handleAddTab = () => {
    const newTabId = uuid4()
    append({ name: "", tabId: newTabId })
    setValue("currentTab", newTabId)
  }

  return (
    <Tabs value={currentTab}>
      <Tabs.List grow>
        {WebsiteDefaultTabsTitles.map((tab) => (
          <Tabs.Tab
            h={38}
            fz={12}
            key={tab.tabId}
            value={tab.tabId}
            icon={tab.icon}
            maw={tab.name ? undefined : 36}
            onClick={() => handleTabChange(tab.tabId)}
          >
            {tab.name}
          </Tabs.Tab>
        ))}
        {tabs.map((tab, idx) => (
          <Tabs.Tab
            fz={12}
            h={38}
            className={styles["tab-label"]}
            key={tab.tabId}
            value={tab.tabId}
            onClick={() => handleTabChange(tab.tabId)}
          >
            <TextInput
              autoFocus={currentTab === tab.tabId}
              variant="unstyled"
              ta={"center"}
              classNames={{ input: styles["tab-input"] }}
              size="xs"
              rightSection={
                tab.name && (
                  <ActionIcon
                    size={"xs"}
                    variant="outline"
                    aria-label="Remove"
                    color="primary"
                    onClick={() => {
                      handleTabRemove(idx)
                      setValue("currentTab", WebsiteDefaultTabsIds.HOME)
                    }}
                  >
                    <IconX size={12} />
                  </ActionIcon>
                )
              }
              onChange={(event) => {
                handleTabTextChange(event.target.value, tab.tabId, idx)
              }}
            ></TextInput>
          </Tabs.Tab>
        ))}
        <Tabs.Tab
          fz={12}
          h={38}
          maw={36}
          onClick={handleAddTab}
          value={WebsiteDefaultTabsIds.ADD}
          icon={<IconPlus size={12} />}
        />
      </Tabs.List>
      <Tabs.Panel value={WebsiteDefaultTabsIds.SETTINGS} py="xl">
        <SettingsContent />
      </Tabs.Panel>
      <Tabs.Panel value={WebsiteDefaultTabsIds.HOME} py="xl">
        <HomeContent />
      </Tabs.Panel>
    </Tabs>
  )
}
