import safeNumber from "../../../../../../shared/utils/helpers/safeNumber"
import { Collapsible } from "../../../../../../ui/Collapsible"
import {
  cateringPolicyBeforeHour,
  getOrderCateringPolicies,
} from "../../../utils/format-catering-order"
import { RuleChip } from "../../components/RuleChip"
import { RulesMessage } from "../../shared/config"
import type {
  CancelPoliciesType,
  CateringForm,
} from "../../shared/hookforms.intefaces"
import { TimeBeforeEventEnum } from "../../shared/hookforms.intefaces"
import { validationCateringTitle } from "../../shared/validation-catering.title"
import { hasUndefinedProperties } from "../../utils/cancel-policy-method"
import styles from "./CancelPolicyForm.module.css"
import {
  ActionIcon,
  Checkbox,
  Flex,
  Group,
  List,
  NumberInput,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { IconPercentage, IconPlus, IconX } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
  useWatch,
} from "react-hook-form"
import { FormattedMessage, useIntl } from "react-intl"

const times: TimeBeforeEventEnum[] = [
  TimeBeforeEventEnum._72H,
  TimeBeforeEventEnum._48H,
  TimeBeforeEventEnum._24H,
]

interface CancelationPolicyFormProps {
  fields: FieldArrayWithId<
    CateringForm,
    "rules.cancelPolicy.cancelPolicies",
    "id"
  >[]
  append: UseFieldArrayAppend<CateringForm, "rules.cancelPolicy.cancelPolicies">
  remove: UseFieldArrayRemove
  disableValue: string | null
  setDisableValue: (value: string | null) => void
}

export const CancelationPolicyForm = ({
  fields,
  append,
  remove,
  disableValue,
  setDisableValue,
}: CancelationPolicyFormProps) => {
  const intl = useIntl()
  const mantineTheme = useMantineTheme()

  const {
    control,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<CateringForm>()
  const [isActive, cancelPolicy] = useWatch({
    control,
    name: ["isActive", "rules.cancelPolicy"],
  })

  const [timesBeforeData, setTimesBeforeData] = useState<
    { value: string; label: string }[]
  >([])

  const ruleChipDataCateringPolicy = (item: CancelPoliciesType) => {
    if (!item.percentage || !item.timeBeforeEvent) return []

    return [
      intl.formatNumber(safeNumber(item.percentage) / 100, {
        style: "percent",
      }),
      intl.formatMessage(
        {
          id: `restaurants.settings.location.catering.rules.cancelation.policy.${
            cateringPolicyBeforeHour[item.timeBeforeEvent]
          }.hours.before.event.chip.label`,
          defaultMessage: "{hoursEvent} hours before the event",
        },
        {
          hoursEvent: cateringPolicyBeforeHour[item.timeBeforeEvent],
        }
      ),
    ]
  }

  const cancelPolicyObject: CancelPoliciesType = {
    percentage: undefined,
    timeBeforeEvent: undefined,
  }

  useEffect(() => {
    if (!times) return

    const updatedTimesBeforeData = getOrderCateringPolicies(times).map(
      (time) => ({
        value: time.value,
        label: intl.formatMessage({
          id: time.label.id,
          defaultMessage: time.label.defaultMessage,
        }),
        disabled:
          disableValue === time.value ||
          cancelPolicy.cancelPolicies.some(
            (item) => item.timeBeforeEvent === time.value
          ),
      })
    )

    setTimesBeforeData(updatedTimesBeforeData)
  }, [intl, cancelPolicy.cancelPolicies, disableValue])

  const hasErrors = errors.rules?.cancelPolicy

  return (
    <Collapsible
      error={Boolean(hasErrors)}
      label={intl.formatMessage(RulesMessage.cancelationPolicy.title)}
      rightSection={
        <Group noWrap>
          {hasUndefinedProperties(cancelPolicy.cancelPolicies) ? (
            <RuleChip
              data={[
                intl.formatMessage({
                  id: "restaurants.settings.location.catering.rules.cancelation.policy.chip.label",
                  defaultMessage: "No charge",
                }),
              ]}
              disabled={!isActive}
            />
          ) : (
            cancelPolicy.cancelPolicies.map((item, index) => (
              <RuleChip
                key={index}
                data={ruleChipDataCateringPolicy(item)}
                disabled={!isActive}
              />
            ))
          )}
        </Group>
      }
      disabled={!isActive}
    >
      <Group w="100%" grow>
        <Stack spacing={16}>
          <List>
            {RulesMessage.cancelationPolicy.list.map((item) => (
              <List.Item key={item.id}>
                <Text size="sm">{intl.formatMessage(item)}</Text>
              </List.Item>
            ))}
          </List>
          <Controller
            name="rules.cancelPolicy.isApply"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={cancelPolicy.isApply}
                onChange={(value) => {
                  field.onChange(value.target.checked)
                  resetField("rules.cancelPolicy.cancelPolicies", {
                    defaultValue: [
                      {
                        percentage: undefined,
                        timeBeforeEnum: undefined,
                      },
                    ],
                  })
                  setValue("rules.cancelPolicy.cancelPolicies", [
                    {
                      percentage: undefined,
                      timeBeforeEvent: undefined,
                    },
                  ])
                  setDisableValue(null)
                }}
                label={intl.formatMessage(
                  RulesMessage.cancelationPolicy.checkbox
                )}
              />
            )}
          />
          <Stack spacing={2}>
            <Text size="sm">
              <FormattedMessage
                id="restaurants.settings.location.catering.rules.cancelation.policy.input.top.label"
                defaultMessage=" Specify the refundable percentage of the deposit amount based on
                    the time before the event in case of cancellation."
              />
            </Text>
            <Text size="sm" c="gray.6">
              <FormattedMessage
                id="restaurants.settings.location.catering.rules.cancelation.policy.input.bottom.label"
                defaultMessage="Create additional rules in case you need it."
              />
            </Text>
            <Flex gap={8} justify="space-between">
              <Stack w="100%">
                {fields.map((item, index) => (
                  <Flex
                    gap={8}
                    w="100%"
                    key={item.id}
                    className={styles["container-cancel-policy-option"]}
                  >
                    <Controller
                      control={control}
                      name={`rules.cancelPolicy.cancelPolicies.${index}.percentage`}
                      render={({ field, fieldState: { error } }) => (
                        <NumberInput
                          disabled={!cancelPolicy.isApply}
                          w="100%"
                          type="number"
                          precision={0}
                          maxLength={3}
                          max={100}
                          error={
                            error?.message
                              ? intl.formatMessage(
                                  validationCateringTitle[error?.message]
                                )
                              : undefined
                          }
                          {...field}
                          rightSection={
                            <IconPercentage
                              size={16}
                              color={mantineTheme.colors.gray[6]}
                            />
                          }
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`rules.cancelPolicy.cancelPolicies.${index}.timeBeforeEvent`}
                      render={({ field, fieldState: { error } }) => (
                        <Select
                          {...field}
                          data={timesBeforeData}
                          placeholder="Select Time"
                          disabled={!cancelPolicy.isApply}
                          w="100%"
                          error={
                            error?.message
                              ? intl.formatMessage(
                                  validationCateringTitle[error?.message]
                                )
                              : undefined
                          }
                          onChange={(value) => {
                            field.onChange(value)
                            setDisableValue(value)
                          }}
                          allowDeselect={false}
                        />
                      )}
                    />
                    {index === 0 ? (
                      <ActionIcon
                        disabled={!cancelPolicy.isApply || fields.length >= 3}
                        onClick={() => append(cancelPolicyObject)}
                      >
                        <IconPlus
                          size={20}
                          color={mantineTheme.colors.violet[6]}
                        />
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        disabled={!cancelPolicy.isApply || fields.length <= 1}
                        onClick={() => {
                          remove(index)
                          setDisableValue(null)
                        }}
                        className={styles["remove-cancel-policy-button"]}
                      >
                        <IconX
                          size={20}
                          color={mantineTheme.colors.violet[6]}
                        />
                      </ActionIcon>
                    )}
                  </Flex>
                ))}
              </Stack>
            </Flex>
          </Stack>
        </Stack>
      </Group>
    </Collapsible>
  )
}
