import {
  WebsiteDomainTypeEnum,
  WebsiteTabsForm,
} from "../../../../hookform/hookforms.interface"
import { validationWebsiteErrors } from "../../../../hookform/website-validation-messages"
import styles from "./DomainSetup.module.css"
import {
  Button,
  Flex,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
} from "@mantine/core"
import { Controller, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

const DOMAIN_PLACEHOLDER = "crm.app/byteburgers"
const OWN_DOMAIN_PLACEHOLDER = "www.yourowndomain.com"
type Props = {
  disabledFields?: boolean
}
export const DomainSetup = ({ disabledFields }: Props) => {
  const intl = useIntl()
  const {
    control,
    register,
    watch,
    formState: { errors },
    resetField,
  } = useFormContext<WebsiteTabsForm>()

  const customURLError = errors.settingsForm?.customDomainURL?.message
  const crmbDomainURLError = errors.settingsForm?.crmbDomainURL?.message

  const domainType = watch("settingsForm.domainType")
  const customURL = watch("settingsForm.customDomainURL")

  const ownDomainType = domainType == WebsiteDomainTypeEnum.OWN_DOMAIN

  const DOMAIN_OPTIONS = [
    {
      label: intl.formatMessage({
        defaultMessage: "Use a CRMB subdomain",
        id: "restaurant.settings.website.settings.set.up.custom.domain",
      }),
      value: WebsiteDomainTypeEnum.SUB_DOMAIN.toString(),
    },
    {
      label: intl.formatMessage({
        defaultMessage: "Connect a domain your own",
        id: "restaurant.settings.website.settings.set.up.own.domain",
      }),
      value: WebsiteDomainTypeEnum.OWN_DOMAIN.toString(),
    },
  ]
  return (
    <Stack spacing={24}>
      <Text fz={"md"} fw={"bold"}>
        {intl.formatMessage({
          defaultMessage: "1. Set up your domain",
          id: "restaurant.settings.website.settings.domain.set.up",
        })}
      </Text>
      <Stack pl={18} spacing={24}>
        <Controller
          control={control}
          render={({ field: { value, onChange } }) => (
            <SegmentedControl
              disabled={disabledFields}
              classNames={{ label: styles["segmented-control-label"] }}
              maw={408}
              value={value ? value.toString() : undefined}
              data={DOMAIN_OPTIONS}
              onChange={(val) => {
                onChange(val)
                resetField("settingsForm.customDomainURL")
                resetField("settingsForm.crmbDomainURL")
              }}
            />
          )}
          name={"settingsForm.domainType"}
        />

        <Stack>
          {ownDomainType ? (
            <Flex gap={8}>
              <TextInput
                {...register("settingsForm.customDomainURL")}
                disabled={disabledFields}
                w={"100%"}
                error={
                  customURLError
                    ? intl.formatMessage(
                        validationWebsiteErrors[customURLError]
                      )
                    : undefined
                }
                placeholder={OWN_DOMAIN_PLACEHOLDER}
                label={
                  <Text c={"gray.6"} mb={4}>
                    {intl.formatMessage({
                      defaultMessage:
                        "Connect a domain purchased through a website hosting service",
                      id: "restaurant.settings.website.settings.domain.set.up.url.own.label",
                    })}
                  </Text>
                }
              ></TextInput>
              <Button mt={22} disabled={!customURL || !!customURLError}>
                {intl.formatMessage({
                  defaultMessage: "Add",
                  id: "restaurant.settings.website.settings.domain.set.up.url.add.custom",
                })}
              </Button>
            </Flex>
          ) : (
            <TextInput
              {...register("settingsForm.crmbDomainURL")}
              disabled={disabledFields}
              error={
                crmbDomainURLError
                  ? intl.formatMessage(
                      validationWebsiteErrors[crmbDomainURLError]
                    )
                  : undefined
              }
              placeholder={DOMAIN_PLACEHOLDER}
              label={
                <Text c={"gray.6"} mb={4}>
                  {intl.formatMessage({
                    defaultMessage:
                      "Instantly connect a CRMB subdomain for free",
                    id: "restaurant.settings.website.settings.domain.set.up.url.label",
                  })}
                </Text>
              }
            ></TextInput>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
