import { MantineTable } from "../../../../ui/MantineTable"
import styles from "./CateringTableSkeleton.module.css"
import { Skeleton, Stack, SystemProp } from "@mantine/core"
import { CSSProperties } from "react"

type Props = {
  headers: {
    title: JSX.Element
    alignment?: SystemProp<CSSProperties["textAlign"]>
  }[]
}
export const CateringTableSkeleton = ({ headers }: Props) => {
  return (
    <Stack>
      <MantineTable
        headers={headers}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        fetchMore={() => {}}
        hasNextPage={false}
        dataLength={1}
      >
        <td height={200}>
          <Stack className={styles["stack-style"]}>
            <Skeleton w="100%" h={40} />
            <Skeleton w="100%" h={40} />
            <Skeleton w="100%" h={40} />
          </Stack>
        </td>
      </MantineTable>
    </Stack>
  )
}
