import paths from "../../../../../shared/routes/paths"
import type { AllowedRoles } from "../../../../RoleView/RoleView"

const {
  settings: { restaurant },
} = paths
const allowOwnerAndAdmin: AllowedRoles[] = [
  "RESTAURANT_OWNER",
  "RESTAURANT_ADMIN",
]

export const restaurantItems = [
  {
    path: restaurant.restaurantInfo,
    title: "Restaurant Info",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.members,
    title: "Members",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.loyaltyProgram,
    title: "Loyalty",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.delivery,
    title: "Delivery",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.banking,
    title: "Banking",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.catering,
    title: "Catering",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.taxes,
    title: "Taxes",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.plans,
    title: "Plans",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.mobileApp,
    title: "Mobile App",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.website,
    title: "Website",
    allowedRoles: allowOwnerAndAdmin,
  },
  {
    path: restaurant.qrCode,
    title: "QR Sticker",
    allowedRoles: allowOwnerAndAdmin,
    hidden: true,
  },
]
