import { fileToDataUri } from "../../../../../../../shared/utils/helpers/fileHelpers"
import { Collapsible } from "../../../../../../../ui/Collapsible"
import { WebsiteTabsForm } from "../../../hookform/hookforms.interface"
import { validationWebsiteErrors } from "../../../hookform/website-validation-messages"
import { WebsiteContentLayout } from "../WebsiteContentLayout"
import { SectionTooltip } from "./SectionTooltip"
import {
  Button,
  FileButton,
  Flex,
  Image,
  Stack,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core"
import { IconInfoCircle, IconUpload } from "@tabler/icons-react"
import { MouseEvent } from "react"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

const TOOLTIP_OPEN_DELAY = 300
const FILE_ACCEPTANCE = "image/png,image/jpeg"
export const HomeContent = () => {
  const intl = useIntl()

  const {
    setValue,
    formState: { errors },
    register,
    watch,
  } = useFormContext<WebsiteTabsForm>()

  const image = watch("homeForm.imageURL")

  const onImageUpload = async (file: File) => {
    const imageURL = await fileToDataUri(file)
    setValue("homeForm.imageURL", imageURL)
  }
  return (
    <WebsiteContentLayout>
      <Stack spacing={32}>
        <Collapsible
          fullWidth
          label={
            <Flex align={"center"} gap={4}>
              <Text size={"sm"} fw={600}>
                {intl.formatMessage({
                  id: "restaurant.settings.website.settings.home.hero.header",
                  defaultMessage: "Hero Header",
                })}
              </Text>
              <Tooltip
                p={8}
                position="right"
                color="gray.6"
                withArrow
                withinPortal
                radius={4}
                openDelay={TOOLTIP_OPEN_DELAY}
                label={<SectionTooltip />}
              >
                <IconInfoCircle size={14} />
              </Tooltip>
            </Flex>
          }
        >
          <Stack
            spacing={24}
            w={"100%"}
            onClick={(e: MouseEvent<HTMLDivElement>) => e?.stopPropagation()}
          >
            <FileButton
              onChange={(file) => {
                file && onImageUpload(file)
              }}
              accept={FILE_ACCEPTANCE}
            >
              {(props) =>
                image ? (
                  <Image
                    height={147}
                    miw={"100%"}
                    fit="cover"
                    radius={"sm"}
                    src={image}
                    {...props}
                  />
                ) : (
                  <Button h={147} miw={"100%"} color="gray.2" {...props}>
                    <Flex gap={4} align={"center"}>
                      <IconUpload
                        size={12}
                        color="var(--mantine-color-gray-5)"
                      />
                      <Text c={"gray.5"} fz={14} fw={"normal"}>
                        {intl.formatMessage({
                          id: "restaurant.settings.website.settings.home.hero.header.image.button.text",
                          defaultMessage: "Upload image",
                        })}
                      </Text>
                    </Flex>
                  </Button>
                )
              }
            </FileButton>
            <TextInput
              {...register("homeForm.title")}
              onKeyUp={(e) => e.preventDefault()}
              withAsterisk
              labelProps={{ mb: 4 }}
              error={
                errors.homeForm?.title?.message
                  ? intl.formatMessage(
                      validationWebsiteErrors[errors.homeForm?.title?.message]
                    )
                  : undefined
              }
              label={
                <Text fz={"sm"} fw={600} component="span">
                  {intl.formatMessage({
                    id: "restaurant.settings.website.settings.home.hero.header.title",
                    defaultMessage: "Title",
                  })}
                </Text>
              }
            />
            <TextInput
              {...register("homeForm.subtitle")}
              onKeyUp={(e) => e.preventDefault()}
              labelProps={{ mb: 4 }}
              label={
                <Text fz={"sm"} fw={600}>
                  {intl.formatMessage({
                    id: "restaurant.settings.website.settings.home.hero.header.subtitle",
                    defaultMessage: "Subtitle",
                  })}
                </Text>
              }
            />
          </Stack>
        </Collapsible>
      </Stack>
    </WebsiteContentLayout>
  )
}
