import { phoneMask } from "../../../../../shared/utils/constant/phoneMask"
import formatPhoneNumber from "../../../../../shared/utils/helpers/formatPhoneNumber"
import type { EmployeeFormType } from "../types/form.types"
import { employeesRolesData } from "../utils/employees.roles"
import { DropzoneInput } from "./DropzoneInput"
import styles from "./EmployeeForm.module.css"
import {
  Box,
  Grid,
  Image,
  Input,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { FileWithPath } from "@mantine/dropzone"
import { IconCurrencyDollar } from "@tabler/icons-react"
import { useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { IMaskInput } from "react-imask"
import { useIntl } from "react-intl"

interface EmployeeFormProps {
  onFile: (file: File) => void
  employeeImageUrl?: string
}

export const EmployeeForm = ({
  onFile,
  employeeImageUrl,
}: EmployeeFormProps) => {
  const intl = useIntl()

  const mantineTheme = useMantineTheme()

  const [file, setFile] = useState<FileWithPath | null>(null)
  const {
    control,
    formState: { errors },
  } = useFormContext<EmployeeFormType>()

  return (
    <Stack spacing={16} className={styles["stack-content"]}>
      <Controller
        name="attachment"
        control={control}
        render={({ field }) =>
          file || employeeImageUrl ? (
            <Box pos="relative" w={130} h={130}>
              <Box className={styles["dropzone-content"]}>
                <Image
                  width={130}
                  height={130}
                  src={file ? URL.createObjectURL(file) : employeeImageUrl}
                />
                <Box className={styles["dropzone"]}>
                  <DropzoneInput
                    onFile={onFile}
                    setFile={setFile}
                    field={field}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <DropzoneInput onFile={onFile} setFile={setFile} field={field} />
          )
        }
      />

      <Grid>
        <Grid.Col span={6}>
          <Input.Wrapper
            classNames={{
              label: styles["input-wrapper-label"],
            }}
            label={
              <Text size="sm" fw={600}>
                {intl.formatMessage({
                  id: "locations.employees.new.form.input.first.name",
                  defaultMessage: "First name",
                })}
              </Text>
            }
            required
            error={errors.firstName?.message}
          >
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <Input value={field.value} onChange={field.onChange} />
              )}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={6}>
          <Input.Wrapper
            classNames={{
              label: styles["input-wrapper-label"],
            }}
            label={
              <Text size="sm" fw={600}>
                {intl.formatMessage({
                  id: "locations.employees.new.form.input.last.name",
                  defaultMessage: "Last name",
                })}
              </Text>
            }
            required
            error={errors.lastName?.message}
          >
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <Input value={field.value} onChange={field.onChange} />
              )}
            />
          </Input.Wrapper>
        </Grid.Col>
      </Grid>
      <Controller
        name="role"
        control={control}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            label={
              <Text size="sm" fw={600}>
                {intl.formatMessage({
                  id: "locations.employees.new.form.input.role",
                  defaultMessage: "Role",
                })}
              </Text>
            }
            error={errors.role?.message}
            data={employeesRolesData}
          />
        )}
      />
      <Input.Wrapper
        classNames={{
          label: styles["input-wrapper-label"],
        }}
        label={
          <Text size="sm" fw={600}>
            {intl.formatMessage({
              id: "locations.employees.new.form.input.phone.number",
              defaultMessage: "Phone number",
            })}
          </Text>
        }
        error={errors.phone?.message}
        required
      >
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <Input
              type="tel"
              value={formatPhoneNumber(field.value)}
              onChange={field.onChange}
              component={IMaskInput}
              mask={phoneMask.US.mask}
            />
          )}
        />
      </Input.Wrapper>
      <Input.Wrapper
        classNames={{
          label: styles["input-wrapper-label"],
        }}
        label={
          <Text size="sm" fw={600}>
            {intl.formatMessage({
              id: "locations.employees.new.form.input.id.code",
              defaultMessage: "ID code",
            })}
          </Text>
        }
        error={errors.code?.message}
        required
      >
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <NumberInput
              hideControls
              maxLength={6}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {!errors.code?.message && (
          <Text mt={5} c="gray.6" size="xs">
            {intl.formatMessage({
              id: "locations.employees.new.form.input.id.code.description",
              defaultMessage: "Codes must be numeric",
            })}
          </Text>
        )}
      </Input.Wrapper>
      <Input.Wrapper
        classNames={{
          label: styles["input-wrapper-label"],
        }}
        label={
          <Text size="sm" fw={600}>
            {intl.formatMessage({
              id: "locations.employees.new.form.input.pay.rate",
              defaultMessage: "Pay rate",
            })}
          </Text>
        }
        error={errors.payRate?.message}
        required
      >
        <Controller
          name="payRate"
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              icon={
                <IconCurrencyDollar
                  size={16}
                  color={mantineTheme.colors.gray[5]}
                />
              }
            />
          )}
        />
      </Input.Wrapper>
      <Controller
        name="allowTips"
        control={control}
        render={({ field }) => (
          <Switch
            checked={field.value}
            onChange={field.onChange}
            labelPosition="left"
            label={intl.formatMessage({
              id: "locations.employees.new.form.switch.allow.tips",
              defaultMessage: "Eligible for tips",
            })}
          />
        )}
      />
    </Stack>
  )
}
