import { GetAllLocationsByUserDocument } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetSingleLocationQuery } from "../../../../GraphQL/Queries/getSingleLocation.generated"
import type { IAddress } from "../../../../components/AutocompleteAddress"
import AutocompleteAddress from "../../../../components/AutocompleteAddress"
import LocationMap from "../../../../components/LocationMap/LocationMap"
import { GoogleMapsProvider } from "../../../../shared/contexts/GoogleMapsProvider"
import { OrderMethodEnum } from "../../../../shared/graphql/generated/types"
import paths from "../../../../shared/routes/paths"
import { validationsTitle } from "../../../../shared/titles/validations.title"
import { CustomOrderMethodTitle } from "../../../../shared/utils/constant/orderMethod.title"
import { EMPTY_STRING } from "../../../../shared/utils/constant/values"
import formatPhoneNumber from "../../../../shared/utils/helpers/formatPhoneNumber"
import { parsePhoneNumber } from "../../../../shared/utils/helpers/parsePhoneNumber"
import Button from "../../../../ui/Button"
import { CheckboxGroup } from "../../../../ui/Checkbox"
import Container from "../../../../ui/Container"
import { Icon } from "../../../../ui/Icon/Icon"
import InputLabel from "../../../../ui/InputLabel"
import Input from "../../../../ui/Inputs"
import DetailPage from "../../../../ui/Layouts/MainLayout/DetailPage"
import PageTitle from "../../../../ui/PageTitle"
import RoleView from "../../../../ui/RoleView"
import Select from "../../../../ui/Select"
import Spacer from "../../../../ui/Spacer"
import { Switch } from "../../../../ui/Switch/Switch"
import { Tooltip } from "../../../../ui/Tooltip/Tooltip"
import HighlightedText from "../../../../ui/Typography/HighlightedText"
import Text from "../../../../ui/Typography/Text"
import notification from "../../../../ui/notification"
import DeleteLocation from "../DeleteLocation"
import { useUpdateLocationMutation } from "../GraphQL/updateLocation.generated"
import type { ILocationsParams } from "../hookforms.interfaces"
import { preparationTimeOptions } from "../utils/constants"
import { isLocationActive } from "../utils/is-location-active"
import { LocationInfoSkeleton } from "./LocationInfo.skeleton"
import { LocationInfoResolver } from "./LocationInfo.yup"
import OrderMethodModal from "./OrderMethodModal"
import type { ILocationInfoForm } from "./hookforms.interfaces"
import { useFlags } from "launchdarkly-react-client-sdk"
import get from "lodash/get"
import React, { useState } from "react"
import { Controller, useForm, useWatch } from "react-hook-form"
import { useIntl } from "react-intl"
import { useHistory, useParams } from "react-router-dom"
import styled from "styled-components"

const Form = ({
  error,
  refetch,
  defaultValues,
}: {
  error?: { message: string }
  refetch: () => void
  defaultValues: ILocationInfoForm
}) => {
  const { locationUUID } = useParams<ILocationsParams>()
  const intl = useIntl()
  const { push } = useHistory()
  const { mapWithPin, selectPrepTimeEnable } = useFlags()

  const formMethods = useForm<ILocationInfoForm>({
    mode: "all",
    defaultValues,
    resolver: LocationInfoResolver,
  })

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    trigger,
    getFieldState,
    formState,
  } = formMethods

  const { errors } = formState
  const phoneState = getFieldState("phone", formState)
  const [selectedOrderTypes, isActive, latitude, longitude] = useWatch({
    name: ["orderTypes", "isActive", "latitude", "longitude"],
    control,
  })

  const [modalOrderMethod, setModalOrderMethod] =
    useState<OrderMethodEnum | null>(null)

  const openOrderMethodModal = (type: OrderMethodEnum) => {
    setModalOrderMethod(type)
  }

  const handleClose = () => {
    setModalOrderMethod(null)
  }

  const goToDeliverySettings = () => {
    push(paths.settings.locations.deliveryByLocation(locationUUID))
  }

  const deliveryTypeExtra = (orderTypeItem: OrderMethodEnum) =>
    selectedOrderTypes?.includes(orderTypeItem) &&
    orderTypeItem === "DELIVERY" && (
      <>
        {
          //TODO: Replace with LinkButton
        }
        <HighlightedText cursor="pointer" onClick={goToDeliverySettings}>
          {intl.formatMessage({
            id: "restaurants.location.form.add.delivery.integration",
            defaultMessage: "Add delivery integration",
          })}
        </HighlightedText>
      </>
    )

  const orderTypeList = (
    Object.keys(OrderMethodEnum) as Array<OrderMethodEnum>
  ).map((orderTypeItem) => ({
    id: orderTypeItem,
    value: orderTypeItem,
    label: intl.formatMessage(CustomOrderMethodTitle[orderTypeItem]),
    extra: deliveryTypeExtra(orderTypeItem),
    className: "capitalized-option",
    settingsIcon: (
      <Icon
        cursor="pointer"
        remixiconClass="ri-settings-4-line"
        size={24}
        color="Primary5"
        onClick={() => openOrderMethodModal(orderTypeItem)}
      />
    ),
  }))

  const orderTypeListFiltered = orderTypeList.filter((type) => {
    return !(type.id === OrderMethodEnum.CATERING)
  })

  const [updateLocation, { loading: saving }] = useUpdateLocationMutation({
    refetchQueries: [GetAllLocationsByUserDocument],
  })

  const onAddressChange = (data: IAddress) => {
    setValue("address", data.address || "")
    setValue("latitude", data.latitude)
    setValue("longitude", data.longitude)
    setValue("addressComponents", data.addressComponents)
  }

  const validateAddress = () => {
    trigger(["address", "addressComponents"])
  }

  const saveLocation = async (formData: ILocationInfoForm) => {
    try {
      await updateLocation({
        variables: {
          data: {
            locationUUID,
            name: formData.name,
            address: formData.address,
            acceptCash: formData.acceptCash,
            latitude: formData.latitude,
            longitude: formData.longitude,
            orderTypes: formData.orderTypes,
            ...(phoneState.isDirty &&
              formData.phone && {
                phone: parsePhoneNumber(formData.phone).number,
              }),
            ...(formData.estimatedPreparationTime && {
              estimatedPreparationTime: formData.estimatedPreparationTime,
            }),
          },
        },
      })

      reset(undefined, { keepValues: true })

      notification({
        description: intl.formatMessage({
          id: "restaurants.locations.overview.notification.success.label",
          defaultMessage: "Your location was updated",
        }),
        type: "success",
      })
    } catch {
      if (phoneState.isDirty) {
        notification({
          type: "error",
          description: intl.formatMessage({
            id: "restaurants.locations.overview.notification.error.phone.label",
            defaultMessage:
              "Something went wrong while saving the location. Your phone number is probably already in use. Please try with another one.",
          }),
        })

        return
      }

      notification({
        type: "error",
        description: intl.formatMessage({
          id: "restaurants.locations.overview.notification.error.label",
          defaultMessage:
            "An error occurred while saving the location. Please try again.",
        }),
      })
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    handleSubmit(saveLocation)()
  }

  const retry = () => refetch()

  const setLatitude = (lat: number) =>
    setValue("latitude", `${lat}`, { shouldTouch: true })

  const setLongitude = (lng: number) =>
    setValue("longitude", `${lng}`, { shouldTouch: true })

  return (
    <DetailPage
      role="content-layout"
      isActive={isActive}
      message={intl.formatMessage({
        id: "restaurants.locations.deactivated.title",
        defaultMessage: "This location is not available.",
      })}
      error={error}
      reload={retry}
    >
      <PageTitle
        title={intl.formatMessage({
          id: "restaurants.locations.overview.head.label",
          defaultMessage: "Location Info",
        })}
        description={intl.formatMessage({
          id: "restaurants.locations.overview.edit.description.label",
          defaultMessage: "Manage your Location settings",
        })}
      />
      <StyledContentLocation readonly={!isActive}>
        <form
          className="location-edit-form"
          role="form"
          aria-label="location-edit-form"
          onSubmit={onSubmit}
        >
          <InputLabel
            label={intl.formatMessage({
              id: "restaurants.locations.overview.order.type.label",
              defaultMessage: "Location Order Type",
            })}
            requirement="required"
          />
          <Container display="flex" gap="8px" flexDirection="column">
            <Text size="s" color="Neutral5">
              {intl.formatMessage({
                id: "restaurants.locations.overview.order.type.description",
                defaultMessage:
                  "Select the type of orders that this location can support",
              })}
            </Text>
            <Controller
              control={control}
              name="orderTypes"
              render={({ field: locationsField }) => (
                <CheckboxGroup
                  {...locationsField}
                  classId="order-type-checkbox-group"
                  flexFlow="column"
                  aria-label="order-type"
                  options={orderTypeListFiltered}
                  hasError={!!errors.orderTypes}
                  helperText={get(errors, "orderTypes.message")}
                  disabled={!isActive}
                />
              )}
            />
            <Spacer size={8} />
            {selectPrepTimeEnable && (
              <Controller
                name="estimatedPreparationTime"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={intl.formatMessage({
                      id: "restaurants.locations.estimated.prep.time.label",
                      defaultMessage: "Estimated preparation time in minutes",
                    })}
                    options={preparationTimeOptions}
                    defaultValue={10}
                    hasError={!!errors.estimatedPreparationTime}
                    helperText={errors.estimatedPreparationTime?.message}
                    aria-label="preparation-time"
                  />
                )}
              />
            )}
          </Container>

          <Spacer size={68} />
          <Container>
            <StyledCashText>
              <Text>
                {intl.formatMessage({
                  id: "restaurants.locations.overview.name.label",
                  defaultMessage: "Accepts Cash",
                })}
              </Text>
              <Tooltip
                placement="topLeft"
                title={intl.formatMessage({
                  id: "restaurants.menu.items.forms.variants.dropdown.remove.tooltip",
                  defaultMessage:
                    "Only for Dine In and Pick Up orders through the Kiosk",
                })}
              >
                <Icon
                  classes="close__icon"
                  remixiconClass="ri-information-line"
                  display="flex"
                  alignItems="center"
                  color="Primary6"
                />
              </Tooltip>
            </StyledCashText>
            <Controller
              control={control}
              name="acceptCash"
              render={({ field: { value, onChange } }) => (
                <Switch size="default" onChange={onChange} checked={value} />
              )}
            />
          </Container>

          <Spacer size={68} />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                requirement="required"
                label={intl.formatMessage({
                  id: "restaurants.locations.overview.name.label",
                  defaultMessage: "Location Name",
                })}
                hasError={!!errors.name}
                helperText={errors.name?.message}
                aria-label="name"
                capitalizeFirstLetter={false}
              />
            )}
          />

          <Spacer size={24} />

          <GoogleMapsProvider>
            {mapWithPin && (
              <>
                <LocationMap
                  position={{ lat: latitude, lng: longitude }}
                  coordinatesSetters={{ setLatitude, setLongitude }}
                  validateAddress={() => trigger("address")}
                />
                <Spacer size={16} />
              </>
            )}
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <AutocompleteAddress
                  name="address"
                  inputProps={{
                    ...field,
                    name: "address",
                    label: intl.formatMessage({
                      id: "restaurants.locations.overview.address.label",
                      defaultMessage: "Location Address",
                    }),
                    requirement: "required",
                    placeholder: intl.formatMessage({
                      id: "restaurants.location.form.address.placeholder",
                      defaultMessage: "Location Address",
                    }),
                    hasError:
                      !!errors.address ||
                      !!errors.latitude ||
                      !!errors.longitude,
                    helperText:
                      errors.address?.message ??
                      errors.latitude?.message ??
                      errors.longitude?.message,
                  }}
                  validateAddress={validateAddress}
                  error={errors.address}
                  onSelect={onAddressChange}
                />
              )}
            />
          </GoogleMapsProvider>

          <Spacer size={24} />

          <Controller
            control={control}
            name="phone"
            render={({ field: phoneNumberField }) => (
              <Input
                {...phoneNumberField}
                label={intl.formatMessage({
                  id: "restaurants.locations.overview.phone.number.label",
                  defaultMessage: "Location Phone Number",
                })}
                requirement="required"
                aria-label="phone-number"
                value={phoneNumberField.value}
                hasError={!!errors?.phone}
                helperText={
                  errors?.phone?.message
                    ? intl.formatMessage(validationsTitle[errors.phone.message])
                    : EMPTY_STRING
                }
                prefix="+"
                onlyInteger
              />
            )}
          />

          <StyledContentButtonEdit>
            <Button
              type="submit"
              aria-label="save"
              title={intl.formatMessage({
                id: "restaurants.locations.overview.edit.button.save",
                defaultMessage: "Save Information",
              })}
              loading={saving}
              hidden={!isActive}
            />
          </StyledContentButtonEdit>
        </form>
      </StyledContentLocation>
      <Spacer size={64} />
      {!!isActive && (
        <RoleView allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}>
          <DeleteLocation
            locationUUID={locationUUID}
            locationName={getValues("name")}
          />
        </RoleView>
      )}
      {modalOrderMethod && (
        <OrderMethodModal
          key={modalOrderMethod}
          opened={!!modalOrderMethod}
          onClose={handleClose}
          type={modalOrderMethod}
        />
      )}
    </DetailPage>
  )
}

export const LocationInfo = () => {
  const { locationUUID } = useParams<ILocationsParams>()

  const {
    data: response,
    loading,
    error,
    refetch,
  } = useGetSingleLocationQuery({
    variables: { locationUUID },
    skip: !locationUUID,
    fetchPolicy: "network-only",
  })

  const data = response?.getSingleLocation

  if (loading || !data) {
    return <LocationInfoSkeleton />
  }

  const locationData = {
    name: data.name,
    address: data.address,
    uuid: data.uuid,
    acceptCash: data.acceptCash,
    latitude: data.latitude ?? undefined,
    longitude: data.longitude ?? undefined,
    timezone: data.timezone ?? undefined,
    orderTypes: data.orderTypes,
    isActive: isLocationActive(data),
    phone: formatPhoneNumber(data.phone?.replace("+", "")) ?? undefined,
    estimatedPreparationTime: data.estimatedPreparationTime ?? undefined,
  }

  return (
    <Form
      defaultValues={locationData}
      refetch={refetch}
      {...(error?.message && { message: error?.message })}
    />
  )
}

const StyledContentLocation = styled(Container)`
  margin-top: 48px;
`

const StyledContentButtonEdit = styled.div`
  margin-top: 64px;
`

const StyledCashText = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`
