import { WebsiteTabsForm } from "../../../../hookform/hookforms.interface"
import { validationWebsiteErrors } from "../../../../hookform/website-validation-messages"
import styles from "./SocialMedia.module.css"
import { Stack, Text, TextInput } from "@mantine/core"
import { useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"

type Props = {
  disabledFields?: boolean
}
export const SocialMedia = ({ disabledFields }: Props) => {
  const intl = useIntl()
  const {
    register,
    formState: { errors },
  } = useFormContext<WebsiteTabsForm>()

  const formErrors = {
    facebook: errors.settingsForm?.facebook?.message,
    instagram: errors.settingsForm?.instagram?.message,
    tiktok: errors.settingsForm?.tiktok?.message,
    socialX: errors.settingsForm?.socialX?.message,
  }
  return (
    <Stack spacing={16}>
      <Text fz={"md"} fw={"bold"}>
        {intl.formatMessage({
          defaultMessage: "3. Social media",
          id: "restaurant.settings.website.settings.social.media",
        })}
      </Text>
      <Stack pl={18} spacing={16}>
        <TextInput
          {...register("settingsForm.facebook")}
          error={
            formErrors.facebook
              ? intl.formatMessage(validationWebsiteErrors[formErrors.facebook])
              : undefined
          }
          disabled={disabledFields}
          classNames={{ label: styles["label"] }}
          label={intl.formatMessage({
            defaultMessage: "Facebook",
            id: "restaurant.settings.website.settings.social.media.facebook",
          })}
        />
        <TextInput
          {...register("settingsForm.instagram")}
          error={
            formErrors.instagram
              ? intl.formatMessage(
                  validationWebsiteErrors[formErrors.instagram]
                )
              : undefined
          }
          disabled={disabledFields}
          classNames={{ label: styles["label"] }}
          label={intl.formatMessage({
            defaultMessage: "Instagram",
            id: "restaurant.settings.website.settings.social.media.instagram",
          })}
        />
        <TextInput
          {...register("settingsForm.tiktok")}
          error={
            formErrors.tiktok
              ? intl.formatMessage(validationWebsiteErrors[formErrors.tiktok])
              : undefined
          }
          disabled={disabledFields}
          classNames={{ label: styles["label"] }}
          label={intl.formatMessage({
            defaultMessage: "Tiktok",
            id: "restaurant.settings.website.settings.social.media.tiktok",
          })}
        />
        <TextInput
          {...register("settingsForm.socialX")}
          error={
            formErrors.socialX
              ? intl.formatMessage(validationWebsiteErrors[formErrors.socialX])
              : undefined
          }
          disabled={disabledFields}
          classNames={{ label: styles["label"] }}
          label={intl.formatMessage({
            defaultMessage: "X",
            id: "restaurant.settings.website.settings.social.media.x",
          })}
        />
      </Stack>
    </Stack>
  )
}
