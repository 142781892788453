import { Catering } from "../../../pages/Settings/Locations/Catering"
import AddLocationButton from "../../../pages/Settings/Locations/CreateLocation/AddLocationButton"
import AddLocationModalFull from "../../../pages/Settings/Locations/CreateLocation/AddLocationModalFull"
import Devices from "../../../pages/Settings/Locations/Devices"
import AddDeviceButton from "../../../pages/Settings/Locations/Devices/AddDeviceButton"
import Employees from "../../../pages/Settings/Locations/Employees"
import HoursOfOperation from "../../../pages/Settings/Locations/HoursOfOperation"
import Integrations from "../../../pages/Settings/Locations/Integrations"
import CreateSquareIntegration from "../../../pages/Settings/Locations/Integrations/SquareDetail/CreateSquareIntegration"
import EditSquareIntegration from "../../../pages/Settings/Locations/Integrations/SquareDetail/EditSquareIntegration"
import { SquareOAuth } from "../../../pages/Settings/Locations/Integrations/SquareOAuth"
import LocationBanking from "../../../pages/Settings/Locations/LocationBanking"
import LocationDelivery from "../../../pages/Settings/Locations/LocationDelivery"
import LocationInfo from "../../../pages/Settings/Locations/LocationInfo"
import OrderThrottling from "../../../pages/Settings/Locations/OrderThrottling"
import Tables from "../../../pages/Settings/Locations/Tables"
import AddTableButton from "../../../pages/Settings/Locations/Tables/AddTableButton"
import CustomizeQRStyle from "../../../pages/Settings/Locations/Tables/CustomizeQRStyle"
import BankAccounts from "../../../pages/Settings/Restaurant/BankAccounts"
import AddBankAccountButton from "../../../pages/Settings/Restaurant/BankAccounts/AddBankAccountButton"
import { CateringCorporate } from "../../../pages/Settings/Restaurant/CateringCorporate"
import DeliveryIntegrations from "../../../pages/Settings/Restaurant/DeliveryIntegration"
import AddDeliveryIntegrationButton from "../../../pages/Settings/Restaurant/DeliveryIntegration/AddDeliveryIntegrationButton"
import EditRestaurant from "../../../pages/Settings/Restaurant/Home/EditRestaurant"
import { Square } from "../../../pages/Settings/Restaurant/Integrations/Square/Square"
import LoyaltyProgram from "../../../pages/Settings/Restaurant/LoyaltyProgram"
import Members from "../../../pages/Settings/Restaurant/Members"
import AddMemberButton from "../../../pages/Settings/Restaurant/Members/AddMemberButton"
import Plans from "../../../pages/Settings/Restaurant/Plans"
import Taxes from "../../../pages/Settings/Restaurant/Taxes"
import AddTaxButton from "../../../pages/Settings/Restaurant/Taxes/AddTaxButton"
import CreateItemTax from "../../../pages/Settings/Restaurant/Taxes/CreateItemTax"
import CreateTax from "../../../pages/Settings/Restaurant/Taxes/CreateTax"
import EditTax from "../../../pages/Settings/Restaurant/Taxes/EditTax"
import Website from "../../../pages/Settings/Restaurant/Website"
import WhiteLabelApplication from "../../../pages/Settings/Restaurant/WhiteLabelApplication"
import { withHeader } from "../../../ui/Header"
import SettingsLayout from "../../../ui/Layouts/SettingsLayout"
import AppSwitch from "../AppSwitch"
import paths from "../paths"
import React from "react"
import { useIntl } from "react-intl"
import { Route } from "react-router"

const { settings } = paths

export const SettingsRoutes: React.FC = () => {
  const intl = useIntl()

  return (
    <SettingsLayout>
      <AppSwitch>
        <Route
          exact
          path={settings.locations.create}
          component={AddLocationModalFull}
        />
        <Route
          exact
          path={settings.locations.locationInfo()}
          component={withHeader(<LocationInfo />, {
            title: intl.formatMessage({
              id: "components.layouts.settings.bar.locations.edit.location.header",
              defaultMessage: "Location Settings",
            }),
          })}
        />
        <Route
          exact
          path={settings.locations.hoursOfOperation()}
          component={HoursOfOperation}
        />
        <Route
          exact
          path={settings.locations.orderThrottling()}
          component={OrderThrottling}
        />
        <Route
          exact
          path={settings.locations.deliveryByLocation()}
          component={withHeader(<LocationDelivery />, {
            title: intl.formatMessage({
              id: "settings.locations.delivery.header",
              defaultMessage: "Delivery Settings",
            }),
          })}
        />
        <Route
          exact
          path={settings.locations.bankingByLocation()}
          component={withHeader(<LocationBanking />, {
            title: intl.formatMessage({
              id: "components.layouts.settings.bar.locations.banking.header",
              defaultMessage: "Banking Settings",
            }),
          })}
        />
        <Route
          exact
          path={settings.locations.employeesByLocation()}
          component={() => <Employees />}
        />
        <Route
          exact
          path={settings.locations.tablesByLocation()}
          component={withHeader(<Tables />, {
            title: intl.formatMessage({
              id: "restaurants.location.tables.title",
              defaultMessage: "Tables",
            }),
            actions: AddTableButton,
          })}
        />
        <Route
          exact
          path={settings.locations.devicesByLocation()}
          component={withHeader(<Devices />, {
            title: intl.formatMessage({
              id: "restaurants.devices.header.title",
              defaultMessage: "Devices",
            }),
            actions: AddDeviceButton,
          })}
        />
        <Route
          exact
          path={settings.locations.integrationsByLocation()}
          component={Integrations}
        />
        <Route
          exact
          path={settings.restaurant.integrations.squareOAuth}
          component={SquareOAuth}
        />
        <Route
          exact
          path={settings.locations.createSquareIntegrationByLocation()}
          component={CreateSquareIntegration}
        />
        <Route
          exact
          path={settings.locations.editSquareIntegrationByLocation()}
          component={EditSquareIntegration}
        />
        <Route
          exact
          path={settings.locations.cateringByLocation()}
          component={Catering}
        />

        <Route
          exact
          path={settings.root}
          component={withHeader(<EditRestaurant />, {
            title: intl.formatMessage({
              id: "restaurants.home.restaurant.info.title",
              defaultMessage: "Restaurant Info",
            }),
            actions: AddLocationButton,
          })}
        />

        <Route
          exact
          path={settings.restaurant.edit()}
          component={EditRestaurant}
        />
        <Route
          exact
          path={settings.restaurant.restaurantInfo}
          component={withHeader(<EditRestaurant />, {
            title: intl.formatMessage({
              id: "restaurants.home.restaurant.info.title",
              defaultMessage: "Restaurant Info",
            }),
            actions: AddLocationButton,
          })}
        />
        <Route
          exact
          path={settings.restaurant.members}
          component={withHeader(<Members />, {
            title: intl.formatMessage({
              id: "settings.restaurant.members.page.header",
              defaultMessage: "Members",
            }),
            actions: AddMemberButton,
          })}
        />
        <Route
          exact
          path={settings.restaurant.loyaltyProgram}
          component={LoyaltyProgram}
        />
        <Route
          exact
          path={settings.restaurant.delivery}
          component={withHeader(<DeliveryIntegrations />, {
            title: intl.formatMessage({
              id: "components.layouts.settings.bar.restaurant.delivery.header",
              defaultMessage: "Delivery Integrations",
            }),
            actions: AddDeliveryIntegrationButton,
          })}
        />
        <Route
          exact
          path={settings.restaurant.banking}
          component={withHeader(<BankAccounts />, {
            title: intl.formatMessage({
              id: "restaurants.bank.accounts.header.title",
              defaultMessage: "Banking",
            }),
            actions: AddBankAccountButton,
          })}
        />

        <Route
          exact
          path={settings.restaurant.taxes}
          component={withHeader(<Taxes />, {
            title: intl.formatMessage({
              id: "restaurants.taxes.title",
              defaultMessage: "Taxes",
            }),
            actions: AddTaxButton,
          })}
        />

        <Route
          exact
          path={settings.restaurant.plans}
          component={withHeader(<Plans />, {
            title: intl.formatMessage({
              id: "settings.restaurant.plans.title",
              defaultMessage: "Plans",
            }),
          })}
        />

        <Route
          exact
          path={settings.restaurant.mobileApp}
          component={WhiteLabelApplication}
        />
        <Route exact path={settings.restaurant.website} component={Website} />
        <Route
          exact
          path={settings.restaurant.catering}
          component={CateringCorporate}
        />

        <Route
          exact
          path={settings.restaurant.integrations.square}
          component={withHeader(<Square />, {
            title: intl.formatMessage({
              id: "restaurants.integrations.square.title",
              defaultMessage: "Integrations",
            }),
          })}
        />
        <AppSwitch>
          <Route
            exact
            path={settings.restaurant.createSalesTax}
            component={CreateTax}
          />
          <Route
            exact
            path={settings.restaurant.createItemTax}
            component={CreateItemTax}
          />
          <Route
            exact
            path={settings.restaurant.editSalesTax()}
            component={EditTax}
          />
          <Route
            exact
            path={settings.restaurant.editItemTax()}
            component={EditTax}
          />
          <Route
            exact
            path={settings.restaurant.qrCode}
            component={CustomizeQRStyle}
          />
        </AppSwitch>
      </AppSwitch>
    </SettingsLayout>
  )
}
