import { IntlFormattersType } from "../../../../../shared/types"

export const EMAIL_VALID_ID =
  "restaurant.settings.website.email.error.description"
export const PHONE_VALID_ID =
  "restaurant.settings.website.phone.error.description"
export const URL_VALID_ID = "restaurant.settings.website.url.error.description"
export const REQUIRED_ID =
  "restaurant.settings.website.field.required.description"

export const validationWebsiteErrors: IntlFormattersType = {
  [EMAIL_VALID_ID]: {
    id: EMAIL_VALID_ID,
    defaultMessage: "This email is not valid.",
  },
  [URL_VALID_ID]: {
    id: URL_VALID_ID,
    defaultMessage: "This URL is not valid.",
  },
  [PHONE_VALID_ID]: {
    id: PHONE_VALID_ID,
    defaultMessage: "This phone number is not valid.",
  },
  [REQUIRED_ID]: {
    id: REQUIRED_ID,
    defaultMessage: "This field is required.",
  },
}
