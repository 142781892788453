import { CalendarPickerModal } from "../../../../components/Modals/CalendarPickerModal"
import { dateRanges } from "../../../../components/Modals/CalendarPickerModal/utils/date-ranges.util"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import {
  DateQueryParam,
  useDateRange,
} from "../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../shared/hooks/useSearchParams"
import { YYYY_MM_DD } from "../../../../shared/utils/constant/dateFormats"
import { DateRangeEnum } from "../../../../shared/v2/utils/dateRange.enum"
import { useGetCateringOrdersByRestaurantQuery } from "../Graphql/getCateringOrdersByRestaurant.generated"
import { OrderMethodEnum, OrderStatusEnum } from "../types"
import { segmentedOptionsTime } from "../utils/time-options"
import { viewModeType } from "../utils/view-mode"
import styles from "./CateringHeader.module.css"
import { Button, Flex, Group, SegmentedControl, Text } from "@mantine/core"
import { IconCalendarEvent } from "@tabler/icons-react"
import moment from "moment-timezone"
import { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"

const viewModeParam = "view-mode"
export const statusFilterParam = "status"

export const StatusFilterEnum = {
  ON_WAIT: "ON_WAIT",
}

const onWaitStartDate = moment("05/01/2024")
const GET_ORDERS_QTY = 20

export const CateringHeader = () => {
  const intl = useIntl()

  const {
    state: {
      currentRestaurant: { uuid: currentRestaurantUUID },
    },
  } = useGeneralContext()

  const { locationUUID } = useParams<{ locationUUID: string }>()
  const { setParam, getParam, deleteParam } = useSearchParams()
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const today = moment().format(YYYY_MM_DD)

  const viewMode = getParam(viewModeParam) ?? ""
  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""
  const statusFilter = getParam(statusFilterParam) ?? ""

  const { data, previousData } = useGetCateringOrdersByRestaurantQuery({
    variables: {
      restaurantUUID: currentRestaurantUUID,
      startDate: moment(onWaitStartDate)
        .set("hour", 0)
        .set("minute", 0)
        .toISOString(false),
      endDate: moment(today)
        .set("hour", 23)
        .set("minute", 59)
        .toISOString(false),
      orderMethods: [OrderMethodEnum.CATERING],
      ...(!!locationUUID && {
        locationUUIDs: [locationUUID],
      }),
      status: [OrderStatusEnum.PLACED, OrderStatusEnum.IN_REVISION],
      take: GET_ORDERS_QTY,
    },
    skip: !currentRestaurantUUID,
    fetchPolicy: "cache-and-network",
  })

  const displayedOrdersAmount =
    data?.getOrdersByRestaurant.results?.length ||
    previousData?.getOrdersByRestaurant.results?.length ||
    0

  const hasMoreThanLimit =
    data?.getOrdersByRestaurant.hasNextPage &&
    data.getOrdersByRestaurant.endCursor

  const { updateDateRangeFilters, updateRangeTypeFilter } = useDateRange()

  const segmentedData = segmentedOptionsTime.map((option) => {
    return {
      value: option.value,
      label: option.label,
    }
  })

  const handleSegmentedControl = (value: string) => {
    setParam("push", viewModeParam, value)
    setParam("replace", "range", DateRangeEnum.CUSTOM)
    setParam(
      "replace",
      DateQueryParam.start,
      moment().clone().startOf("month").format(YYYY_MM_DD)
    )
    setParam(
      "replace",
      DateQueryParam.end,
      moment().clone().endOf("month").format(YYYY_MM_DD)
    )
  }
  const handleStatusFilterButton = () => {
    if (statusFilter === StatusFilterEnum.ON_WAIT) {
      deleteParam(statusFilterParam)
      setParam("replace", DateQueryParam.start, moment().format(YYYY_MM_DD))
      setParam("replace", DateQueryParam.end, moment().format(YYYY_MM_DD))
    } else {
      setParam("push", statusFilterParam, StatusFilterEnum.ON_WAIT)
      setParam(
        "replace",
        DateQueryParam.start,
        onWaitStartDate.format(YYYY_MM_DD)
      )
      setParam("replace", DateQueryParam.end, moment().format(YYYY_MM_DD))
    }
  }
  useEffect(() => {
    if (!startDateParam || !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationUUID])

  useEffect(() => {
    if (!startDateParam && !endDateParam) {
      updateDateRangeFilters(dateRanges.today())
      updateRangeTypeFilter(DateRangeEnum.TODAY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateParam, endDateParam, locationUUID])

  return (
    <Flex
      bg="white"
      px={32}
      py={8}
      align="center"
      justify="space-between"
      pos="sticky"
      top={0}
      className={styles["catering-header"]}
    >
      <Text fw={600}>
        <FormattedMessage
          id="restaurants.catering.title"
          defaultMessage="Catering"
        />
      </Text>
      <Group>
        {viewModeType(viewMode) === "TABLE" &&
          statusFilter !== StatusFilterEnum.ON_WAIT && (
            <Button
              px={16}
              variant="outline"
              leftIcon={<IconCalendarEvent size={16} />}
              onClick={() => setOpenDatePicker(true)}
            >
              <Text ml={8} size="sm" weight={400}>
                {intl.formatMessage({
                  id: "restaurant.catering.header.date.range.button",
                  defaultMessage: "Date Range",
                })}
              </Text>
            </Button>
          )}
        <Button
          px={16}
          miw={120}
          variant="light"
          onClick={handleStatusFilterButton}
        >
          <Text ml={8} size="sm" weight={400}>
            {statusFilter === StatusFilterEnum.ON_WAIT
              ? intl.formatMessage({
                  id: "restaurant.catering.header.status.see.all.button",
                  defaultMessage: "See all",
                })
              : intl.formatMessage(
                  {
                    id: "restaurant.catering.header.status.on.wait.button",
                    defaultMessage: "On Wait ({qty})",
                  },
                  {
                    qty: `${
                      hasMoreThanLimit ? "+" : ""
                    }${displayedOrdersAmount}`,
                  }
                )}
          </Text>
        </Button>
        <SegmentedControl
          defaultValue={viewModeType(viewMode)}
          value={viewMode}
          data={segmentedData}
          onChange={handleSegmentedControl}
        />
      </Group>
      {openDatePicker && (
        <CalendarPickerModal
          opened={openDatePicker}
          onClose={() => setOpenDatePicker(false)}
        />
      )}
    </Flex>
  )
}
