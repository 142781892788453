import { useGeneralContext } from "../../../../../shared/contexts/StoreProvider"
import { DateQueryParam } from "../../../../../shared/hooks/useDateRange"
import { useSearchParams } from "../../../../../shared/hooks/useSearchParams"
import {
  FIRST_HOUR,
  FIRST_MINUTE,
  FIRST_SECOND,
  LAST_HOUR,
  LAST_MINUTE,
  LAST_SECOND,
} from "../../../../../shared/utils/constant/values"
import { getAuthToken } from "../../../../../shared/utils/storage/auth"
import { ExportCSV } from "../../components/ExportCSV"
import {
  Table,
  TableBody,
  TableCell,
  TableCellHeader,
  TableHeader,
  TableRow,
} from "../../components/Table"
import { FOOD_TRUCK_LEAGUE_UUID, LOCATIONS_QUERY_PARAM } from "../../constants"
import { getCSVLocationsQueryString } from "../../utils/get-csv-locations-query-string.util"
import { downloadErrorIntl } from "../FinancialSection/cards.title"
import { Group, Skeleton, Stack, Text, Title } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import * as Sentry from "@sentry/react"
import fileDownload from "js-file-download"
import { useFlags } from "launchdarkly-react-client-sdk"
import moment from "moment-timezone"
import { Fragment } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"

interface Row {
  highlight?: boolean
  name: string
  amount: number
}

interface MainRow extends Row {
  subRows?: Row[]
}

export interface SalesSummaryProps {
  tableName: string
  rows?: MainRow[]
}

export interface Tables {
  data: SalesSummaryProps[]
  loading: boolean
}

const apiURL = process.env.REACT_APP_API_URL

export const SalesSummary = (props: Tables) => {
  const { data, loading } = props
  const intl = useIntl()
  const { getParam } = useSearchParams()
  const { locationUUID } = useParams<{ locationUUID: string }>()
  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
    },
  } = useGeneralContext()
  const { apEnablePayrollDownload } = useFlags()
  const isCorporate = !locationUUID
  const corporateLocations = getParam(LOCATIONS_QUERY_PARAM)?.split(",") || []
  const locationsQueryString = getCSVLocationsQueryString(
    isCorporate ? corporateLocations : [locationUUID]
  )
  const startDateParam = getParam(DateQueryParam.start) ?? ""
  const endDateParam = getParam(DateQueryParam.end) ?? ""
  const isFTL = restaurantUUID === FOOD_TRUCK_LEAGUE_UUID

  const handleExportCSV = async () => {
    const token = getAuthToken()
    const start = moment(startDateParam)
      .set({ hour: FIRST_HOUR, minute: FIRST_MINUTE, second: FIRST_SECOND })
      .toISOString()
    const end = moment(endDateParam)
      .set({ hour: LAST_HOUR, minute: LAST_MINUTE, second: LAST_SECOND })
      .toISOString()

    apiURL &&
      (await fetch(
        `${apiURL}/cms/reports/financial?startDate=${start}&endDate=${end}&${locationsQueryString}`,
        {
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          redirect: "follow",
          referrerPolicy: "no-referrer",
          headers: {
            Authorization: `Bearer ${token?.replace(/"/g, "") || ""}`,
          },
        }
      )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Unable to download. Error: ${response.status}`)
          }
          return fileDownload(await response.blob(), "financial-report.csv")
        })
        .catch((error) => {
          notifications.show({
            message: intl.formatMessage(downloadErrorIntl),
            color: "red",
            autoClose: false,
            withBorder: true,
            id: "error/unable-download",
          })
          Sentry.captureException(error, {
            tags: { query: "financial-report" },
          })
        }))
  }

  const handlePayoutDownload = async () => {
    const token = getAuthToken()

    apiURL &&
      (await fetch(`${apiURL}/cms/reports/payouts`, {
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: {
          Authorization: `Bearer ${token?.replace(/"/g, "") || ""}`,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Unable to download. Error: ${response.status}`)
          }
          return fileDownload(await response.blob(), "payout.csv")
        })
        .catch((error) => {
          notifications.show({
            message: intl.formatMessage(downloadErrorIntl),
            color: "red",
            autoClose: false,
            withBorder: true,
            id: "error/unable-download-payout",
          })
          Sentry.captureException(error, {
            tags: { query: "payouts" },
          })
        }))
  }

  const handleExportAllFTL = async () => {
    const token = getAuthToken()

    apiURL &&
      (await fetch(`${apiURL}/cms/reports/ftl`, {
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrerPolicy: "no-referrer",
        headers: {
          Authorization: `Bearer ${token?.replace(/"/g, "") || ""}`,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error(`Unable to download. Error: ${response.status}`)
          }
          return fileDownload(await response.blob(), "full-report.xlsx")
        })
        .catch((error) => {
          notifications.show({
            message: intl.formatMessage(downloadErrorIntl),
            color: "red",
            autoClose: false,
            withBorder: true,
            id: "error/unable-download-all",
          })
          Sentry.captureException(error, {
            tags: { query: "all-transactions-ftl" },
          })
        }))
  }

  return (
    <Stack>
      <Group position="apart" noWrap>
        <Title order={4}>
          {intl.formatMessage({
            id: "restaurants.reports.financial.title",
            defaultMessage: "Financial",
          })}
        </Title>
        <Group position="left">
          {!isCorporate && apEnablePayrollDownload && (
            <ExportCSV
              onClick={handlePayoutDownload}
              type="Payouts"
              label={intl.formatMessage({
                id: "restaurants.reports.financial.export.all.label",
                defaultMessage: "Export payouts",
              })}
            />
          )}
          {isFTL && isCorporate && (
            <ExportCSV
              onClick={handleExportAllFTL}
              type="FTLFinancial"
              label={intl.formatMessage({
                id: "restaurants.reports.financial.export.all.label",
                defaultMessage: "Export all transactions",
              })}
            />
          )}
          <ExportCSV onClick={handleExportCSV} type="Financial" />
        </Group>
      </Group>
      {data.map((table, tableIndex) => (
        <Table key={tableIndex}>
          <TableHeader>
            <TableRow>
              <TableCellHeader colSpan={2}>
                <Text c="gray.7" fw={400}>
                  {table.tableName}
                </Text>
              </TableCellHeader>
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.rows?.map((row, index) => (
              <Fragment key={`${tableIndex}-${index}`}>
                <TableRow>
                  <TableCell>
                    <Text fw={row.highlight ? 700 : undefined}>{row.name}</Text>
                  </TableCell>
                  <TableCell>
                    {loading ? (
                      <Skeleton h={15} w={60} />
                    ) : (
                      <Text align="end" fw={row.highlight ? 700 : undefined}>
                        {intl.formatNumber(row.amount, {
                          style: "currency",
                          currency: "USD",
                        })}
                      </Text>
                    )}
                  </TableCell>
                </TableRow>
                {row?.subRows?.map((subRow, subIndex) => (
                  <TableRow key={`${tableIndex}-${index}-${subIndex}`}>
                    <TableCell>
                      <Text pl={20}>{subRow.name}</Text>
                    </TableCell>
                    <TableCell>
                      {loading ? (
                        <Skeleton h={15} w={60} />
                      ) : (
                        <Text align="end">
                          {intl.formatNumber(subRow.amount, {
                            style: "currency",
                            currency: "USD",
                          })}
                        </Text>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ))}
    </Stack>
  )
}
