import config from "../../../../../package.json"
import type { GetAllLocationsByUserQuery } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { useGetAllLocationsByUserQuery } from "../../../../GraphQL/Queries/getAllLocationsByUser.generated"
import { ActionTypes } from "../../../../pages/Restaurants/actions"
import { useGeneralContext } from "../../../../shared/contexts/StoreProvider"
import { updateGetAllLocationsByUser } from "../../../../shared/graphql/updateQuery/updateGetAllLocationsByUser"
import paths from "../../../../shared/routes/paths"
import storage from "../../../../shared/utils/storage"
import { SETTINGS_PATH } from "../../../../shared/utils/storage/constants"
import { showGraphqlErrors } from "../../../ErrorList"
import Icon from "../../../Icon"
import InlineMenuItem from "../../../Menu/InlineMenuItem"
import SubMenu from "../../../Menu/SubMenu"
import SubMenuItem from "../../../Menu/SubMenu/SubMenuItem"
import MenuSubTitle from "../../../MenuSubTitle"
import RoleView from "../../../RoleView"
import Text from "../../../Typography/Text"
import { SidebarLocationMenuSkeleton } from "../../MainLayout/Sidebar/SidebarMenu/SidebarLocationMenu.skeleton"
import RestaurantCompletionProgress from "../../components/RestaurantCompletionProgress"
import { integrationItems } from "./items/integrationsItems"
import { locationsItems } from "./items/locationsItems"
import { restaurantItems } from "./items/restaurantItems"
import { Menu } from "antd"
import { useFlags } from "launchdarkly-react-client-sdk"
import React, { useCallback } from "react"
import { useIntl } from "react-intl"
import { NavLink, useHistory, useLocation } from "react-router-dom"
import styled from "styled-components"

export interface ISettingsBarParams {
  locationUUID: string
}

export const SettingsBar: React.FC = () => {
  const intl = useIntl()
  const { pathname } = useLocation()
  const { push } = useHistory()

  const {
    plans: plansFeatureEnabled,
    loyalty: loyaltyFeatureEnabled,
    completionProgress: completionProgressFeatureEnabled,
    apEnableCatering,
    apWebsiteEnabled,
  } = useFlags<Record<string, boolean>>()

  const deliveryFeatureDisabled =
    process.env.REACT_APP_ENABLE_DELIVERY?.toLowerCase() === "false"

  const {
    state: {
      currentRestaurant: { uuid: restaurantUUID },
      selectedLocation: { uuid: locationUUID },
      auth: {
        admin: { uuid: userUUID },
      },
    },
    dispatch,
  } = useGeneralContext()

  const {
    data,
    loading: loadingLocations,
    fetchMore,
  } = useGetAllLocationsByUserQuery({
    variables: { restaurantUUID, userUUID, isActive: true },
    skip: !restaurantUUID || !userUUID,
  })

  const backToRestaurantPath =
    storage.get<string>(SETTINGS_PATH) ?? paths.restaurants.orders
  const locations = data?.getAllLocationsByUser?.results ?? []

  const hasNextPage = data?.getAllLocationsByUser?.hasNextPage ?? false
  const endCursor = data?.getAllLocationsByUser?.endCursor ?? null
  const canFetchMore = !loadingLocations && hasNextPage && endCursor

  // Filtering restaurant items based on a validation to hide the Loyalty Program or/and Delivery in the Settings
  const restaurantItemsFiltered = restaurantItems.filter((item) => {
    const showCateringMenuItem =
      item.title === "Catering" ? apEnableCatering : true
    const showDeliveryMenuItem =
      item.title === "Delivery" ? !deliveryFeatureDisabled : true
    const showLoyaltyMenuItem =
      item.title === "Loyalty" ? loyaltyFeatureEnabled : true
    const showPlansMenuItem =
      item.title === "Plans" ? plansFeatureEnabled : true
    const showWebsiteMenuItem =
      item.title === "Website" ? apWebsiteEnabled : true

    return (
      showDeliveryMenuItem &&
      showLoyaltyMenuItem &&
      showPlansMenuItem &&
      showCateringMenuItem &&
      showWebsiteMenuItem
    )
  })

  const onSelectedLocation = (location: string) => {
    dispatch({
      type: ActionTypes.SetCurrentState,
      payload: {
        restaurantUUID,
        locationUUID: location,
      },
    })
  }

  const showAddLocation = () => {
    push(paths.settings.locations.create)
  }

  const clearSelectedLocation = () => onSelectedLocation("")

  const menusByRoute = [
    {
      route: paths.settings.locations.root,
      key: `submenu-location-${locationUUID}`,
    },
    {
      route: paths.settings.restaurant.root,
      key: "",
    },
  ]

  const defaultOpenKeys = menusByRoute
    .filter(({ route }) => pathname.includes(route))
    .map(({ key }) => key)

  const fetchMoreLocationsByUser = useCallback(async () => {
    try {
      await fetchMore({
        variables: {
          restaurantUUID,
          userUUID,
          isActive: true,
          after: endCursor,
        },
        updateQuery: (prev: GetAllLocationsByUserQuery, { fetchMoreResult }) =>
          updateGetAllLocationsByUser(prev, fetchMoreResult),
      })
    } catch (error) {
      showGraphqlErrors(error)
    }
  }, [endCursor, fetchMore, restaurantUUID, userUUID])

  const onScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLElement
    const scrolledToBottom =
      Math.abs(target.scrollHeight - target.clientHeight - target.scrollTop) < 1

    if (canFetchMore && scrolledToBottom) {
      fetchMoreLocationsByUser()
    }
  }

  const handleOutOfSettings = () => {
    const urlFields = backToRestaurantPath.split("/")
    const locationIndex = urlFields.indexOf("location")
    if (locationIndex >= 0) {
      onSelectedLocation(urlFields[locationIndex + 1])
    } else {
      clearSelectedLocation()
    }
  }

  return (
    <StyledWrapper onScroll={onScroll} id="settings-bar-wrapper">
      <StyledMenu
        mode="inline"
        defaultSelectedKeys={[paths.settings.restaurant.restaurantInfo]}
        selectedKeys={[pathname]}
        defaultOpenKeys={defaultOpenKeys}
      >
        <div className="inline-header-divider" />
        <InlineMenuItem
          className="menu-items-with-icon inline-menu-header"
          icon={<Icon size={12} remixiconClass="ri-arrow-left-s-line" />}
          key="settings-header"
          onClick={handleOutOfSettings}
        >
          <NavLink to={backToRestaurantPath}>
            {intl.formatMessage({
              id: "components.layouts.settings.bar.title",
              defaultMessage: "Settings",
            })}
          </NavLink>
        </InlineMenuItem>

        <RoleView allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}>
          <MenuSubTitle
            title={intl.formatMessage({
              id: "components.layouts.settings.bar.restaurant",
              defaultMessage: "Restaurant",
            })}
            key="restaurant-item-group"
          />
        </RoleView>

        {restaurantItemsFiltered.map((menuItem) => (
          <InlineMenuItem
            className="inline-menu-item"
            key={menuItem.path}
            allowed={menuItem.allowedRoles}
            hidden={menuItem.hidden}
          >
            <NavLink to={menuItem.path} onClick={clearSelectedLocation}>
              {menuItem.title}
            </NavLink>
          </InlineMenuItem>
        ))}

        {/* TODO: We will remove this condition after completing this feature. */}
        {process.env.NODE_ENV === "development" && (
          <SubMenu
            title="Integrations"
            className="sidebar-submenu"
            icon={<Icon size={14} remixiconClass="ri-settings-3-line" />}
          >
            {integrationItems.map((integrationItem) => (
              <SubMenuItem
                key={integrationItem.path}
                width={176}
                className="sidebar-submenu__item"
              >
                <NavLink to={integrationItem.path}>Square</NavLink>
              </SubMenuItem>
            ))}
          </SubMenu>
        )}

        {locations.length > 0 ? (
          <MenuSubTitle
            title={intl.formatMessage({
              id: "components.layouts.settings.bar.locations",
              defaultMessage: "Locations",
            })}
            key="locations-item-group"
          />
        ) : null}

        {loadingLocations ? (
          <SidebarLocationMenuSkeleton />
        ) : (
          locations &&
          locations.map((location) => (
            <SubMenu
              key={`submenu-location-${location.uuid}`}
              title={location.name}
              className="sidebar-submenu"
              icon={<Icon size={14} remixiconClass="ri-settings-3-line" />}
            >
              {locationsItems.map((locationItem) => {
                if (locationItem.name === "Catering" && !apEnableCatering) {
                  return null
                }

                return (
                  <SubMenuItem
                    width={176}
                    key={locationItem.path(location.uuid)}
                    className="sidebar-submenu__item"
                  >
                    <NavLink
                      to={locationItem.path(location.uuid)}
                      onClick={() => onSelectedLocation(location.uuid)}
                    >
                      {locationItem.name}
                    </NavLink>
                  </SubMenuItem>
                )
              })}
            </SubMenu>
          ))
        )}

        <div className="footer">
          <InlineMenuItem
            icon={<Icon size={16} remixiconClass="ri-add-line" />}
            key="create-location"
            onClick={showAddLocation}
            allowed={["RESTAURANT_OWNER", "RESTAURANT_ADMIN"]}
          >
            {intl.formatMessage({
              id: "components.main.sidebar.locations.add",
              defaultMessage: "Add Location",
            })}
          </InlineMenuItem>
          {completionProgressFeatureEnabled && <RestaurantCompletionProgress />}
          <div className="settings-bar__footer">
            <Text size="s" color="Neutral5">
              {intl.formatMessage(
                {
                  id: "components.layouts.settings.bar.app.version",
                  defaultMessage: "Version {version}",
                },
                {
                  version: config?.version,
                }
              )}
            </Text>
          </div>
        </div>
      </StyledMenu>
    </StyledWrapper>
  )
}

const StyledMenu = styled(Menu)`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledWrapper = styled.div`
  padding: 8px 16px 0px;
  overflow: auto;
  height: 100%;

  .inline-header-divider {
    height: 8px;
    position: sticky;
    top: 0;
    width: 100%;
    background: white;
    z-index: 1;
  }

  .inline-menu-header {
    position: sticky;
    top: 8px;
    width: 100%;
    background: white;
    z-index: 1;
  }

  .ant-menu-item-group {
    color: ${({ theme }) => theme.colors["Neutral6"]} !important;
  }

  .ant-menu-inline {
    border: none;
  }

  .ant-menu-item-group-title {
    color: ${({ theme }) => theme.colors["Neutral6"]} !important;
    font-weight: 400 !important;
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border: none;
    margin: 0px;
  }

  .ant-menu-item .ant-menu-item-icon + span,
  .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ant-menu-item .anticon + span,
  .ant-menu-submenu-title .anticon + span {
    margin-left: 8px;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.colors["Primary1"]};
  }

  .inline-menu-item {
    padding-left: 30px !important ;
  }

  .footer {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 16px;
    position: sticky;
    width: 100%;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: ${({ theme }) => theme.colors["Neutral0"]};

    .settings-bar__footer {
      padding-bottom: 8px;
    }
  }
`
