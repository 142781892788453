import styles from "./Collapsible.module.css"
import {
  Collapse,
  Flex,
  Group,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  IconAlertCircle,
  IconChevronRight,
  IconChevronUp,
} from "@tabler/icons-react"
import classNames from "classnames"
import { ReactNode } from "react"

type CollapsibleProps = {
  label: ReactNode
  children: React.ReactNode
  disabled?: boolean
  rightSection?: ReactNode
  fullWidth?: boolean
  error?: boolean
}

export const Collapsible = (props: CollapsibleProps) => {
  const {
    children,
    fullWidth,
    label,
    rightSection,
    disabled = false,
    error = false,
  } = props
  const { colors } = useMantineTheme()
  const [opened, { toggle }] = useDisclosure(false)
  const showRightSection = rightSection && (!opened || disabled)
  const IconChevron = opened ? IconChevronUp : IconChevronRight

  const labelComponent =
    typeof label === "string" ? (
      <Text size="sm" weight={600}>
        {label}
      </Text>
    ) : (
      label
    )
  return (
    <Flex gap={4} pos="relative" align="center">
      {error && !opened && (
        <IconAlertCircle
          color={colors.red[6]}
          className={styles["alert-icon"]}
        />
      )}
      <UnstyledButton
        w="100%"
        bg="gray.0"
        display="flex"
        onClick={() => !disabled && toggle()}
        style={{ borderRadius: 8, flexDirection: "column" }}
      >
        <Group
          position="apart"
          w="100%"
          mih={56}
          p={16}
          noWrap
          className={classNames({ pointer: !disabled })}
        >
          {labelComponent}
          <Group pr={20} noWrap>
            {showRightSection && rightSection}
            <IconChevron size={16} color={colors.gray[6]} />
          </Group>
        </Group>
        <Collapse
          w={fullWidth ? "100%" : undefined}
          in={opened && !disabled}
          pb={16}
        >
          <Stack mx={16}>{children}</Stack>
        </Collapse>
      </UnstyledButton>
    </Flex>
  )
}
